import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FileState, FileStore } from './file.store';
import { DocType, FileData, Realm } from '@data/file/file.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';

export function cumsumFileSize(files: FileData[]): number {
  return files.reduce((sum, current) => sum + current.size, 0);
}

@Injectable({ providedIn: 'root' })
export class FileQuery extends QueryEntity<FileState, FileData> {
  constructor(protected store: FileStore) {
    super(store);
  }

  public selectCumulativeSize(realm: Realm, forId: string, docType: DocType): Observable<number> {
    return this.selectFor(realm, forId, docType).pipe(map((files) => cumsumFileSize(files)));
  }

  public getCumulativeSize(realm: Realm, forId: string, docType: DocType): number {
    return cumsumFileSize(this.getFor(realm, forId, docType));
  }

  public selectFor(realm: Realm, forId: string, docType: DocType): Observable<FileData[]> {
    return this.selectAll({
      filterBy: (f) => f.forId === forId && f.docType === docType,
    });
  }

  public getFor(realm: Realm, forId: string, docType: DocType): FileData[] {
    return this.getAll({
      filterBy: (f) => f.forId === forId && f.docType === docType,
    });
  }

  public getCountFor(
    realm: Realm,
    forId: string,
    docType: DocType,
    predicate?: (entity: FileData, index: number) => boolean,
  ): number {
    return this.getCount((f, i) => {
      const forMatches = f.forId === forId && f.docType === docType;
      if (!!predicate) {
        return forMatches && predicate(f, i);
      }
      return forMatches;
    });
  }

  public getEntityByNameFor(realm: Realm, forId: string, docType: DocType, name: string): FileData {
    return this.getAll({
      filterBy: (f) => f.forId === forId && f.docType === docType && f.name === name,
    })[0];
  }

  getHasCacheFor(realm: Realm, forId: string, docType: DocType): boolean {
    return !_.isEmpty(this.getFor(realm, forId, docType));
  }
}
