<div>
  <h4>{{ headline | translate }}</h4>
  <ng-template [ngIfElse]="emptyTpl" [ngIf]="!!data?.length">
    <recrewt-requirement-item
      (addClicked)="onAdded($event)"
      (deleteClicked)="onDeleted(i)"
      (updated)="onUpdated($event, i)"
      (cancelled)="onCancelled($event)"
      *ngFor="let req of data; index as i"
      [req]="req"
      class="p-b-1"
    ></recrewt-requirement-item>
  </ng-template>
</div>

<ng-template #emptyTpl>
  <div class="width-match-parent" fxLayout="column" fxLayoutAlign="center center">
    <p class="mat-hint">{{ 'REQUIREMENTS_EDITOR.placeholder' | translate }}</p>
    <button (click)="data.push({ category: null, desc: '' })" color="primary" mat-stroked-button type="button">
      {{ 'COMMON.add' | translate }}
    </button>
  </div>
</ng-template>
