import { Injectable } from '@angular/core';
import { ChatService } from '@data/chat/chat.service';
import { ChatActions } from '@data/chat/chat.actions';
import { map, switchMap, tap } from 'rxjs/operators';
import { ChatStore } from '@data/chat/chat.store';
import { Match } from '@data/match/match.model';
import { GA_EVENT_ID, GoogleAnalyticsService } from '@core/services/ga/google-analytics.service';
import { MatchStore } from '@data/match/match.store';
import { CompanyQuery } from '@data/company/company.query';
import { MobileNotifierService } from '@core/services/http/mobile-notifier/mobile-notifier.service';
import { createEffect, ofType } from '@ngneat/effects';

@Injectable({ providedIn: 'root' })
export class ChatEffects {
  loadMessages = createEffect((actions) =>
    actions.pipe(
      ofType(ChatActions.loadForUser),
      switchMap(({ userId }) => this.chatService.get(userId)),
      tap((messages) => this.chatStore.set(messages)),
    ),
  );

  sendMessage = createEffect(
    (actions) =>
      actions.pipe(
        ofType(ChatActions.send),
        switchMap(({ message }) =>
          this.chatService.create(message).pipe(map(() => ChatActions.sendSuccess({ message }))),
        ),
      ),
    { dispatch: true },
  );

  addMessage = createEffect((actions) =>
    actions.pipe(
      ofType(ChatActions.add),
      tap(({ message }) => {
        const companyName = this.companyQuery.getValue().identifiableName ?? 'Unternehmen';
        this.notifier.notifyNewMessage(message.receiver, message.sender, companyName, message.message);
        this.chatStore.add(message);
      }),
    ),
  );

  sendSuccessful = createEffect((actions) =>
    actions.pipe(
      ofType(ChatActions.sendSuccess),
      tap(({ message }) => {
        const companyName = this.companyQuery.getValue().identifiableName ?? 'Unternehmen';
        this.notifier.notifyNewMessage(message.receiver, message.sender, companyName, message.message);
        this.matchStore.update((it: Match) => it.user.id === message.receiver && it.jobRef === message.jobRef, {
          contacted: true,
        });
        this.ga.eventEmitter(GA_EVENT_ID.chat_message_sent, 'chat', GA_EVENT_ID.chat_message_sent);
      }),
    ),
  );

  constructor(
    private chatService: ChatService,
    private chatStore: ChatStore,
    private matchStore: MatchStore,
    private companyQuery: CompanyQuery,
    private notifier: MobileNotifierService,
    private ga: GoogleAnalyticsService,
  ) {}
}
