import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'recrewt-stripe-agreement-checkbox',
  templateUrl: './stripe-agreement-checkbox.component.html',
  styleUrls: ['./stripe-agreement-checkbox.component.scss'],
})
export class StripeAgreementCheckboxComponent {
  agreed = false;

  @Output() consentChange = new EventEmitter<boolean>();

  onCheckChanged(checked: boolean) {
    this.agreed = checked;
    this.consentChange.emit(checked);
  }
}
