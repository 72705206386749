import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { ListSideNavItem } from '@shared/components/list-sidenav/data/list-side-nav-item';
import { TemplateHostDirective } from '@shared/directives/template-host.directive';
import { DynamicComponentService } from '@core/services/template/dynamic-component.service';

@Component({
  selector: 'recrewt-list-sidenav-item',
  templateUrl: './list-sidenav-item.component.html',
  styleUrls: ['./list-sidenav-item.component.scss'],
})
export class ListSidenavItemComponent<T> implements OnInit {
  @Input() itemType?: Type<any>;

  @Input() item?: ListSideNavItem<T>;

  @ViewChild(TemplateHostDirective, { static: true })
  templateHost?: TemplateHostDirective;

  constructor(private templateLoader: DynamicComponentService) {}

  ngOnInit(): void {
    if (!this.templateHost || !this.itemType || !this.item) {
      return;
    }
    this.templateLoader.loadComponent(this.templateHost?.viewContainerRef, this.itemType, this.item.data);
  }
}
