<div class="notification-list-wrapper" fxLayout="column">
  <header class="p-v-2 p-l-3 p-r-2" fxLayoutAlign="space-between center">
    <p class="mat-subheading-1 no-pad">{{ 'NOTIFICATIONS.LIST.HEADER.notifications' | translate }}</p>
    <button (click)="markAllAsRead.emit()" *ngIf="notifications?.length" mat-button>
      <span class="on-surface-medium mat-caption no-pad">{{
        'NOTIFICATIONS.LIST.HEADER.mark_as_read' | translate
      }}</span>
    </button>
  </header>
  <section class="notification-list">
    <ng-container *ngIf="!loading; else loadingTpl">
      <ng-container *ngIf="notifications?.length; else emptyTpl">
        <recrewt-notification-list-item
          (read)="markOneAsRead.emit($event)"
          *ngFor="let notification of notifications"
          [notification]="notification"
        ></recrewt-notification-list-item>
      </ng-container>
    </ng-container>
  </section>
</div>

<ng-template #emptyTpl>
  <div class="no-notifications-wrapper" fxLayoutAlign="center center">
    <p class="no-pad on-surface-medium">{{ 'NOTIFICATIONS.LIST.no_notifications' | translate }}</p>
  </div>
</ng-template>

<ng-template #loadingTpl>
  <div class="no-notifications-wrapper" fxLayoutAlign="center center">
    <mat-spinner color="primary" diameter="24" mode="indeterminate"></mat-spinner>
  </div>
</ng-template>
