<div [formGroup]="parentFormGroup">
  <ng-container *ngFor="let dataset of config; index as i">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <mat-icon>{{ dataset.icon }}</mat-icon>
      <h4 class="no-pad m-b-1 m-t-2">{{ dataset.label | translate }}</h4>
    </div>
    <recrewt-chip-editor
      #cl
      (added)="readjustAfterAdded($event.fromAuto, i, $event.chipIdx); reselectChips(i)"
      (chipClicked)="updateChipSelection($event.fromAuto, $event.value, $event.chipIdx, i)"
      (editStart)="reselectChips(i)"
      (removed)="readjustAfterRemove(i, $event.chipIdx); reselectChips(i)"
      [formControlName]="dataset.groupName"
      [options]="dataset.data"
    ></recrewt-chip-editor>
  </ng-container>
</div>
