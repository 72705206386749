import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { get } from 'lodash';

@Component({
  selector: 'recrewt-accordion-list',
  templateUrl: './accordion-list.component.html',
  styleUrls: ['./accordion-list.component.scss'],
})
export class AccordionListComponent {
  @Input() flat = false;

  @Input() titleKey?: string;

  @Input() items: { title?: string; description?: string; value: any }[] = [];

  @Input() itemTpl?: TemplateRef<any>;

  @Output() itemClicked = new EventEmitter<number>();

  step = 0;

  trackByTitle = (item: any) => this.title(item);

  title(item: any): string {
    if (!!this.titleKey) {
      return get(item, this.titleKey, '').toString();
    } else {
      return item?.title?.toString();
    }
  }

  setStep(index: number): void {
    this.step = index;
  }

  onItemClicked(i: number): void {
    this.itemClicked.emit(i);
  }
}
