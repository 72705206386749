import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { SectionConfig } from '../section/section-config';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'recrewt-section-side-nav',
  templateUrl: './section-side-nav.component.html',
  styleUrls: ['./section-side-nav.component.scss'],
})
export class SectionSideNavComponent implements OnChanges {
  @Input() sectionConfigs: SectionConfig[] = [];

  @Input() navtitle?: string;

  @Input() subtitle?: string;

  @Input() initialSection?: number;

  @Input() icon = 'business';

  @ViewChild('stepper', { static: true }) stepper?: MatStepper;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialSection != null && this.stepper) {
      this.stepper.selectedIndex = this.initialSection;
    }
  }
}
