import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: '[key]recrewt-count-down-button',
  templateUrl: './count-down-button.component.html',
  styleUrls: ['./count-down-button.component.scss'],
})
export class CountDownButtonComponent implements OnInit {
  @Input() color: ThemePalette;

  @Input() disabled = false;

  @Input() countdown = 0;

  @Input() label = '';

  @Input() key!: string;

  @Input() clear?: Observable<void>;

  remainingCountdown = 0;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    const lastClick = localStorage.getItem(this.key);
    if (lastClick) {
      const lastClickDate = new Date(parseInt(lastClick, 10));
      const now = new Date();
      const diff = now.getTime() - lastClickDate.getTime();
      const seconds = Math.floor(diff / 1000);
      if (seconds < this.countdown) {
        this.remainingCountdown = this.countdown - seconds;
        this.countDown();
      }
    }

    this.clear?.pipe(untilDestroyed(this)).subscribe(() => (this.remainingCountdown = 0));
  }

  onClick() {
    localStorage.setItem(this.key, Date.now().toString());
    this.remainingCountdown = this.countdown;
    this.countDown();
  }

  private countDown() {
    const interval = setInterval(() => {
      this.remainingCountdown--;
      this.cdr.detectChanges();
      if (this.remainingCountdown <= 0) {
        clearInterval(interval);
      }
    }, 1000);
  }
}
