import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-colored-item',
  templateUrl: './colored-item.component.html',
  styleUrls: ['./colored-item.component.scss', '../../mobile-theme.scss'],
})
export class ColoredItemComponent {
  @Input() text = '';

  @Input() darken = false;
}
