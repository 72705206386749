import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationStore } from '@data/notification/notification.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Notification } from '@data/notification/notification.model';
import { CompanyQuery } from '@data/company/company.query';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private url = `${environment.apiUrl}/notifications`;

  constructor(
    private notificationStore: NotificationStore,
    private http: HttpClient,
    private companyQuery: CompanyQuery,
  ) {}

  get() {
    const cid$ = this.companyQuery.getActiveIdWhenReady();
    return cid$.pipe(switchMap(this.getNotifications()));
  }

  markAsRead(id?: string) {
    const cid$ = this.companyQuery.getActiveIdWhenReady();
    return cid$.pipe(switchMap(this.markNotificationsAsRead(id)));
  }

  private getNotifications() {
    return (cid?: string) => {
      if (!cid) return of([]);
      return this.http.get<Notification[]>(`${this.url}/${cid}`);
    };
  }

  private markNotificationsAsRead(id?: string) {
    return (cid?: string) => {
      if (!cid) return of([]);
      if (id) {
        return this.http.put(`${this.url}/${cid}/${id}`, {}).pipe(switchMap(() => id));
      } else {
        return this.http.put(`${this.url}/${cid}`, {});
      }
    };
  }
}
