import { Notification, NotificationType } from '@data/notification/notification.model';
import { spaceRouteNames } from '../../../modules/space/space.routing.names';

type MessageNotificationMetadata = {
  userId: string;
  jobId: string;
};

export class MessageNotification implements Notification {
  id: string;

  actionLabel: string;

  companyRef: string;

  createdAt: Date;

  metadata: MessageNotificationMetadata;

  read: boolean;

  title: string;

  type: NotificationType;

  href: string;

  params: any;

  constructor(notification: Notification) {
    if (notification.type !== NotificationType.NEW_MESSAGE) {
      throw new Error('Invalid notification type. Notification is not a message notification.');
    }
    const metadata = notification.metadata;
    this.id = notification.id;
    this.read = notification.read;
    this.companyRef = notification.companyRef;
    this.createdAt = notification.createdAt;
    this.metadata = metadata as MessageNotificationMetadata;
    this.type = NotificationType.NEW_MESSAGE;
    this.actionLabel = 'NOTIFICATIONS.MESSAGE.actionLabel';
    this.title = 'NOTIFICATIONS.MESSAGE.title';
    if (metadata.matchRef) {
      this.href = spaceRouteNames.MATCHES;
      this.params = { id: metadata.matchRef };
    } else {
      this.href = spaceRouteNames.APPLICATIONS;
      this.params = { uid: metadata.userRef, jid: metadata.jobRef };
    }
  }
}
