<recrewt-sidenav>
  <div
    *ngIf="isBranch$ | async"
    class="b-rad-1 primary-light p-h-3 m-h-6 m-t-2 p-v-1"
    fxLayout.lt-md="column"
    fxLayoutAlign="space-between center"
    fxLayoutGap.lt-md="12px"
  >
    <mat-icon color="primary" style="min-width: 24px">info</mat-icon>
    <p class="text-align-center no-pad p-h-2">{{ 'BRANCHES.info' | translate }}</p>
    <div fxLayout="row" fxLayoutAlign="end center">
      <a (click)="toParentSpace()" mat-button>{{ 'BRANCHES.to_parent' | translate }}</a>
    </div>
  </div>
  <ng-container *ngIf="spaceQuery.isActive$ | async">
    <router-outlet></router-outlet>
  </ng-container>
</recrewt-sidenav>
