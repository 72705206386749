import { createAction, props } from '@ngneat/effects';
import { ApplicationMetaStatus, ApplicationStage } from '@core/enums/data/application-status';
import { Application } from '@data/application/application.model';

export namespace ApplicationActions {
  export const load = createAction('[Application] Load applications');
  export const updateStatus = createAction(
    '[Application] Update application status',
    props<{ aid: string; status: ApplicationMetaStatus }>(),
  );
  export const updateStage = createAction(
    '[Application] Update application stage',
    props<{ application: Application; stage: ApplicationStage }>(),
  );

  export const download = createAction(
    '[Application] Download application document',
    props<{ aid: string | null; userId: string | null; onload: (url: string) => void }>(),
  );
}
