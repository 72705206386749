import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ThemePalette } from '@angular/material/core';

export interface DialogData {
  title: string;
  message: string;
  confirmText: string;
  confirmColor: ThemePalette;
}

@Component({
  selector: 'recrewt-single-action-dialog',
  templateUrl: './single-action-dialog.component.html',
  styleUrls: ['./single-action-dialog.component.scss'],
})
export class SingleActionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<SingleActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    dialogRef.disableClose = true;
  }

  noActionClicked(): void {
    this.dialogRef.close(null);
  }
}
