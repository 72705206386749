import { Component, EventEmitter, Output } from '@angular/core';
import { PopulatedResource } from '@data/resource/resource.model';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';

@Component({
  selector: 'recrewt-resource-menu',
  templateUrl: './resource-menu.component.html',
  styleUrls: ['./resource-menu.component.scss'],
})
export class ResourceMenuComponent extends MenuComponent<PopulatedResource> {
  @Output() pinClicked = new EventEmitter<{
    resource: PopulatedResource;
    attach: boolean;
  }>();

  @Output() deleteClicked = new EventEmitter<any>();

  @Output() duplicateClicked = new EventEmitter<any>();

  @Output() retryClicked = new EventEmitter<any>();

  pinnedItemText = '';

  pinned = false;

  private readonly defaultStrategy: ResourceMenuStrategy = {
    showPin: (r) => r.jobRef?.status !== 'template',
    showDuplicate: () => true,
    showDelete: (it) => ['template', 'creation_paused'].includes(it.jobRef?.status ?? ''),
    showRetry: () => false,
  };

  strategy = this.defaultStrategy;

  pin(resource: PopulatedResource): void {
    const attach = !this.pinned;
    this.pinClicked.emit({ resource, attach });
  }

  delete(resource: PopulatedResource): void {
    this.deleteClicked.emit(resource);
  }

  duplicate(resource: PopulatedResource): void {
    this.duplicateClicked.emit(resource);
  }

  retry(resource: PopulatedResource): void {
    this.retryClicked.emit(resource);
  }

  onContextMenu(
    event: MouseEvent,
    resource: PopulatedResource,
    pinned: boolean,
    strategy: ResourceMenuStrategy = this.defaultStrategy,
  ): void {
    this.pinned = pinned;
    this.pinnedItemText = pinned ? 'MENUS.RESOURCE_CONTEXT.unpin' : 'MENUS.RESOURCE_CONTEXT.pin';
    super.onContextMenu(event, resource, strategy);
  }
}

export interface ResourceMenuStrategy extends MenuStrategy {
  showPin(res: PopulatedResource): boolean;

  showDelete(res: PopulatedResource): boolean;

  showDuplicate(res: PopulatedResource): boolean;

  showRetry(res: PopulatedResource): boolean;
}
