<recrewt-on-click-confirm-with-button-editable
  #editable
  (cancel)="cancelled.emit(!!req.desc?.length && !!req.category != null)"
  (update)="updated.emit(form.value)"
  [mode]="req?.desc && req.category !== null ? 'view' : 'edit'"
>
  <ng-template viewMode>
    <recrewt-hover-action-bar-wrapper
      (addClicked)="addClicked.emit({ desc: null, category: null })"
      (deleteClicked)="deleteClicked.emit()"
      (editClicked)="editable.mode = 'edit'"
      align="row"
      class="p-1"
    >
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
        <mat-icon class="requirements-icon">done</mat-icon>
        <div fxFlex="grow">
          <div class="overline no-pad" mat-line>{{ req.category | decToStr: 'requirements' | translate }}</div>
          <div class="no-pad" mat-line>{{ req.desc | translate }}</div>
        </div>
      </div>
    </recrewt-hover-action-bar-wrapper>
  </ng-template>

  <ng-template editMode>
    <p class="no-pad m-t-2">{{ 'REQUIREMENTS_EDITOR.placeholder' | translate }}</p>
    <div [formGroup]="form" fxLayout="column">
      <recrewt-select
        [displayWith]="requirementMapping"
        [matStyle]="true"
        [options]="REQUIREMENTS.length | iterable"
        [parentFormGroup]="form"
        [require]="true"
        error="JOB_CREATOR.GENERAL.ERRORS.select_from_list"
        formControlName="category"
        fxFlex="grow"
        placeholder="REQUIREMENTS_EDITOR.category"
      >
      </recrewt-select>
      <mat-form-field appearance="outline" fxFlex="grow">
        <input formControlName="desc" matInput required />
        <mat-label>{{ 'REQUIREMENTS_EDITOR.desc' | translate }}</mat-label>
      </mat-form-field>
    </div>
  </ng-template>
</recrewt-on-click-confirm-with-button-editable>
