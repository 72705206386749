import { Component, Input, OnInit, Type, ViewChild } from '@angular/core';
import { CompanyPageComponent, CompanySectionData } from '../section/section-config';
import { DynamicComponentService } from '@core/services/template/dynamic-component.service';
import { TemplateHostDirective } from '@shared/directives/template-host.directive';

@Component({
  selector: 'recrewt-section-content',
  templateUrl: './section-content.component.html',
  styleUrls: ['./section-content.component.scss'],
})
export class SectionContentComponent implements OnInit {
  @Input() data: CompanySectionData | null = null;

  @Input() sectionType: Type<CompanyPageComponent> | null = null;

  @ViewChild(TemplateHostDirective, { static: true })
  tplHost: TemplateHostDirective | undefined;

  constructor(private tplLoader: DynamicComponentService) {}

  ngOnInit(): void {
    if (!this.tplHost || !this.sectionType) {
      return;
    }
    this.tplLoader.loadComponent(this.tplHost.viewContainerRef, this.sectionType, this.data);
  }
}
