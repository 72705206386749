import { CollabStatus, Role } from '@data/space/space.model';
import { guid } from '@datorama/akita';

export interface CollaboratorMetadata {
  advanced?: boolean;
}

export interface Collaborator extends CollaboratorMetadata {
  id: string;
  userRef: string;
  role: Role;
  status: CollabStatus;
  space: string;
}

export interface CollaboratorData extends CollaboratorMetadata {
  userRef: string;
  role: Role;
  status: CollabStatus;
  _id: string;
}

export function createCollaborator(c: CollaboratorData, spaceId: string): Collaborator {
  return {
    ...c,
    id: c._id.toString() || guid(),
    space: spaceId,
  };
}
