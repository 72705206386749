import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Collaborator } from '@data/collaborator/collaborator.model';
import { CollaboratorState, CollaboratorStore } from '@data/collaborator/collaborator.store';
import { UserQuery } from '@data/user/user.query';
import { SpaceQuery } from '@data/space/space.query';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { Space } from '@data/space/space.model';
import { UserData } from '@data/user-data/user-data.model';

@Injectable({ providedIn: 'root' })
export class CollaboratorQuery extends QueryEntity<CollaboratorState, Collaborator> {
  isAdmin$ = combineLatest([this.spaceQuery.getActiveWhenReady(), this.userQuery.select()]).pipe(
    switchMap(([space, user]) => {
      if (!user?.userData || !space) {
        return of(false);
      }
      if (user.isAdmin) {
        return of(true);
      }
      return this.selectEntity(this.isCollaboratorForSpace(space, user.userData)).pipe(map(this.isAdmin));
    }),
  );

  constructor(protected store: CollaboratorStore, private userQuery: UserQuery, private spaceQuery: SpaceQuery) {
    super(store);
  }

  get isAdvanced$(): Observable<boolean> {
    return combineLatest([this.userQuery.userData$, this.userQuery.assertedActiveSpaceId$]).pipe(
      switchMap(([ud, sid]) => {
        const space = this.spaceQuery.getEntity(sid);
        if (space?.parentRef) {
          sid = space.parentRef;
        }
        return this.selectAll({
          filterBy: (it: Collaborator) => {
            return it.userRef === ud?.id && it.space === sid && !!it?.advanced;
          },
        }).pipe(map((it) => !!it?.length));
      }),
    );
  }

  isValid(spaceId: string): boolean {
    let space = this.spaceQuery.getEntity(spaceId);
    if (!space) {
      return false;
    }
    if (this.userQuery.getValue().isAdmin) {
      return true;
    }
    if (space?.parentRef) {
      space = this.spaceQuery.getEntity(space.parentRef) ?? space;
    }
    const user = this.userQuery.userData()!;
    return this.hasEntity(this.isCollaboratorForSpace(space, user));
  }

  private isAdmin(c: Collaborator | undefined) {
    return c?.role === 'admin';
  }

  private isCollaboratorForSpace(space: Space, user: UserData) {
    let currentSpace = space;
    if (space?.parentRef) {
      currentSpace = this.spaceQuery.getEntity(space.parentRef) ?? space;
    }
    return (c: Collaborator) =>
      currentSpace.id === c.space && c.userRef.toString() === user.id.toString() && c.status === 'active';
  }
}
