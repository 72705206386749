import { Injectable } from '@angular/core';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ChatQuery } from '@data/chat/chat.query';
import { ChatStore } from '@data/chat/chat.store';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ChatMessage } from '@data/chat/chat.model';
import { CompanyQuery } from '@data/company/company.query';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private url = `${environment.apiUrl}/chat`;

  constructor(
    private chatQuery: ChatQuery,
    private chatStore: ChatStore,
    private http: HttpClient,
    private companyQuery: CompanyQuery,
  ) {}

  get(userId: string) {
    const cid$ = this.companyQuery.getActiveIdWhenReady();
    return cid$.pipe(switchMap(this.getMessages(userId)));
  }

  create(message: ChatMessage): Observable<any> {
    this.chatStore.add(message);
    return this.http.post(`${this.url}`, { message, cid: message.sender }).pipe(
      catchError((err) => {
        this.chatStore.remove(message.message);
        return of(err);
      }),
    );
  }

  private getMessages(userId: string) {
    return (cid?: string) => {
      if (!cid) return of([]);
      this.chatStore.setLoading(true);
      return this.http.get<ChatMessage[]>(`${this.url}/of/${cid}/${userId}`);
    };
  }
}
