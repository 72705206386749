import { guid } from '@datorama/akita';

export interface ChatMessage {
  id: string;
  sender: string;
  receiver: string;
  matchRef?: string;
  jobRef?: string;
  senderType: 0 | 1;
  timestamp: Date;
  message: string;
  read: boolean;
}

export function createChatMessage(msg: string, companyId: string, userId: string, jobId: string, matchId?: string) {
  const message: ChatMessage = {
    id: guid(),
    read: false,
    message: msg,
    receiver: userId,
    jobRef: jobId,
    matchRef: matchId,
    sender: companyId,
    senderType: 1,
    timestamp: new Date(),
  };
  return message;
}
