import { Injectable } from '@angular/core';

const CAT_MASK = 0b11111111;
const CUSTOM_MASK = 0b1;
const VALUE_MASK = 0b11111111111111111;

@Injectable({
  providedIn: 'root',
})
export class BenefitsMapperService {
  decodeBenefits(encoded: number[]): any[] {
    const selected: any[] = [];

    encoded.forEach((val) => {
      /* eslint-disable no-bitwise */
      const listIdx = Math.log2(CAT_MASK & (val >> 18));
      const fromAuto = !!(CUSTOM_MASK & (val >> 17));
      let index = VALUE_MASK & val;
      if (fromAuto) {
        index = Math.log2(index);
      }
      selected.push({ listIdx, index, fromAuto });
    });
    return selected;
  }
}
