import { Injectable } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { SpaceUI } from '@data/space/space.model';
import { Company } from '@data/company/company.model';
import { Subject, Subscription } from 'rxjs';
import { SpaceQuery } from '@data/space/space.query';
import { DynamicThemeService } from '@core/services/theme/dynamic-theme.service';

@Injectable({
  providedIn: 'root',
})
export class ThemeHandlerService {
  syncStopped = new Subject();

  private company: Company | null = null;

  private sub: Subscription | null = null;

  constructor(private spaceQuery: SpaceQuery, private theme: DynamicThemeService) {}

  syncCompanyTheme(c: Company): void {
    this.company = c;
    if (!this.sub) {
      this.sync();
    } else {
      this.instant();
    }
  }

  stopSync(): void {
    this.theme.reset();
    this.syncStopped.next(true);
    this.sub = null;
  }

  sync(): void {
    this.sub = this.spaceQuery
      .selectActiveMetadata()
      .pipe(takeUntil(this.syncStopped))
      .subscribe((ui) => {
        this.updateTheme(ui);
      });
  }

  instant(): void {
    this.sub = this.spaceQuery.selectActiveMetadata().subscribe((ui) => {
      this.updateTheme(ui);
    });
  }

  private updateTheme(meta: SpaceUI): void {
    if (meta.applyTheme) {
      this.theme.primaryColor = this.company?.color?.primary ?? this.theme.primaryColor;
      this.theme.accentColor = this.company?.color?.accent ?? this.theme.accentColor;
      this.theme.savePrimaryColor();
      this.theme.saveAccentColor();
    } else {
      this.theme.reset(meta.mode);
    }
  }
}
