export const PERKS = [
  'MAPPING.PERKS.good_salary',
  'MAPPING.PERKS.no_shift',
  'MAPPING.PERKS.no_weekend',
  'MAPPING.PERKS.work_life_balance',
  'MAPPING.PERKS.infrastructure',
  'MAPPING.PERKS.career',
  'MAPPING.PERKS.education',
  'MAPPING.PERKS.security',
  'MAPPING.PERKS.takeover',
];

export const FIN_BENEFITS = [
  'MAPPING.BENEFITS.FIN.13_salaries',
  'MAPPING.BENEFITS.FIN.14_salaries',
  'MAPPING.BENEFITS.FIN.christmas_bonus',
  'MAPPING.BENEFITS.FIN.holiday_bonus',
  'MAPPING.BENEFITS.FIN.high_salary',
  'MAPPING.BENEFITS.FIN.performance_bonus',
  'MAPPING.BENEFITS.FIN.pension_plan',
  'MAPPING.BENEFITS.FIN.capital_forming',
  'MAPPING.BENEFITS.FIN.rent_bonus',
];
export const EDU_BENEFITS = [
  'MAPPING.BENEFITS.EDU.takeover',
  'MAPPING.BENEFITS.EDU.education',
  'MAPPING.BENEFITS.EDU.programs',
  'MAPPING.BENEFITS.EDU.mentor',
  'MAPPING.BENEFITS.EDU.exam_prep',
  'MAPPING.BENEFITS.EDU.book_bonus',
  'MAPPING.BENEFITS.EDU.e_learning',
];
export const MOBILITY_BENEFITS = [
  'MAPPING.BENEFITS.MOBILITY.connection',
  'MAPPING.BENEFITS.MOBILITY.parking',
  'MAPPING.BENEFITS.MOBILITY.job_ticket',
  'MAPPING.BENEFITS.MOBILITY.travel_bonus',
  'MAPPING.BENEFITS.MOBILITY.train_ticket',
  'MAPPING.BENEFITS.MOBILITY.company_car',
  'MAPPING.BENEFITS.MOBILITY.job_bike',
];
export const WORK_BENEFITS = [
  'MAPPING.BENEFITS.WORK.flexitime',
  'MAPPING.BENEFITS.WORK.work_time_account',
  'MAPPING.BENEFITS.WORK.home_office',
];
export const LEISURE_BENEFITS = [
  'MAPPING.BENEFITS.LEISURE.events',
  'MAPPING.BENEFITS.LEISURE.activities',
  'MAPPING.BENEFITS.LEISURE.trainee_days',
];
export const CATERING_BENEFITS = [
  'MAPPING.BENEFITS.CATERING.canteen',
  'MAPPING.BENEFITS.CATERING.drinks',
  'MAPPING.BENEFITS.CATERING.snacks',
  'MAPPING.BENEFITS.CATERING.fruit',
  'MAPPING.BENEFITS.CATERING.food_bonus',
];
export const HEALTH_BENEFITS = [
  'MAPPING.BENEFITS.HEALTH.sport',
  'MAPPING.BENEFITS.HEALTH.health',
  'MAPPING.BENEFITS.HEALTH.fitness_bonus',
  'MAPPING.BENEFITS.HEALTH.ergonomic_office',
];
export const TEC_BENEFITS = [
  'MAPPING.BENEFITS.TEC.laptop',
  'MAPPING.BENEFITS.TEC.pc',
  'MAPPING.BENEFITS.TEC.handy',
  'MAPPING.BENEFITS.TEC.wifi',
];

export const BENEFITS: { [key: string]: string[] } = {
  financial: FIN_BENEFITS,
  education: EDU_BENEFITS,
  mobility: MOBILITY_BENEFITS,
  work: WORK_BENEFITS,
  leisure: LEISURE_BENEFITS,
  catering: CATERING_BENEFITS,
  health: HEALTH_BENEFITS,
  technic: TEC_BENEFITS,
};

export const BENEFITS_SELECTOR_CONFIG = [
  {
    data: FIN_BENEFITS,
    label: 'BENEFITS.fin',
    groupName: 'financial',
    icon: 'savings',
  },
  {
    data: EDU_BENEFITS,
    label: 'BENEFITS.edu',
    groupName: 'education',
    icon: 'school',
  },
  {
    data: MOBILITY_BENEFITS,
    label: 'BENEFITS.mobility',
    groupName: 'mobility',
    icon: 'directions_car',
  },
  {
    data: WORK_BENEFITS,
    label: 'BENEFITS.work',
    groupName: 'work',
    icon: 'work',
  },
  {
    data: LEISURE_BENEFITS,
    label: 'BENEFITS.leisure',
    groupName: 'leisure',
    icon: 'festival',
  },
  {
    data: CATERING_BENEFITS,
    label: 'BENEFITS.catering',
    groupName: 'catering',
    icon: 'restaurant',
  },
  {
    data: HEALTH_BENEFITS,
    label: 'BENEFITS.health',
    groupName: 'health',
    icon: 'fitness_center',
  },
  {
    data: TEC_BENEFITS,
    label: 'BENEFITS.tech',
    groupName: 'technic',
    icon: 'devices',
  },
];
