import { Injectable, Type } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DialogService<T, D> {
  dialogRef?: MatDialogRef<T>;

  private readonly DEFAULT_WIDTH = '700px';

  constructor(private dialog: MatDialog) {}

  public open<I>(type: Type<T>, data?: I, config?: MatDialogConfig): void {
    this.dialogRef = this.dialog.open(type, {
      data,
      width: this.DEFAULT_WIDTH,
      ...config,
    });
  }

  public confirmed(): Observable<D> {
    return (
      this.dialogRef?.afterClosed().pipe(
        take(1),
        map((res) => {
          return res;
        }),
      ) ?? of(null)
    );
  }
}
