export const EDUCATION_LEVEL = [
  'MAPPING.EDUCATION.NONE',
  'MAPPING.EDUCATION.MS',
  'MAPPING.EDUCATION.Q_MS',
  'MAPPING.EDUCATION.MR',
  'MAPPING.EDUCATION.FA',
  'MAPPING.EDUCATION.A',
  'MAPPING.EDUCATION.OTHER',
  'MAPPING.EDUCATION.SUPPORT',
];

export enum Education {
  MS = 1,
  Q_MS,
  MR,
  FA,
  A,
  SUPPORT = 7,
}
