export enum FeedbackType {
  TECHNICAL_PROBLEM,
  GENERAL_PROBLEM,
  QUESTION,
  HELP,
  FEATURE_REQUEST,
  FEEDBACK,
  BOOKING,
  BOOKING_BRANCHES,
}
