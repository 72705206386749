import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Subscription } from './subscription.model';

export interface SubscriptionState extends EntityState<Subscription> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'subscription', resettable: true, idKey: 'id' })
export class SubscriptionStore extends EntityStore<SubscriptionState, Subscription, string> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
