import { Component } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { Application, hasAppointment, isNotArchivedOrFinished } from '@data/application/application.model';
import { ApplicationMetaStatus, ApplicationStage } from '@core/enums/data/application-status';
import { Actions } from '@ngneat/effects-ng';
import { ApplicationActions } from '@data/application/application.actions';
import { DialogService } from '@shared/components/dialog/dialog.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'recrewt-application-menu',
  templateUrl: './application-menu.component.html',
  styleUrls: ['./application-menu.component.scss'],
})
export class ApplicationMenuComponent extends MenuComponent<Application> {
  strategy: ApplicationMenuStrategy = {
    showDownloadApplication: (a: Application) => !!a.hasDocument,
    showStatusChange: (a: Application) => isNotArchivedOrFinished(a),
    showDateChange: (a: Application) => hasAppointment(a),
    showArchive: (a: Application) => a.status === ApplicationMetaStatus.NEW,
    showUnarchive: (a: Application) => a.status === ApplicationMetaStatus.ARCHIVED,
  };

  constructor(private actions: Actions, private dialog: DialogService<ConfirmDialogComponent, any>) {
    super();
  }

  downloadApplication(application: Application): void {
    if (!application.hasDocument) return;
    this.actions.dispatch(
      ApplicationActions.download({
        aid: application.id,
        userId: application.userId,
        onload: (url) => this.download(url, application.userName),
      }),
    );
  }

  setStage([application, stage]: [Application, ApplicationStage]): void {
    this.actions.dispatch(ApplicationActions.updateStage({ application, stage }));
  }

  archive(application: Application): void {
    this.actions.dispatch(
      ApplicationActions.updateStatus({ aid: application.id, status: ApplicationMetaStatus.ARCHIVED }),
    );
  }

  delete(application: Application): void {
    this.dialog.open<ConfirmDialogData>(ConfirmDialogComponent, {
      confirmColor: 'warn',
      title: 'MENUS.APPLICATION.DELETE.title',
      message: 'MENUS.APPLICATION.DELETE.message',
      confirmText: 'COMMON.delete',
    });

    this.dialog.confirmed().subscribe((result) => {
      if (!result) {
        return;
      }

      this.actions.dispatch(
        ApplicationActions.updateStatus({ aid: application.id, status: ApplicationMetaStatus.DELETED }),
      );
    });
  }

  unarchive(application: Application): void {
    this.actions.dispatch(ApplicationActions.updateStatus({ aid: application.id, status: ApplicationMetaStatus.NEW }));
  }

  private download(url: string, applicantName: string): void {
    const link = document.createElement('a');
    const sanitizedApplicantName = applicantName.replace(' ', '_');
    link.href = url;
    link.download = `Bewerbung_${sanitizedApplicantName}.pdf`;
    link.click();
  }
}

export interface ApplicationMenuStrategy extends MenuStrategy {
  showDownloadApplication(a: Application): boolean;

  showStatusChange(a: Application): boolean;

  showDateChange(a: Application): boolean;

  showArchive(a: Application): boolean;

  showUnarchive(a: Application): boolean;
}
