import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SegmentData } from '@shared/components/path-headline/breadcrumb.component';

@Component({
  selector: 'recrewt-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  categories: Set<any> = new Set<any>();

  currentCategory = null;

  imagesForCategory: GalleryImage[] = [];

  segments: SegmentData[] = [];

  @Output() imageClicked = new EventEmitter<string>();

  @Input() images: GalleryImage[] = [];

  @Input() displayCategoryWith: (it: any) => string = (it) => it;

  ngOnInit(): void {
    this.categories = this.getCategories();
  }

  toCategories(): void {
    this.segments.pop();
    this.currentCategory = null;
    this.imagesForCategory = [];
  }

  showImagesForCategory(category: any): void {
    this.segments.push({
      segment: this.displayCategoryWith(category),
      ref: '',
    });
    this.currentCategory = category;
    this.imagesForCategory = this.images.filter((it) => it.category === category);
  }

  private getCategories(): Set<any> {
    return new Set(this.images.map((it) => it.category));
  }
}

export interface GalleryImage {
  category: any;
  image: string;
  thumbnail: string;
}
