<div class="skeleton" fxLayout="row" fxLayoutGap="8px">
  <ngx-skeleton-loader
    [theme]="{ width: width1, height: '32px', 'background-color': color, margin: '0', 'border-radius': '6px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    [theme]="{ width: width2, height: '32px', 'background-color': color, margin: '0', 'border-radius': '6px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    [theme]="{ width: width3, height: '32px', 'background-color': color, margin: '0', 'border-radius': '6px' }"
  ></ngx-skeleton-loader>
  <ngx-skeleton-loader
    [theme]="{ width: width4, height: '32px', 'background-color': color, margin: '0', 'border-radius': '6px' }"
  ></ngx-skeleton-loader>
</div>
