<div [formGroup]="parentFormGroup">
  <quill-editor
    [formControlName]="formCrtlName"
    [modules]="config"
    [placeholder]="placeholder"
    [styles]="editorStyle"
    [sanitize]="true"
    filterNull
  ></quill-editor>
</div>
