import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MobileNotifierService {
  private url = `${environment.apiUrl}/mobile/notify`;

  constructor(private http: HttpClient) {}

  notifyNewMessage(userId: string, companyId: string, companyName: string, message: string): void {
    this.http.post(`${this.url}/new_message`, { userId, companyId, companyName, message }).subscribe();
  }
}
