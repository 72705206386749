<div class="p-h-6">
  <form [formGroup]="frmScholastic" fxLayout="column">
    <div>
      <h4>{{ 'JOB_CREATOR.SCHOLASTIC.HEADLINES.school' | translate }}</h4>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'JOB_CREATOR.SCHOLASTIC.HEADLINES.school' | translate }}</mat-label>
        <input formControlName="schoolName" matInput />
      </mat-form-field>
      <recrewt-location-selector [formGroup]="frmScholastic" controlName="schoolLocation"></recrewt-location-selector>
    </div>
    <div *ngIf="type === BookableType.COMPOUND_STUDY || type === BookableType.DUAL_STUDY">
      <h4>{{ 'JOB_CREATOR.SCHOLASTIC.HEADLINES.about' | translate }}</h4>
      <recrewt-select
        (selectChange)="updateCourse($event)"
        *ngIf="type === BookableType.DUAL_STUDY"
        [matStyle]="true"
        [options]="courses"
        [parentFormGroup]="frmScholastic"
        formControlName="courseId"
        labelKey="name.de"
        placeholder="JOB_CREATOR.SCHOLASTIC.HEADLINES.course"
        valueKey="id"
      ></recrewt-select>
      <mat-form-field appearance="outline">
        <input formControlName="courseName" matInput />
        <mat-label>{{ 'JOB_CREATOR.SCHOLASTIC.HEADLINES.course_name' | translate }}</mat-label>
      </mat-form-field>
      <recrewt-rich-text-editor
        [config]="QUILL_CONFIG"
        [parentFormGroup]="frmScholastic"
        [placeholder]="'JOB_CREATOR.SCHOLASTIC.HEADLINES.phases' | translate"
        formCrtlName="phases"
      ></recrewt-rich-text-editor>
      <mat-form-field appearance="outline" class="m-t-3">
        <mat-label>{{ 'JOB_CREATOR.SCHOLASTIC.HEADLINES.degree' | translate }}</mat-label>
        <input formControlName="degree" matInput />
      </mat-form-field>
      <div class="width-match-parent m-b-3">
        <mat-checkbox class="width-match-parent" formControlName="semesterAbroad">{{
          'JOB_CREATOR.SCHOLASTIC.HEADLINES.semester_abroad' | translate
        }}</mat-checkbox>
      </div>
    </div>
    <div>
      <h4>{{ 'JOB_CREATOR.SCHOLASTIC.HEADLINES.job_opportunities' | translate }}</h4>
      <recrewt-rich-text-editor
        [config]="QUILL_CONFIG"
        [parentFormGroup]="frmScholastic"
        [placeholder]="'JOB_CREATOR.SCHOLASTIC.HEADLINES.job_opportunities' | translate"
        formCrtlName="jobOpportunities"
      ></recrewt-rich-text-editor>
    </div>
  </form>
</div>
