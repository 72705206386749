import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { base64ToFile, ImageCroppedEvent, ImageCropperComponent, LoadedImage } from 'ngx-image-cropper';
import { GalleryImage } from '@lib/file-elements/components/image-gallery/image-gallery.component';
import { getColorFromImage, rgbaToHex } from '@shared/util/color.util';

@Component({
  selector: 'recrewt-image-cropper',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.scss'],
})
export class ImageCropComponent implements OnInit {
  imageChangedEvent: any;

  loading = true;

  compress = false;

  committing = false;

  backgroundColor = '#fff';

  @ViewChild(ImageCropperComponent) cropper?: ImageCropperComponent;

  private inputImage?: File | null;

  private croppedImage?: File;

  constructor(
    public dialogRef: MatDialogRef<ImageCropComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageCropperConfig,
  ) {
    dialogRef.disableClose = true;
    this.imageChangedEvent = this.data.fileChangedEvent;
  }

  ngOnInit(): void {
    const input = this.data.fileChangedEvent.target as HTMLInputElement;
    this.inputImage = input?.files?.item(0);
  }

  onImageCropped(event: ImageCroppedEvent): void {
    if (!event.base64) {
      return;
    }
    const blob = base64ToFile(event.base64);
    if (!this.inputImage) {
      return;
    }
    this.croppedImage = new File([blob], this.inputImage?.name, { type: this.inputImage?.type });
  }

  onImageLoaded(img: LoadedImage) {
    this.loading = false;

    const image = img.original.image;
    const rgba = getColorFromImage(image, 0, 0);
    this.backgroundColor = rgbaToHex(rgba.r, rgba.g, rgba.b, rgba.a);
  }

  onImageChanged(event: any): void {
    this.loading = true;
    this.imageChangedEvent = event;
    this.compress = true;
  }

  noActionClicked(): void {
    this.dialogRef.close(null);
  }

  onDelete(): void {
    this.dialogRef.close(-1);
  }

  onConfirm(): void {
    this.committing = true;
    this.cropper?.crop();
    this.dialogRef.close({
      file: this.croppedImage,
      compress: this.compress,
    });
  }
}

export interface ImageCropperConfig {
  fileChangedEvent?: any;
  aspectRatio: number;
  resizeToWidth: number;
  maintainAspectRatio: boolean;
  roundCropper: boolean;
  galleryImages?: GalleryImage[];
  displayGalleryCategoriesWith?: (it: number) => string;
}
