<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container>
      <button mat-menu-item routerLink="info/agb">
        <mat-icon>gavel</mat-icon>
        <span>{{ 'MENUS.USER.agb' | translate }}</span>
      </button>
      <button mat-menu-item routerLink="info/privacy">
        <mat-icon>lock</mat-icon>
        <span>{{ 'MENUS.USER.privacy' | translate }}</span>
      </button>
      <button mat-menu-item routerLink="info/impressum">
        <mat-icon>contact_support</mat-icon>
        <span>{{ 'MENUS.USER.impressum' | translate }}</span>
      </button>
      <mat-divider></mat-divider>
      <button (click)="closeSpace()" *ngIf="strategy.showCloseSpace()" mat-menu-item>
        <mat-icon>transit_enterexit</mat-icon>
        <span>{{ 'MENUS.USER.close' | translate }}</span>
      </button>
      <button (click)="resetPassword()" mat-menu-item>
        <mat-icon>lock_open</mat-icon>
        <span>{{ 'MENUS.USER.reset_password' | translate }}</span>
      </button>
      <button (click)="logout()" *ngIf="strategy.showLogout()" mat-menu-item>
        <mat-icon>logout</mat-icon>
        <span>{{ 'MENUS.USER.logout' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
