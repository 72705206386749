import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLayoutComponent } from './layout/auth-layout/auth-layout.component';
import { VerifiedGuard } from '@core/guards/verified/verified.guard';
import { InfoCardLayoutComponent } from './layout/info-card-layout/info-card-layout.component';
import { appRouteNames } from './app.routing.names';
import { ToolbarLayoutComponent } from './layout/toolbar-layout/toolbar-layout.component';
import { ValidSpaceGuard } from '@core/guards/valid-space/valid-space.guard';
import { ActiveSpaceGuard } from '@core/guards/active-space/active-space.guard';
import { PrimaryBgLayoutComponent } from './layout/primary-bg-layout/primary-bg-layout.component';
import { AuthGuard as auth } from '@auth0/auth0-angular';
import { AuthGuard } from '@core/guards/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home/spaces',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: ToolbarLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: appRouteNames.HOME,
        canActivate: [auth, VerifiedGuard, ActiveSpaceGuard],
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: appRouteNames.SPACE,
        canActivate: [auth, VerifiedGuard, ValidSpaceGuard],
        loadChildren: () => import('./modules/space/space.module').then((m) => m.SpaceModule),
      },
    ],
  },
  {
    path: appRouteNames.AUTH,
    component: AuthLayoutComponent,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: appRouteNames.VERIFICATION,
    component: InfoCardLayoutComponent,
    loadChildren: () => import('./modules/verification/verification.module').then((m) => m.VerificationModule),
  },
  {
    path: appRouteNames.ERROR,
    component: InfoCardLayoutComponent,
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: appRouteNames.INFO,
    component: PrimaryBgLayoutComponent,
    loadChildren: () => import('./modules/info/info.module').then((m) => m.InfoModule),
  },
  {
    path: '**',
    redirectTo: 'error/404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
