import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FairBoothQuery } from '@data/fair-booth/fair-booth.query';
import { FairBoothStore } from '@data/fair-booth/fair-booth.store';
import { DataBaseService } from '@data/data.base.service';
import { FairBooth, FairBoothInput } from '@data/fair-booth/fair-booth.model';
import { CompanyQuery } from '@data/company/company.query';
import { switchMap, tap } from 'rxjs/operators';
import { Session } from '@data/session/session.model';
import { SessionStore } from '@data/session/session.store';
import { SessionQuery } from '@data/session/session.query';

@Injectable({
  providedIn: 'root',
})
export class FairBoothService extends DataBaseService<FairBoothInput, FairBooth> {
  constructor(
    private companyQuery: CompanyQuery,
    private sessionQuery: SessionQuery,
    private sessionStore: SessionStore,
    http: HttpClient,
    fairBoothQuery: FairBoothQuery,
    fairBoothStore: FairBoothStore,
  ) {
    super(http, fairBoothStore, fairBoothQuery, `fairs/booths`);
  }

  get(params?: any, useCache: boolean = true) {
    const company$ = this.companyQuery.getActiveIdWhenReady();
    company$.subscribe((company) => super.get({ ...params, company }, useCache));
  }

  update(id: string, changes: Partial<FairBooth>, params?: any) {
    const company$ = this.companyQuery.getActiveIdWhenReady();
    company$.subscribe((company) => super.update(id, changes, { ...params, company }));
  }

  create(data: FairBoothInput) {
    const company$ = this.companyQuery.getActiveIdWhenReady();
    return company$.pipe(
      switchMap((company) => {
        return this.http
          .post<{ fair?: Session; booth?: FairBooth }>(`${this.url}`, { ...data, company })
          .pipe(tap((it) => this.addToStores(it)));
      }),
    );
  }

  delete(id: string, params?: any) {
    const company$ = this.companyQuery.getActiveIdWhenReady();
    company$.subscribe((company) => super.delete(id, { ...params, company }));
  }

  private addToStores(it: { fair?: Session; booth?: FairBooth } | null) {
    if (!it?.fair || !it.booth) {
      return;
    }
    this.sessionStore.add(it.fair);
    this.store.add(it.booth);
  }
}
