<div class="p-h-6">
  <form [formGroup]="frmBenefits">
    <div fxLayout="column">
      <h4>{{ 'JOB_CREATOR.BENEFITS.HEADLINES.salary' | translate }}</h4>
      <div class="m-b-2">
        <recrewt-labelled-line headline="JOB_CREATOR.GENERAL.HEADLINES.salary_unspecified" labelWidth="350px">
          <mat-slide-toggle
            (change)="onAtInterviewToggled($event.checked)"
            color="primary"
            formControlName="salaryUnspecified"
          ></mat-slide-toggle>
        </recrewt-labelled-line>
      </div>
      <ng-container *ngFor="let year of salaryControlNames">
        <recrewt-labelled-line [headline]="'JOB_DETAILS.year' | translate: { year: +year + 1 }" labelWidth="110px">
          <recrewt-input-slider
            [formCtrlName]="year"
            [parentFormGroup]="salaryGroup"
            [require]="true"
            [showThumb]="false"
            fxFlex="grow"
            max="3000"
            step="10"
          ></recrewt-input-slider>
        </recrewt-labelled-line>
      </ng-container>
    </div>
    <div fxLayout="column">
      <h4>{{ 'JOB_CREATOR.BENEFITS.HEADLINES.vacation' | translate }}</h4>
      <recrewt-input-slider
        [parentFormGroup]="frmBenefits"
        [require]="true"
        formCtrlName="vacation"
        fxFlex="grow"
        max="50"
        step="1"
      ></recrewt-input-slider>
    </div>
    <div fxLayout="column">
      <h4>{{ 'JOB_CREATOR.BENEFITS.HEADLINES.benefits' | translate }}</h4>
      <p class="on-surface-medium">{{ 'JOB_CREATOR.BENEFITS.HINTS.benefits' | translate }}</p>

      <recrewt-categorical-selector-group
        (selectionChanged)="updateHighlightBenefits($event)"
        [config]="datasets"
        [parentFormGroup]="benefitsGroup"
        [selected]="highlightedBenefits"
      ></recrewt-categorical-selector-group>
    </div>
  </form>
</div>
