import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  dialogRef?: MatDialogRef<ConfirmDialogComponent>;

  constructor(private dialog: MatDialog) {}

  public open(options: ConfirmDialogData): void {
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '700px',
      data: options,
    });
  }

  public confirmed(): Observable<any> {
    return (
      this.dialogRef?.afterClosed().pipe(
        take(1),
        map((res) => {
          return res;
        }),
      ) ?? of(null)
    );
  }
}
