<button
  *ngIf="show"
  @bubbleGrow
  [class.left]="position === 'left'"
  [color]="color"
  [disabled]="disabled"
  class="extended-fab-button"
  mat-fab
>
  <mat-icon *ngIf="iconPosition === 'left'" class="prefix">{{ icon }}</mat-icon>
  <span class="extended-fab-button__text" fxHide.lt-sm>{{ text | translate }}</span>
  <mat-icon *ngIf="iconPosition === 'right'" class="suffix">{{ icon }}</mat-icon>
</button>
