import { GalleryImage } from '@lib/file-elements/components/image-gallery/image-gallery.component';

export const STOCK_IMAGES: GalleryImage[] = [
  {
    image: 'assets/banners/stock_image (5).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (5).jpg',
    category: 0,
  },
  {
    image: 'assets/banners/stock_image (27).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (27).jpg',
    category: 0,
  },
  {
    image: 'assets/banners/stock_image (28).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (28).jpg',
    category: 0,
  },
  {
    image: 'assets/banners/stock_image (38).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (38).jpg',
    category: 1,
  },
  {
    image: 'assets/banners/stock_image (39).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (39).jpg',
    category: 1,
  },
  {
    image: 'assets/banners/stock_image (40).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (40).jpg',
    category: 1,
  },
  {
    image: 'assets/banners/stock_image (1).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (1).jpg',
    category: 2,
  },
  {
    image: 'assets/banners/stock_image (2).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (2).jpg',
    category: 2,
  },
  {
    image: 'assets/banners/stock_image (3).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (3).jpg',
    category: 2,
  },
  {
    image: 'assets/banners/stock_image (5).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (5).jpg',
    category: 2,
  },
  {
    image: 'assets/banners/stock_image (2).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (2).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (4).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (4).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (15).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (15).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (16).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (16).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (17).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (17).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (18).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (18).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (19).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (19).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (20).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (20).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (21).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (21).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (22).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (22).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (23).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (23).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (24).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (24).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (25).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (25).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (30).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (30).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (38).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (38).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (39).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (39).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (40).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (40).jpg',
    category: 3,
  },
  {
    image: 'assets/banners/stock_image (14).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (14).jpg',
    category: 4,
  },
  {
    image: 'assets/banners/stock_image (5).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (5).jpg',
    category: 5,
  },
  {
    image: 'assets/banners/stock_image (6).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (6).jpg',
    category: 5,
  },
  {
    image: 'assets/banners/stock_image (27).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (27).jpg',
    category: 5,
  },
  {
    image: 'assets/banners/stock_image (9).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (9).jpg',
    category: 6,
  },
  {
    image: 'assets/banners/stock_image (11).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (11).jpg',
    category: 6,
  },
  {
    image: 'assets/banners/stock_image (11).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (11).jpg',
    category: 7,
  },
  {
    image: 'assets/banners/stock_image (33).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (33).jpg',
    category: 7,
  },
  {
    image: 'assets/banners/stock_image (26).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (26).jpg',
    category: 8,
  },
  {
    image: 'assets/banners/stock_image (13).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (13).jpg',
    category: 9,
  },
  {
    image: 'assets/banners/stock_image (14).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (14).jpg',
    category: 9,
  },
  {
    image: 'assets/banners/stock_image (11).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (11).jpg',
    category: 10,
  },
  {
    image: 'assets/banners/stock_image (4).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (4).jpg',
    category: 11,
  },
  {
    image: 'assets/banners/stock_image (15).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (15).jpg',
    category: 11,
  },
  {
    image: 'assets/banners/stock_image (16).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (16).jpg',
    category: 12,
  },
  {
    image: 'assets/banners/stock_image (29).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (29).jpg',
    category: 12,
  },
  {
    image: 'assets/banners/stock_image (1).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (1).jpg',
    category: 13,
  },
  {
    image: 'assets/banners/stock_image (12).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (12).jpg',
    category: 13,
  },
  {
    image: 'assets/banners/stock_image (10).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (10).jpg',
    category: 14,
  },
  {
    image: 'assets/banners/stock_image (34).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (34).jpg',
    category: 15,
  },
  {
    image: 'assets/banners/stock_image (7).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (7).jpg',
    category: 16,
  },
  {
    image: 'assets/banners/stock_image (8).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (8).jpg',
    category: 16,
  },
  {
    image: 'assets/banners/stock_image (31).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (31).jpg',
    category: 16,
  },
  {
    image: 'assets/banners/stock_image (32).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (32).jpg',
    category: 17,
  },
  {
    image: 'assets/banners/stock_image (36).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (36).jpg',
    category: 18,
  },
  {
    image: 'assets/banners/stock_image (9).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (9).jpg',
    category: 19,
  },
  {
    image: 'assets/banners/stock_image (37).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (37).jpg',
    category: 20,
  },
  {
    image: 'assets/banners/stock_image (30).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (30).jpg',
    category: 21,
  },
  {
    image: 'assets/banners/stock_image (36).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (36).jpg',
    category: 22,
  },
  {
    image: 'assets/banners/stock_image (37).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (37).jpg',
    category: 22,
  },
  {
    image: 'assets/banners/stock_image (7).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (7).jpg',
    category: 23,
  },
  {
    image: 'assets/banners/stock_image (8).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (8).jpg',
    category: 23,
  },
  {
    image: 'assets/banners/stock_image (35).jpg',
    thumbnail: 'assets/banners_thumbnail/stock_image (35).jpg',
    category: 23,
  },
];
