import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'recrewt-small-link-box',
  templateUrl: './small-link-box.component.html',
  styleUrls: ['./small-link-box.component.scss'],
})
export class SmallLinkBoxComponent {
  @Input() message?: string;

  @Input() linkText?: string;

  @Input() route?: string;

  @Output() action = new EventEmitter<void>();
}
