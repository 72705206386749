import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({
  name: 'translateField',
})
export class TranslateFieldPipe extends TranslatePipe implements PipeTransform {
  transform(obj: any, field: string): any[] {
    if (typeof obj !== 'object') {
      return obj;
    }
    return super.transform(`${obj[field]}`);
  }
}
