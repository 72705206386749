import { DocType, FileData, Realm } from '@data/file/file.model';
import { Observable } from 'rxjs';

import { HttpEvent } from '@angular/common/http';

export type UploadOptions = {
  compress?: boolean;
  forBranches?: boolean;
};

export interface IIonosService {
  upload(
    realm: Realm,
    type: DocType,
    id: string,
    file: File | Blob,
    options: UploadOptions,
  ): Observable<HttpEvent<string[]> | null>;

  delete(realm: Realm, type: DocType, id: string, fileName: string): void;

  meta(realm: Realm, type: DocType, id: string): Observable<FileData[]>;

  download(realm: Realm, type: DocType, id: string, name: string): Observable<Blob>;
}
