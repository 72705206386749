import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuComponent } from '@shared/menus/menu/menu.component';

@Component({
  selector: 'recrewt-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent extends MenuComponent<any> {
  @Output() logoutClicked = new EventEmitter<any>();

  @Output() resetPasswordClicked = new EventEmitter<any>();

  @Output() spaceClosed = new EventEmitter<any>();

  private readonly defaultStrategy: UserMenuStrategy = {
    showLogout: () => true,
    showCloseSpace: () => true,
  };

  @Input() strategy: UserMenuStrategy = this.defaultStrategy;

  logout(): void {
    this.logoutClicked.emit();
  }

  closeSpace(): void {
    this.spaceClosed.emit();
  }

  resetPassword(): void {
    this.resetPasswordClicked.emit();
  }
}

export interface UserMenuStrategy {
  showLogout(): boolean;

  showCloseSpace(): boolean;
}
