<recrewt-details-page-base
  (back)="back()"
  [imageUrl]="bannerSrc$ | async | safe : 'resourceUrl'"
  [selected]="'MATCHING.DETAILS.job'"
>
  <ng-container gradicon>
    <img [src]="logoSrc$ | async | safe : 'resourceUrl'" alt="logo" class="logo" />
  </ng-container>
  <ng-container content>
    <div class="p-3" fxLayout="column" fxLayoutAlign="start center">
      <h1 class="mat-title text-align-center m-t-4 p-h-1 p-b-1 m-b-1" style="font-size: 20px">
        {{ job?.identifiableName }}
      </h1>
      <h4 class="text-align-center">{{ companyName }}</h4>
      <p class="mat-hint text-align-center">
        {{ jobData?.jobSite?.location?.address ?? job?.location?.address }}
      </p>
      <div
        class="outline-button width-match-parent p-1 m-b-2"
        fxLayout="row"
        fxLayoutAlign="center center"
        fxLayoutGap="16px"
      >
        <mat-icon>business</mat-icon>
        <p class="no-pad mat-body-2">Über {{ companyName }}</p>
      </div>
      <div class="m-b-2" fxLayoutAlign="center center">
        <mat-icon class="m-r-1 mobile-gradient">school</mat-icon>
        <p class="no-pad on-surface-medium mat-body-1">
          {{ job?.matchData?.general?.educationLevel ?? 0 | decToStr : 'education' | translate }}
        </p>
      </div>
      <div *ngIf="job?.mode === 0" class="m-b-2" fxLayoutAlign="center center">
        <mat-icon class="m-r-1 mobile-gradient">date_range</mat-icon>
        <p class="no-pad on-surface-medium mat-body-1">
          {{ job?.startDate | date : 'YYYY' }}
        </p>
      </div>
      <div class="width-match-parent text-align-start m-b-2" fxLayout="column">
        <ng-container *ngIf="!!job?.desc?.length">
          <span class="mat-subheading-1 m-b-1">Beschreibung.</span>
          <p [innerHTML]="job?.desc" class="mat-hint no-pad"></p>
        </ng-container>
        <ng-container *ngIf="!!job?.tasks?.length">
          <span class="mat-subheading-1 m-b-1">Deine Aufgaben.</span>
          <div fxLayout="row wrap">
            <recrewt-colored-item
              *ngFor="let task of job?.tasks; index as i"
              [class.m-l-1]="!!(i % 2)"
              [class.m-r-1]="!(i % 2)"
              [darken]="i % 4 === 1 || i % 4 === 2"
              [text]="task"
              class="m-b-2 max-50"
              fxFlex="calc(50% - 8px)"
            ></recrewt-colored-item>
          </div>
        </ng-container>
        <h4>Deine Vorteile.</h4>
        <div class="width-match-parent" fxLayout="column" fxLayoutAlign="start center">
          <div class="width-match-parent p-v-2" fxLayout="row" fxLayoutAlign="space-between">
            <ng-container *ngFor="let benefit of highlightBenefits | slice : 0 : 3">
              <recrewt-highlight-item
                [category]="benefit.title"
                [icon]="benefit.icon"
                fxFlex="30"
              ></recrewt-highlight-item>
            </ng-container>
          </div>
          <div *ngIf="job?.vacation" class="m-b-2" fxLayoutAlign="center center">
            <mat-icon class="m-r-1 on-surface-medium">date_range</mat-icon>
            <p class="no-pad on-surface-medium mat-body-1">{{ job.vacation }} Urlaubstage</p>
          </div>
          <div class="p-b-2">
            <p class="mat-caption pointer primary">{{ 'Alle Benefits' | uppercase }}</p>
          </div>
        </div>
        <div *ngIf="!job?.salaryUnspecified" class="m-b-2" fxLayout="column" fxLayoutGap="8px">
          <span class="mat-subheading-1">Lohn pro Ausbildungsjahr.</span>
          <recrewt-salary-bars [bars]="job?.estimatedSalaryEUR ?? [0, 0, 0]"></recrewt-salary-bars>
        </div>
        <ng-container *ngIf="!!job?.matchData?.general?.requirements?.length">
          <span class="mat-subheading-1 m-b-1">Was wir suchen.</span>
          <div class="m-b-1">
            <recrewt-stage-item
              *ngFor="let req of job?.matchData?.general?.requirements"
              [desc]="req.desc"
              [title]="req.category | decToStr : 'requirements' | translate"
              color="accent"
              icon="check_circle"
            ></recrewt-stage-item>
          </div>
        </ng-container>
        <ng-container *ngIf="job?.mode == 0">
          <span class="mat-subheading-1 m-b-1">So läuft die Bewerbung ab.</span>
          <div class="m-b-1">
            <recrewt-stage-item
              [desc]="job?.deadline | date : 'dd.MM.yy'"
              color="primary"
              icon="event_available"
              title="Bewerbungsfrist."
            ></recrewt-stage-item>
            <recrewt-stage-item
              [desc]="job?.startDate | date : 'dd.MM.yy'"
              color="primary"
              icon="play_arrow"
              title="Ausbildungsbeginn."
            ></recrewt-stage-item>
            <recrewt-stage-item
              [desc]="job?.endDate | date : 'dd.MM.yy'"
              color="primary"
              icon="check_circle"
              title="Ausbildungsende"
            ></recrewt-stage-item>
          </div>
        </ng-container>
        <span class="mat-subheading-1 m-b-1">Dein Ansprechpartner.</span>
        <recrewt-contact-sheet [contact]="job?.contact"></recrewt-contact-sheet>
      </div>
    </div>
  </ng-container>
</recrewt-details-page-base>
