import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { Moment } from 'moment';

@Component({
  selector: 'recrewt-date-editor',
  templateUrl: './date-editor.component.html',
  styleUrls: ['./date-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateEditorComponent,
    },
  ],
})
export class DateEditorComponent extends CustomFormControlComponent implements OnInit {
  @Input() value?: Date;

  @Input() placeholder?: string;

  @Output() update = new EventEmitter<Date>();

  @Output() cancel = new EventEmitter<any>();

  editControl?: UntypedFormControl;

  private dirty = false;

  ngOnInit(): void {
    this.editControl = new UntypedFormControl(this.value, Validators.required);
  }

  onUpdate(): void {
    if (this.dirty) {
      const moment = this.editControl?.value as Moment;
      const date = moment.toDate();
      this.value = date;
      this.update.emit(date);
      this.onChanged(date);
      this.dirty = false;
    }
  }

  onCancel(): void {
    this.editControl?.setValue(this.value);
    this.dirty = false;
  }

  onInputChanged(): void {
    this.dirty = true;
  }

  writeValue(obj: Date): void {
    this.value = obj;
    this.editControl?.setValue(obj);
  }
}
