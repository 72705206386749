import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { ImageGalleryDialogComponent } from '@lib/file-elements/components/image-gallery-dialog/image-gallery-dialog.component';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { GalleryImage } from '@lib/file-elements/components/image-gallery/image-gallery.component';
import { ImageCompressService } from '@lib/file-elements/services/image-compress.service';
import { MatButton } from '@angular/material/button';
import { MutableFileList } from '@shared/util/mutable-filelist';

@Component({
  selector: 'recrewt-image-selection-dialog-button',
  templateUrl: './image-selection-dialog-button.component.html',
  styleUrls: ['./image-selection-dialog-button.component.scss'],
})
export class ImageSelectionDialogButtonComponent {
  @Input() firstChange = false;

  @Input() disabled = false;

  @Input() galleryImages: GalleryImage[] = [];

  @Output() imageSelected = new EventEmitter<any>();

  @Output() delete = new EventEmitter();

  @ViewChild('imgInput', { static: true }) imgInput?: ElementRef;

  @ViewChild('button', { static: true }) button?: MatButton;

  constructor(
    private imageCompress: ImageCompressService,
    public imageSelectOptionDialog: DialogService<ConfirmDialogComponent, boolean | null>,
    public deleteOrSelectDialog: DialogService<ConfirmDialogComponent, boolean | null>,
    public imageGalleryDialog: DialogService<ImageGalleryDialogComponent, string>,
  ) {}

  @Input() displayCategoriesWith: (it: any) => string = (it) => it;

  click(): void {
    this.openDeleteOrSelectDialog();
  }

  private onChangeImageClicked(): void {
    if (!!this.galleryImages?.length) {
      this.openImageSelectionOptionsDialog();
    } else {
      this.openFileBrowser();
    }
  }

  private openDeleteOrSelectDialog(): void {
    if (this.firstChange) {
      this.onChangeImageClicked();
      return;
    }
    const data: ConfirmDialogData = {
      altConfirmText: 'COMMON.delete',
      cancelText: 'COMMON.cancel',
      confirmColor: 'accent',
      confirmText: 'COMMON.edit',
      message: 'FILE_ELEMENTS.SELECT_DIALOG.message',
      title: 'FILE_ELEMENTS.SELECT_DIALOG.title',
    };
    this.deleteOrSelectDialog.open(ConfirmDialogComponent, data);
    this.deleteOrSelectDialog.dialogRef?.beforeClosed().subscribe((it) => {
      if (it == null) {
        return;
      } else if (it) {
        this.onChangeImageClicked();
      } else {
        this.delete.emit();
      }
    });
  }

  private openImageSelectionOptionsDialog(): void {
    const data: ConfirmDialogData = {
      confirmColor: 'accent',
      cancelText: 'COMMON.cancel',
      altConfirmText: 'FILE_ELEMENTS.IMAGE_SELECT.system',
      confirmText: 'FILE_ELEMENTS.IMAGE_SELECT.gallery',
      message: 'FILE_ELEMENTS.IMAGE_SELECT.message',
      title: 'FILE_ELEMENTS.IMAGE_SELECT.title',
    };
    this.imageSelectOptionDialog.open(ConfirmDialogComponent, data);
    this.imageSelectOptionDialog.dialogRef?.beforeClosed().subscribe((it) => {
      if (it == null) {
        return;
      } else if (it) {
        this.openGalleryDialog();
      } else {
        this.openFileBrowser();
      }
    });
  }

  private openFileBrowser() {
    this.imgInput?.nativeElement.click();
  }

  private openGalleryDialog(): void {
    this.imageGalleryDialog.open(
      ImageGalleryDialogComponent,
      {
        images: this.galleryImages,
        displayWith: this.displayCategoriesWith,
      },
      {
        width: '80vw',
        height: '90vh',
      },
    );
    this.imageGalleryDialog.confirmed().subscribe((it) => {
      if (!it) {
        return;
      }
      this.onImageSelected(it);
    });
  }

  private onImageSelected(url: string): void {
    this.imageCompress.fromUrl(url, 'image.jpg', 'image/jpg', (file) => {
      if (!file) {
        return;
      }

      const files = new MutableFileList([file]);
      this.imageSelected.emit({
        target: {
          ...this.imgInput?.nativeElement,
          files,
          accept: this.imgInput?.nativeElement.accept,
        },
      });
    });
  }
}
