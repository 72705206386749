import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { appRouteNames } from '../../../app.routing.names';
import { UserQuery } from '@data/user/user.query';
import { SpaceQuery } from '@data/space/space.query';

@Injectable({
  providedIn: 'root',
})
export class ActiveSpaceGuard implements CanActivate {
  constructor(private router: Router, private userQuery: UserQuery, private spaceQuery: SpaceQuery) {}

  canActivate(): UrlTree | true {
    const key = this.userQuery.getValue().activeSpaceId;
    if (this.spaceQuery.hasActive() && this.spaceQuery.getActiveId() === key) {
      return true;
    }
    if (!!key) {
      return this.router.createUrlTree([appRouteNames.SPACE.replace(':id', key)]);
    } else {
      return true;
    }
  }
}
