import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserDataService } from '@data/user-data/user-data.service';
import { Observable, of } from 'rxjs';
import { UserData } from '@data/user-data/user-data.model';
import { UserDataQuery } from '@data/user-data/user-data.query';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'recrewt-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UserSelectorComponent,
    },
  ],
})
export class UserSelectorComponent extends CustomFormControlComponent implements OnInit {
  @Input() current?: UserData;

  @Output() selectionChanged = new EventEmitter<UserData>();

  collaborators$: Observable<UserData[]> = of([]);

  loading$: Observable<boolean> = of(false);

  constructor(private collaboratorService: UserDataService, private collaboratorQuery: UserDataQuery) {
    super();
  }

  ngOnInit(): void {
    this.collaborators$ = this.collaboratorQuery.selectAll();
    this.loading$ = this.collaboratorQuery.selectLoading();
  }

  onSelectionChanged(collaborator: UserData): void {
    if (this.current?.id !== collaborator.id) {
      this.current = collaborator;
      this.selectionChanged.emit(collaborator);
      this.onChanged(collaborator);
    }
  }

  loadCollaborators(): void {
    this.collaboratorService.getForActive();
  }

  writeValue(collaborator: UserData): void {
    this.current = collaborator;
  }
}
