<div class="inline">
  <recrewt-image-selection-dialog-button
    #imgInput
    (delete)="onDelete()"
    (imageSelected)="onImageSelected($event)"
    [displayCategoriesWith]="displayGalleryCategoriesWith"
    [firstChange]="!imageUrl || imageUrl === DEFAULT_URL"
    [galleryImages]="galleryImages"
    style="display: none"
  ></recrewt-image-selection-dialog-button>
  <div
    (click)="imgInput.click()"
    (keyup)="$event.keyCode === 13 && imgInput.click()"
    class="img-container inline"
    tabindex="0"
  >
    <div>
      <img
        *ngIf="(imageUrl && imageUrl !== DEFAULT_URL) || appearance !== 'banner'; else bannerPlaceholder"
        [class.banner]="appearance === 'banner'"
        [class.circle]="appearance === 'circle'"
        [height]="size"
        [src]="imageUrl | safe: 'resourceUrl'"
        alt="Image upload"
        class="img-input b-rad-1"
      />
    </div>
    <div
      [class.banner]="appearance === 'banner'"
      [class.circle]="appearance === 'circle'"
      [ngStyle]="{ height: size + 'px' }"
      class="after b-rad-1"
      fxLayout="column"
      fxLayoutAlign="center center"
    >
      <mat-icon>{{ icon }}</mat-icon>
      <p class="mat-caption no-pad">{{ hint | translate }}</p>
    </div>
  </div>
</div>

<ng-template #bannerPlaceholder>
  <div [ngStyle]="{ height: size + 'px' }" class="banner-placeholder b-rad-1" fxLayoutAlign="center center">
    <img [ngStyle]="{ height: size / 2 + 'px' }" [src]="DEFAULT_URL" alt="" class="b-rad-1" />
  </div>
</ng-template>
