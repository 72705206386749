import { Injectable } from '@angular/core';
import { NotificationService } from '@data/notification/notification.service';
import { NotificationActions } from '@data/notification/notification.actions';
import { map, switchMap, tap } from 'rxjs/operators';
import { NotificationStore } from '@data/notification/notification.store';
import { createEffect, ofType } from '@ngneat/effects';
import { Notification } from '@data/notification/notification.model';
import { NotificationFactory } from '@data/notification/impl/notification-factory';

@Injectable({ providedIn: 'root' })
export class NotificationEffects {
  loadNotifications = createEffect((actions) =>
    actions.pipe(
      ofType(NotificationActions.load),
      switchMap(() => this.notificationService.get()),
      map((notifications) => notifications.map(NotificationFactory.from)),
      tap((notifications) => this.notificationStore.set(notifications)),
    ),
  );

  markAsRead = createEffect((actions) =>
    actions.pipe(
      ofType(NotificationActions.markAllAsRead),
      switchMap(() => this.notificationService.markAsRead()),
      tap(() => this.notificationStore.update((it: Notification) => !it.read, { read: true })),
    ),
  );

  markOneAsRead = createEffect((actions) =>
    actions.pipe(
      ofType(NotificationActions.markOneAsRead),
      switchMap(({ id }) =>
        this.notificationService.markAsRead(id).pipe(tap(() => this.notificationStore.update(id, { read: true }))),
      ),
    ),
  );

  constructor(private notificationService: NotificationService, private notificationStore: NotificationStore) {}
}
