import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'recrewt-hover-action-bar-wrapper',
  templateUrl: './hover-action-bar.component.html',
  styleUrls: ['./hover-action-bar.component.scss'],
})
export class HoverActionBarComponent {
  @Output() editClicked = new EventEmitter<void>();

  @Output() deleteClicked = new EventEmitter<void>();

  @Output() addClicked = new EventEmitter<void>();

  @Input() align: 'row' | 'column' = 'column';

  showControls = false;
}
