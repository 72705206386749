import { Component, OnInit } from '@angular/core';
import { SpaceQuery } from '@data/space/space.query';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { appRouteNames } from '../../app.routing.names';
import { SpaceService } from '@data/space/space.service';

@Component({
  selector: 'recrewt-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit {
  isBranch$ = of(false);

  constructor(protected spaceQuery: SpaceQuery, private router: Router, private spaceService: SpaceService) {}

  ngOnInit() {
    this.isBranch$ = this.spaceQuery.isBranch$;
  }

  toParentSpace(): void {
    const id = this.spaceQuery.getActive()?.parentRef;
    if (!id) {
      return;
    }
    this.spaceService.deactivate();
    this.router.navigate([appRouteNames.SPACE.replace(':id', id)], { replaceUrl: true }).then();
  }
}
