<recrewt-click-editable (cancel)="onCancel()" (update)="onUpdate()">
  <ng-template viewMode>
    <p *ngIf="value" class="no-pad p-1">{{ value.address }}</p>
    <p *ngIf="!value" class="on-surface-medium no-pad p-1">{{ placeholder ? (placeholder | translate) : '-' }}</p>
  </ng-template>
  <ng-template editMode>
    <recrewt-location-selector
      [formGroup]="form"
      [require]="true"
      [useMatStyle]="false"
      controlName="location"
    ></recrewt-location-selector>
  </ng-template>
</recrewt-click-editable>
