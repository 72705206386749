import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GalleryImage } from '@lib/file-elements/components/image-gallery/image-gallery.component';

@Component({
  selector: 'recrewt-image-gallery-dialog',
  templateUrl: './image-gallery-dialog.component.html',
  styleUrls: ['./image-gallery-dialog.component.scss'],
})
export class ImageGalleryDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ImageGalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { images: GalleryImage[]; displayWith: (it: any) => string },
  ) {}

  noActionClicked(): void {
    this.dialogRef.close();
  }
}
