import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-loadable-image',
  templateUrl: './loadable-image.component.html',
  styleUrls: ['./loadable-image.component.scss'],
})
export class LoadableImageComponent {
  @Input() src?: string;

  @Input() height?: number;

  loading = true;
}
