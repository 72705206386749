import { COLOR_CONFIGS } from '@core/constants/app/status-colors';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';

export enum LookingFor {
  UNKNOWN,
  INTERNSHIP,
  TRAINING,
  BOTH,
}

export const LOOKING_FOR_TAGS = {
  training: {
    ...COLOR_CONFIGS.INFO,
    text: 'COMMON.training',
  },
  internship: {
    ...COLOR_CONFIGS.INFO,
    text: 'COMMON.internship',
  },
  both: {
    ...COLOR_CONFIGS.INFO,
    text: 'COMMON.both',
  },
};

export function getLookingForTag(lookingFor: LookingFor): StatusTagConfig | undefined {
  switch (lookingFor) {
    case LookingFor.BOTH:
    case LookingFor.UNKNOWN:
      return LOOKING_FOR_TAGS.both;
    case LookingFor.TRAINING:
      return LOOKING_FOR_TAGS.training;
    case LookingFor.INTERNSHIP:
      return LOOKING_FOR_TAGS.internship;
    default:
      return undefined;
  }
}
