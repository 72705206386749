import { Component, Input } from '@angular/core';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { FeedbackDialogComponent } from '../../page/feedback-dialog/feedback-dialog.component';
import { Feedback, SupportService } from '@core/services/http/support/support.service';
import { NotificationService } from '@core/services/notification/notification.service';

@Component({
  selector: 'recrewt-feedback-button',
  templateUrl: './feedback-button.component.html',
  styleUrls: ['./feedback-button.component.scss'],
})
export class FeedbackButtonComponent {
  @Input() data?: Partial<Feedback>;

  constructor(
    private feedbackDialog: DialogService<FeedbackDialogComponent, any>,
    private supportService: SupportService,
    private snackBar: NotificationService,
  ) {}

  openFeedbackDialog(): void {
    this.feedbackDialog.open(FeedbackDialogComponent, this.data);
    this.sendFeedbackWhenReady();
  }

  private sendFeedbackWhenReady(): void {
    this.feedbackDialog.confirmed().subscribe((feedbackData: Feedback) => {
      if (feedbackData?.type == null) return;
      this.sendFeedback(feedbackData);
    });
  }

  private sendFeedback(feedbackData: Feedback) {
    this.supportService.sendFeedback(feedbackData).subscribe({
      next: () => this.snackBar.success('SUPPORT.FEEDBACK.success'),
      error: (err) => this.snackBar.error(err.message),
    });
  }
}
