import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Job } from './job.model';

export interface JobState extends EntityState<Job, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'job', resettable: true })
export class JobStore extends EntityStore<JobState, Job, string> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  akitaPreAddEntity(newEntity: Job): Job {
    function assertDate(date: any): Date {
      if (typeof date === 'string') {
        return new Date(Date.parse(date));
      }
      return date;
    }

    newEntity.creationDate = assertDate(newEntity.creationDate);
    newEntity.editDate = assertDate(newEntity.editDate);
    newEntity.endDate = assertDate(newEntity.endDate);
    newEntity.startDate = assertDate(newEntity.startDate);
    newEntity.deadline = assertDate(newEntity.deadline);
    newEntity.publishDate = assertDate(newEntity.publishDate);
    newEntity.publishedDate = assertDate(newEntity.publishedDate);
    return super.akitaPreAddEntity(newEntity);
  }
}
