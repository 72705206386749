<div
  (mouseenter)="showControls = true"
  (mouseleave)="showControls = false"
  [fxLayoutAlign]="align === 'column' ? 'start' : 'space-between center'"
  [fxLayout]="align"
  fxFlex="grow"
>
  <ng-content></ng-content>
  <div [class.hide]="!showControls" class="controls" fxLayoutAlign="end center">
    <button mat-icon-button type="button">
      <mat-icon (click)="addClicked.emit(); $event.stopPropagation()" class="on-surface-medium">add</mat-icon>
    </button>
    <button mat-icon-button type="button">
      <mat-icon (click)="editClicked.emit(); $event.stopPropagation()" class="on-surface-medium">edit</mat-icon>
    </button>
    <button mat-icon-button type="button">
      <mat-icon (click)="deleteClicked.emit(); $event.stopPropagation()" class="on-surface-medium">delete</mat-icon>
    </button>
  </div>
</div>
