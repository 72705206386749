import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserStore } from '@data/user/user.store';
import { Observable } from 'rxjs';
import { UserQuery } from '@data/user/user.query';
import { environment } from '../../../../../environments/environment';
import { resetStores } from '@datorama/akita';
import { IAuthService } from '@core/services/http/auth/interface.auth.service';
import * as AUTH0 from '@auth0/auth0-angular';
import { PersistStateStorage } from '@datorama/akita/src/lib/persistState';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements IAuthService {
  private url = `${environment.apiUrl}/auth`;

  constructor(
    private http: HttpClient,
    private userStore: UserStore,
    private userQuery: UserQuery,
    private auth0: AUTH0.AuthService,
    @Inject('persistStorage') private persistStorage: PersistStateStorage,
  ) {}

  logout(params?: object): void {
    let route = '/auth/login';
    if (params) {
      route += '?';
      for (const [key, value] of Object.entries(params)) {
        route += `${key}=${value}`;
      }
    }
    this.auth0.logout({ returnTo: window.location.origin + route });
    resetStores({ exclude: ['message'] });
  }

  validateToken(token: string): Observable<any> {
    return this.http.get(`${this.url}/token/${token}`);
  }
}
