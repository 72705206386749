import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { Location } from '@data/job/job.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'recrewt-location-on-click-editor',
  templateUrl: './location-on-click-editor.component.html',
  styleUrls: ['./location-on-click-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: LocationOnClickEditorComponent,
    },
  ],
})
export class LocationOnClickEditorComponent extends CustomFormControlComponent implements OnInit {
  @Input() value?: Location;

  @Input() placeholder?: string;

  @Output() update = new EventEmitter<Location>();

  @Output() cancel = new EventEmitter<any>();

  form!: UntypedFormGroup;

  private dirty = false;

  constructor(private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      location: [this.value, []],
    });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.dirty = true;
    });
  }

  onUpdate(): void {
    if (this.dirty) {
      const location = this.form?.value.location as Location;
      this.value = location;
      this.update.emit(location);
      this.onChanged(location);
      this.dirty = false;
    }
  }

  onCancel(): void {
    this.form!.controls.location.setValue(this.value);
    this.dirty = false;
  }

  writeValue(obj: Location): void {
    this.value = obj;
    this.form.controls.location?.setValue(obj);
  }
}
