import { DEFAULT_PRIMARY, DEFAULT_TERTIARY } from '@core/constants/app/theme';

export const COLOR_CONFIGS = {
  OK: {
    color: '#1F6808',
    backgroundColor: '#DAF6C1',
  },
  OK_VARIANT: {
    color: DEFAULT_TERTIARY,
    backgroundColor: '#F9D4E2',
  },
  WARN: {
    color: DEFAULT_PRIMARY,
    backgroundColor: '#FEF1E0',
  },
  ERROR: {
    color: '#B00020',
    backgroundColor: '#EFCCD2',
  },
  INFO: {
    color: '#0055BC',
    backgroundColor: '#CFF5F6',
  },
  GRAY: {
    color: '#545a69',
    backgroundColor: '#ebeef1',
  },
};
