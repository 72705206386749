import { COLOR_CONFIGS } from '@core/constants/app/status-colors';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';

export const CONTACTED_TAGS = {
  contacted: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'check',
    text: 'MATCHING.CONTACT.contacted_title',
    desc: 'MATCHING.CONTACT.contacted',
  },
  not_contacted: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'drafts',
    text: 'MATCHING.CONTACT.contact_title',
    desc: 'MATCHING.CONTACT.contact',
  },
  not_allowed: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'close',
    text: 'MATCHING.CONTACT.disallowed_title',
    desc: 'MATCHING.CONTACT.disallowed',
  },
};

export function getContactedStatusTag(contacted: boolean, contactAllowed: boolean): StatusTagConfig | undefined {
  if (contactAllowed) {
    if (contacted) {
      return CONTACTED_TAGS.contacted;
    }
    return CONTACTED_TAGS.not_contacted;
  }
  return CONTACTED_TAGS.not_allowed;
}
