import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Application } from './application.model';

export interface ApplicationState extends EntityState<Application> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'application', resettable: true, idKey: 'id' })
export class ApplicationStore extends EntityStore<ApplicationState, Application, string> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
