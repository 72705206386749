import { ChatMessage } from '@data/chat/chat.model';
import { createAction, props } from '@ngneat/effects';

export namespace ChatActions {
  export const loadForUser = createAction('[Chat] Load chat for user', props<{ userId: string }>());

  export const add = createAction('[Chat] Add chat message', props<{ message: ChatMessage }>());

  export const send = createAction('[Chat] Send chat message', props<{ message: ChatMessage }>());

  export const sendSuccess = createAction('[Chat] Chat message sent successfully', props<{ message: ChatMessage }>());
}
