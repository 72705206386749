import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { ChatMessage } from './chat.model';

export interface ChatState extends EntityState<ChatMessage> {}

export interface ChatUIState extends EntityState<ChatMessage> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'chat', resettable: true })
export class ChatStore extends EntityStore<ChatState, ChatMessage> {
  ui!: EntityUIStore<ChatUIState, ChatMessage>;

  constructor() {
    super();
    this.createUIStore();
  }
}
