<div class="main height-match-parent">
  <ng-container *ngrxLet="mode$; let mode">
    <ng-container *ngrxLet="hideGettingStarted$; let hideGettingStarted">
      <ng-container *ngrxLet="isBranch$; let isBranch">
        <ng-container *ngrxLet="hasBranches$; let hasBranches">
          <div class="p-l-5 p-r-4">
            <div *ngIf="!showSidenav" class="nav-bar" fxLayoutAlign="space-between center">
              <div fxLayout="row" fxLayoutGap="8px">
                <a
                  *ngIf="!hideGettingStarted"
                  class="link-container hover-effect"
                  routerLink="getting-started"
                  routerLinkActive="activated"
                >
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.getting_started' | translate }}</h4>
                </a>
                <a class="link-container hover-effect" routerLink="dashboard" routerLinkActive="activated">
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.home' | translate }}</h4>
                </a>
                <a class="link-container hover-effect" routerLink="company-profile" routerLinkActive="activated">
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.profile' | translate }}</h4>
                </a>
                <a
                  *ngIf="hasBranches && mode === ContextMode.DEFAULT"
                  class="link-container hover-effect"
                  routerLink="branches"
                  routerLinkActive="activated"
                >
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.branches' | translate }}</h4>
                </a>
                <a
                  *ngIf="mode === ContextMode.DEFAULT"
                  class="link-container hover-effect"
                  routerLink="budget"
                  routerLinkActive="activated"
                >
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.budget' | translate }}</h4>
                </a>
                <a
                  *ngIf="mode === ContextMode.DEFAULT && !hasBranches"
                  class="link-container hover-effect"
                  routerLink="job-administration"
                  routerLinkActive="activated"
                >
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.job_admin' | translate }}</h4>
                </a>
                <a
                  *ngIf="mode === ContextMode.FAIR"
                  class="link-container hover-effect"
                  routerLink="job-fairs"
                  routerLinkActive="activated"
                >
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.fairs' | translate }}</h4>
                </a>
                <a
                  *ngIf="!hasBranches || (hasBranches && mode === ContextMode.FAIR)"
                  class="link-container hover-effect"
                  disabled
                  routerLink="matches"
                  routerLinkActive="activated"
                >
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.matches' | translate }}</h4>
                </a>
                <a
                  *ngIf="!hasBranches || (hasBranches && mode === ContextMode.FAIR)"
                  class="link-container hover-effect"
                  routerLink="applications"
                  routerLinkActive="activated"
                >
                  <h4 class="no-pad">{{ 'SIDENAV.' + (mode ?? '0') + '.applications' | translate }}</h4>
                </a>
              </div>
              <div *ngIf="!isBranch" class="text-switch-container">
                <recrewt-mode-switch></recrewt-mode-switch>
              </div>
            </div>
          </div>
          <div *ngIf="!showSidenav" class="nav-content height-match-parent">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
          </div>

          <recrewt-rail-sidenav
            *ngIf="showSidenav"
            class="height-match-parent"
            closedWidth="16"
            expandDelay="1000"
            expandedWidth="280"
            preventOpenOnClick="true"
          >
            <div class="height-match-parent width-match-parent" sidenav style="overflow: hidden">
              <mat-nav-list disableRipple>
                <a *ngIf="!hideGettingStarted" mat-list-item routerLink="getting-started" routerLinkActive="active">
                  <mat-icon mat-list-icon>bolt</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.getting_started' | translate }}</h4>
                </a>
                <a mat-list-item routerLink="dashboard" routerLinkActive="active">
                  <mat-icon mat-list-icon>dashboard</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.home' | translate }}</h4>
                </a>
                <a mat-list-item routerLink="company-profile" routerLinkActive="active">
                  <mat-icon mat-list-icon>business</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.profile' | translate }}</h4>
                </a>
                <a
                  *ngIf="hasBranches && mode === ContextMode.DEFAULT"
                  mat-list-item
                  routerLink="branches"
                  routerLinkActive="active"
                >
                  <mat-icon mat-list-icon>account_tree</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.branches' | translate }}</h4>
                </a>
                <a *ngIf="mode === ContextMode.DEFAULT" mat-list-item routerLink="budget" routerLinkActive="active">
                  <mat-icon mat-list-icon>payments</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.budget' | translate }}</h4>
                </a>
                <a
                  *ngIf="mode === ContextMode.DEFAULT && !hasBranches"
                  mat-list-item
                  routerLink="job-administration"
                  routerLinkActive="active"
                >
                  <mat-icon mat-list-icon>contact_page</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.job_admin' | translate }}</h4>
                </a>
                <a *ngIf="mode === ContextMode.FAIR" mat-list-item routerLink="job-fairs" routerLinkActive="active">
                  <mat-icon mat-list-icon>storefront</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.fairs' | translate }}</h4>
                </a>
                <a
                  *ngIf="!hasBranches || (hasBranches && mode === ContextMode.FAIR)"
                  mat-list-item
                  routerLink="matches"
                  routerLinkActive="active"
                >
                  <mat-icon mat-list-icon>favorite</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.matches' | translate }}</h4>
                </a>
                <a
                  *ngIf="!hasBranches || (hasBranches && mode === ContextMode.FAIR)"
                  mat-list-item
                  routerLink="applications"
                  routerLinkActive="active"
                >
                  <mat-icon mat-list-icon>badge</mat-icon>
                  <h4 mat-line>{{ 'SIDENAV.' + (mode ?? '0') + '.applications' | translate }}</h4>
                </a>
              </mat-nav-list>
            </div>
            <ng-container sidenav-content>
              <div *ngIf="!isBranch" class="text-switch-container p-r-4 p-t-1" fxLayoutAlign="end">
                <recrewt-mode-switch></recrewt-mode-switch>
              </div>
              <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
            </ng-container>
          </recrewt-rail-sidenav>
          <ng-template #contentTpl><ng-content></ng-content></ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
