<button
  *ngIf="show"
  @bubbleGrow
  [disabled]="disabled"
  class="button extended-fab-button"
  fxLayoutAlign="center center"
  fxLayoutGap="10px"
>
  <mat-icon *ngIf="iconPosition === 'left'" class="no-pad">{{ icon }}</mat-icon>
  <span class="extended-fab-button__text">{{ text | translate }}</span>
  <mat-icon *ngIf="iconPosition === 'right'" class="no-pad">{{ icon }}</mat-icon>
</button>
