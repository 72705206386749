import { Component, Inject, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Job } from '@data/job/job.model';
import { BENEFITS_SELECTOR_CONFIG } from '@core/constants/job/benefits';
import { MobilePreviewComponent } from '../mobile-preview/mobile-preview.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { BenefitsMapperService } from '../../services/benefits-mapper/benefits-mapper.service';
import { CompanySite } from '@data/company/company.model';

type BenefitData = { icon?: string; title?: string; desc?: string };

export interface JobPreviewData {
  job: Job;
  jobSite?: CompanySite;
  companyName: string;
  companyTheme: any;
  logo$?: Observable<string>;
  banner$?: Observable<string>;
}

@Component({
  selector: 'recrewt-job-preview',
  templateUrl: './job-mobile-preview.component.html',
  styleUrls: ['./job-mobile-preview.component.scss', '../../mobile-theme.scss'],
})
export class JobMobilePreviewComponent extends MobilePreviewComponent implements OnInit, OnChanges {
  @Input() jobData?: JobPreviewData;

  job?: Job;

  companyName = '';

  logoSrc$ = of('');

  bannerSrc$ = of('');

  highlightBenefits: BenefitData[] = [];

  private readonly DEFAULT_LOGO = 'assets/images/RC_Logo_Icon.svg';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: JobPreviewData,
    private dialogRef: MatDialogRef<JobMobilePreviewComponent>,
    private benefitsMapper: BenefitsMapperService,
  ) {
    super();
    this.updateData(this.data);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  back(): void {
    if (this.dialogRef?.componentInstance instanceof JobMobilePreviewComponent) {
      this.dialogRef?.close();
    }
  }

  decodeHighlightBenefits(): void {
    if (!this.job?.highlight_benefits?.length) {
      return;
    }
    this.highlightBenefits.splice(0, this.highlightBenefits.length);
    const data = this.benefitsMapper.decodeBenefits(this.job.highlight_benefits);
    for (const d of data) {
      const hb = this.mapHighlightBenefit(d);
      this.highlightBenefits.push(hb);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const jobData = changes.jobData.currentValue;
    if (!jobData) {
      return;
    }
    this.updateData(jobData);
  }

  private mapHighlightBenefit(d: any): BenefitData {
    const benefitsGroup = BENEFITS_SELECTOR_CONFIG[d.listIdx];
    const customBenefits = this.job?.matchData?.additionalData?.benefits?.[benefitsGroup?.groupName] ?? [];

    function getTitle(): string | undefined {
      if (d.fromAuto) {
        return benefitsGroup?.data[d.index];
      } else {
        return customBenefits[d.index];
      }
    }

    return {
      icon: benefitsGroup?.icon,
      desc: benefitsGroup?.label,
      title: getTitle(),
    };
  }

  private updateData(data?: JobPreviewData): void {
    this.job = data?.job;
    this.bannerSrc$ = data?.banner$ ?? of('');
    this.logoSrc$ = data?.logo$?.pipe(map((it) => (!!it?.length ? it : this.DEFAULT_LOGO))) ?? of(this.DEFAULT_LOGO);
    this.theme = data?.companyTheme;
    this.companyName = data?.companyName ?? '';
    this.decodeHighlightBenefits();
  }
}
