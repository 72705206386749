import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { SessionState, SessionStore } from '@data/session/session.store';
import { Session, SessionStatus } from '@data/session/session.model';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends QueryEntity<SessionState, Session> {
  openSessions$ = this.selectAll({
    filterBy: (session) => session.status !== SessionStatus.CLOSED,
  });

  constructor(protected store: SessionStore) {
    super(store);
  }
}
