import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface SegmentData {
  segment: string;
  ref: string;
}

@Component({
  selector: 'recrewt-breadcrumbs',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  @Input() segments: SegmentData[] = [];

  @Input() routerLink?: string;

  @Output() segmentClick = new EventEmitter<string>();

  @Output() homeClick = new EventEmitter();

  onSegmentClicked(ref: string): void {
    this.segmentClick.emit(ref);
  }
}
