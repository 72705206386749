import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NotificationService } from '@core/services/notification/notification.service';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { ImageCropComponent } from '../image-cropper/image-crop.component';
import { ImageCompressService } from '@lib/file-elements/services/image-compress.service';
import { GalleryImage } from '@lib/file-elements/components/image-gallery/image-gallery.component';

@Component({
  selector: 'recrewt-image-selector',
  templateUrl: './image-selector.component.html',
  styleUrls: ['./image-selector.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class ImageSelectorComponent implements OnInit, OnChanges {
  @Input() appearance: 'square' | 'circle' | 'banner' = 'circle';

  DEFAULT_URL = 'assets/images/logo.png';

  @Input() size = 100;

  @Input() icon = 'add_a_photo';

  @Input() hint = '';

  @Input() imageUrl: string | null = this.DEFAULT_URL;

  @Input() galleryImages?: GalleryImage[];

  @Input() displayGalleryCategoriesWith?: (category: number) => string;

  @Output() fileSelected = new EventEmitter<File>();

  @Output() fileDeleted = new EventEmitter<void>();

  constructor(
    private notify: NotificationService,
    private imageCompress: ImageCompressService,
    private imageCropper: DialogService<ImageCropComponent, { file: File; compress: boolean } | number | null>,
  ) {}

  ngOnInit() {
    this.DEFAULT_URL = this.appearance === 'banner' ? 'assets/images/banner.png' : 'assets/images/logo.png';
  }

  onImageSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputFile = input?.files?.item(0);
    if (!inputFile) {
      return;
    }
    if (!input?.accept?.includes(inputFile.type)) {
      this.notify.error('SNACKBAR.IMAGE.type');
      return;
    }

    this.openCropper(event, true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.DEFAULT_URL = this.appearance === 'banner' ? 'assets/images/banner.png' : 'assets/images/logo.png';
    const imgUrl = changes.imageUrl?.currentValue;
    this.imageUrl = imgUrl?.length ? imgUrl : this.DEFAULT_URL;
  }

  onDelete(): void {
    this.imageUrl = this.DEFAULT_URL;
    this.fileDeleted.emit();
  }

  private getCompression(byteSize: number): [number, number] | undefined {
    const kb = byteSize / 1000;
    if (this.appearance === 'circle') {
      if (kb > 10000) {
        return [20, 20];
      } else if (kb > 5000) {
        return [25, 25];
      } else if (kb > 3000) {
        return [30, 30];
      } else {
        return [35, 35];
      }
    }
  }

  // eslint-disable-next-line
  private openCropper(event: any, compress: boolean) {
    this.imageCropper.open(ImageCropComponent, {
      fileChangedEvent: event,
      aspectRatio: this.appearance === 'banner' ? 2.47 : 1,
      resizeToWidth: true,
      maintainAspectRatio: true,
      roundCropper: this.appearance === 'circle',
      galleryImages: this.galleryImages,
      displayGalleryCategoriesWith: this.displayGalleryCategoriesWith,
    });
    this.imageCropper.confirmed().subscribe((it) => {
      if (!it) {
        return;
      } else if (typeof it === 'number') {
        this.onDelete();
      } else {
        if (compress || it.compress) {
          this.compressFile(it.file);
        } else {
          this.fileSelected.emit(it.file);
        }
      }
    });
  }

  private compressFile(image: File): void {
    this.imageCompress.compressFile(
      image,
      ({ file, url }) => {
        this.imageUrl = url;
        this.fileSelected.emit(file);
      },
      this.getCompression(image.size),
    );
  }
}
