import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'recrewt-collaborator-actions',
  templateUrl: './collaborator-actions.component.html',
  styleUrls: ['./collaborator-actions.component.scss'],
})
export class CollaboratorActionsComponent {
  @Input() disableResend = false;

  @Input() disableDelete = false;

  @Output() deleteClicked = new EventEmitter();

  @Output() resendClicked = new EventEmitter();

  onResendClicked(): void {
    this.resendClicked.emit();
    this.disableResend = true;
  }
}
