import { Component, Input } from '@angular/core';
import { ColoredOption } from '../data/colored-option';

@Component({
  selector: 'recrewt-colored-select-option',
  templateUrl: './colored-select-option.component.html',
  styleUrls: ['./colored-select-option.component.scss'],
})
export class ColoredSelectOptionComponent {
  @Input() value?: ColoredOption;
}
