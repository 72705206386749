import { ChangeDetectorRef, Component, NgZone, OnInit, ViewContainerRef } from '@angular/core';
import { PopupComponent } from '@shared/components/popup/base/popup.component';
import { Observable, of } from 'rxjs';
import { Actions } from '@ngneat/effects-ng';
import { NotificationQuery } from '@data/notification/notification.query';
import { NotificationActions } from '@data/notification/notification.actions';
import { Notification } from '@data/notification/notification.model';

@Component({
  selector: 'recrewt-notification-button',
  templateUrl: './notification-button.component.html',
  styleUrls: ['./notification-button.component.scss'],
})
export class NotificationButtonComponent extends PopupComponent implements OnInit {
  notifications$: Observable<Notification[]> = of([]);

  loading$: Observable<boolean> = of(true);

  unreadAmount$: Observable<number> = of(0);

  constructor(
    private actions: Actions,
    private notificationsQuery: NotificationQuery,
    vcr: ViewContainerRef,
    zone: NgZone,
    cdr: ChangeDetectorRef,
  ) {
    super(vcr, zone, cdr);
  }

  ngOnInit(): void {
    this.actions.dispatch(NotificationActions.load());
    this.loading$ = this.notificationsQuery.selectLoading();
    this.notifications$ = this.notificationsQuery.selectAll();
    this.unreadAmount$ = this.notificationsQuery.countUnread$;
  }

  markAllAsRead(): void {
    this.actions.dispatch(NotificationActions.markAllAsRead());
  }

  markOneAsRead(id: string): void {
    this.actions.dispatch(NotificationActions.markOneAsRead({ id }));
  }
}
