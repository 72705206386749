import { Component, Input, OnInit } from '@angular/core';
import { APPLICATION_INTERVIEW } from '@core/constants/job/application_interview';
import { AbstractControl, FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DialogService } from '@shared/components/dialog/dialog.service';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { ApplicationService } from '@data/application/application.service';
import { Subject } from 'rxjs';
import moment from 'moment/moment';
import { Moment } from 'moment';

@Component({
  selector: 'recrewt-application-handling-selector',
  templateUrl: './application-handling-selector.component.html',
  styleUrls: ['./application-handling-selector.component.scss'],
})
export class ApplicationHandlingSelectorComponent implements OnInit {
  readonly APPLICATION_INTERVIEW = APPLICATION_INTERVIEW;

  @Input() applicationForm?: UntypedFormGroup;

  useAppForInternshipApplication = true;

  useAppForTrainingApplication = true;

  clearTraining = new Subject<void>();

  clearIntern = new Subject<void>();

  constructor(
    private applicationService: ApplicationService,
    private dialog: DialogService<ConfirmDialogComponent, any>,
    private formBuilder: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    const value = this.applicationForm?.value;
    const internshipSite = value?.applicationFlowOverride?.internshipSite;
    const careerSite = value?.applicationFlowOverride?.careerSite;
    this.useAppForInternshipApplication = !internshipSite;
    this.useAppForTrainingApplication = !careerSite;

    const internshipSlots = value?.internshipSlots ?? [];
    for (let i = 0; i < internshipSlots.length; i++) {
      this.applicationForm?.addValidators([this.startDateValidator(i), this.endDateValidator(i)]);
    }

    this.toggleInterviewEnableState(value?.enableInterview);
  }

  toggleInterviewEnableState(checked: boolean): void {
    const interviewQuestionInputs = this.applicationForm?.controls.application_interview as UntypedFormArray;
    interviewQuestionInputs?.controls.forEach((it) => (checked ? it.enable() : it.disable()));
  }

  clearInternshipSite(use: boolean): void {
    this.useAppForInternshipApplication = use;
    this.applicationForm?.controls.applicationFlowOverride.get('internshipSite')?.setValue(null);
  }

  clearCareerSite(use: boolean): void {
    this.useAppForTrainingApplication = use;
    this.applicationForm?.controls.applicationFlowOverride.get('careerSite')?.setValue(null);
  }

  addInternshipSlot() {
    const slots = this.applicationForm?.controls.internshipSlots as FormArray;
    slots.push(this.formBuilder.group({ start: [null], end: [null] }));
    this.applicationForm?.addValidators([
      this.startDateValidator(slots.length - 1),
      this.endDateValidator(slots.length - 1),
    ]);
  }

  removeInternshipSlot(i: number) {
    const slots = this.applicationForm?.controls.internshipSlots as FormArray;
    slots?.removeAt(i);
    this.applicationForm?.setErrors(null);
  }

  openSendDialog(email: string, clear: Subject<void>) {
    const data: ConfirmDialogData = {
      cancelText: 'COMMON.cancel',
      message: 'JOB_CREATOR.APPLICATION.TEST.text',
      title: 'JOB_CREATOR.APPLICATION.TEST.send',
      warning: email,
      confirmText: 'JOB_CREATOR.APPLICATION.TEST.send',
      confirmColor: 'primary',
    };

    this.dialog.open(ConfirmDialogComponent, data);
    this.dialog.confirmed().subscribe((confirmed) => {
      if (confirmed) this.sendTestApplication(email);
      else clear.next();
    });
  }

  sendTestApplication(email: string) {
    this.applicationService.sendTestApplication(email).subscribe();
  }

  startDateValidator(slotIndex: number) {
    return (form: AbstractControl) => {
      const now = moment().subtract(1, 'day');
      const slots = form?.get('internshipSlots') as FormArray;
      const start = slots?.at(slotIndex)?.get('start');
      const end = slots.at(slotIndex)?.get('end');
      if (!start || !end) {
        return null;
      }
      const startDate: Moment | undefined = start?.value;
      const endDate: Moment | undefined = end?.value;

      const afterToday = !!startDate && startDate.isAfter(now);
      const notSetButEndDaySet = !startDate && !!endDate;

      const error = afterToday && !notSetButEndDaySet ? null : { invalidStart: true };
      start?.setErrors(error);
      return error;
    };
  }

  endDateValidator(slotIndex: number) {
    return (form: AbstractControl) => {
      const slots = form?.get('internshipSlots') as FormArray;
      const start = slots?.at(slotIndex)?.get('start');
      const end = slots?.at(slotIndex)?.get('end');
      if (!start || !end) {
        return null;
      }
      const startDate: Moment | undefined = start?.value;
      const endDate: Moment | undefined = end?.value;

      const notSetButStartDaySet = !!startDate && !endDate;
      const startDateBeforeEndDate = !!endDate && endDate.isAfter(startDate);
      const error = startDateBeforeEndDate && !notSetButStartDaySet ? null : { invalidEndDate: true };
      end?.setErrors(error);
      return error;
    };
  }
}
