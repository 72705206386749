<div
  [fxLayoutAlign]="center ? 'start center' : 'start baseline'"
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutAlign.lt-md="start start"
>
  <div [ngStyle]="{ width: labelWidth }">
    <p class="on-surface-medium no-pad">
      {{ headline | translate }}
      <recrewt-what-is-this *ngIf="desc" [desc]="desc"></recrewt-what-is-this>
    </p>
  </div>
  <div class="width-match-parent">
    <ng-content></ng-content>
  </div>
</div>
