<div style="display: block">
  <canvas
    [data]="pieChartData"
    [legend]="pieChartLegend"
    [options]="pieChartOptions"
    [plugins]="pieChartPlugins"
    [type]="pieChartType"
    baseChart
    height="300px"
  >
  </canvas>
</div>
