import { Query } from '@datorama/akita';
import { UserState, UserStore } from './user.store';
import { Injectable } from '@angular/core';
import { filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }

  name$ = this.select((user) => user?.userData?.name);

  email$ = this.select((user) => user?.userData?.email);

  activeSpaceId$ = this.select((user) => user?.activeSpaceId);

  userData$ = this.select((user) => user?.userData).pipe(
    filter((u) => !!u),
    take(1),
  );

  assertedActiveSpaceId$ = this.select((user) => user?.activeSpaceId).pipe(
    filter((u) => !!u),
    take(1),
  );

  userData = () => this.getValue().userData;

  id = () => this.getValue()._id;

  email = () => this.getValue().email;
}
