import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroupDirective, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { debounceTime, map } from 'rxjs/operators';
import { first, Observable, of } from 'rxjs';
import { GeocoderService } from '@core/services/geocoder/geocoder.service';
import { Location } from '@data/job/job.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'recrewt-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorComponent implements OnInit {
  form!: UntypedFormGroup;

  @Input() controlName = '';

  @Input() predefinedOnly = false;

  @Input() predefined: Location[] = [];

  @Input() error = 'JOB_CREATOR.GENERAL.ERRORS.select_from_list';

  @Input() placeholder = 'LOCATION_SELECT.placeholder';

  @Input() require = false;

  @Input() useMatStyle = true;

  searchControl = new FormControl();

  results$: Observable<Location[]> = of([]);

  initValue?: any;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private locationService: GeocoderService,
    private cdr: ChangeDetectorRef,
  ) {}

  displayValue = (value: Location) => value?.address ?? '';

  street = (value: Location) => value?.address?.split(',')[0]?.trim() ?? '';

  city = (value: Location) => value?.address?.split(',')[1]?.trim() ?? '';

  ngOnInit(): void {
    this.results$ = of(this.predefined);
    this.form = this.rootFormGroup.control as UntypedFormGroup;

    if (!this.predefined.length) {
      this.initFromValue();
    }
    this.observeValueChange();
    this.observeSearchValueChange();
  }

  setFormFieldValue(value: Location) {
    this.form.get(this.controlName)?.setValue(value);
    this.cdr.detectChanges();
  }

  private observeValueChange() {
    this.form
      .get(this.controlName)
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.results$ = of([value]);
        this.results$.pipe(first()).subscribe((res) => {
          this.initValue = res?.[0];
          this.cdr.detectChanges();
        });
      });
  }

  private initFromValue() {
    const initValue = this.form.get(this.controlName)?.value;
    if (typeof initValue === 'string') {
      this.results$ = this.locationService.searchAddress(initValue.trim()?.toLowerCase());
      this.results$.pipe(first()).subscribe((res) => {
        this.initValue = res?.[0];
        this.setFormFieldValue(res?.[0]);
      });
    } else if (typeof initValue === 'object') {
      this.initValue = initValue;
      this.setFormFieldValue(initValue);
    }
  }

  private observeSearchValueChange() {
    this.searchControl?.valueChanges
      .pipe(debounceTime(500), untilDestroyed(this))
      .subscribe((term: string | Location) => {
        if (!(typeof term === 'string')) return;
        if (!(term?.trim()?.length >= 3) || this.predefinedOnly) {
          this.results$ = of(this.predefined);
          return;
        }
        this.results$ = this.locationService.searchAddress(term?.trim()?.toLowerCase()).pipe(
          map((value) => {
            return this.mergePredefinedAndSearchResults(value, term);
          }),
        );
        this.cdr.detectChanges();
      });
  }

  private mergePredefinedAndSearchResults(results: Location[], term: string): Location[] {
    return results.concat(this.predefined.filter((p) => p.address?.toLowerCase().includes(term.toLowerCase())));
  }
}

export function fromListValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const forbidden = typeof control.value === 'string';
    return forbidden ? { forbiddenValue: { value: control.value } } : null;
  };
}
