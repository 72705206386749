import { Pipe, PipeTransform } from '@angular/core';
import { CardinalMapping, MapperUtil } from '@shared/util/mapper.util';

@Pipe({
  name: 'decToStr',
})
export class DecToStrPipe implements PipeTransform {
  transform(value: number, mapping: CardinalMapping): string {
    const decToStr = MapperUtil.decToStr(value, mapping);
    return decToStr ?? '-';
  }
}
