<ng-container *ngIf="(loading$ | async) === false">
  <ng-container *ngrxLet="message$; let msg">
    <div
      *ngIf="!msg.hidden"
      class="b-rad-1 primary-light p-h-3 m-v-4 p-v-1"
      fxLayout.lt-md="column"
      fxLayoutAlign="space-between center"
      fxLayoutGap.lt-md="12px"
    >
      <mat-icon color="primary">info</mat-icon>
      <p class="no-pad p-h-2 text-align-center">{{ msg.text }}</p>
      <div fxLayout="row" fxLayoutAlign="end center">
        <a *ngIf="msg.ref" [href]="msg.ref" mat-button>{{ 'INFO_BOX.show' | translate }}</a>
        <a (click)="hide()" mat-button>{{ 'INFO_BOX.hide' | translate }}</a>
      </div>
    </div>
  </ng-container>
</ng-container>
