import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { NotificationButtonComponent } from '@lib/notification-elements/component/notification-button/notification-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';
import { NotificationListComponent } from './component/notification-list/notification-list.component';
import { NotificationListItemComponent } from './component/notification-list-item/notification-list-item.component';
import { FlexModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [NotificationButtonComponent, NotificationListComponent, NotificationListItemComponent],
  imports: [SharedModule, MatButtonModule, MatIconModule, MatBadgeModule, FlexModule, MatProgressSpinnerModule],
  exports: [NotificationButtonComponent],
})
export class NotificationElementsModule {}
