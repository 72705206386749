import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ControlContainer, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { Job } from '@data/job/job.model';
import { BENEFITS, BENEFITS_SELECTOR_CONFIG } from '@core/constants/job/benefits';
import { CategoricalSelectorConfig } from '@lib/job-elements/model/categorical-selector.config';
import { ChipSelectionData } from '@lib/job-elements/model/chip-selection-data';
import { JobDataMapperService } from '@lib/job-elements/services/job-data-mapper.service';

@Component({
  selector: 'recrewt-benefits-job-data-page',
  templateUrl: './benefits-job-data-page.component.html',
  styleUrls: ['./benefits-job-data-page.component.scss'],
  providers: [NgForm],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class BenefitsJobDataPageComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() template: Partial<Job> = {};

  @Input() salaryFormGroup = new UntypedFormGroup({});

  highlightedBenefits: ChipSelectionData[] = [];

  salaryGroup!: UntypedFormGroup;

  benefitsGroup!: UntypedFormGroup;

  frmBenefits!: UntypedFormGroup;

  datasets: CategoricalSelectorConfig[] = BENEFITS_SELECTOR_CONFIG;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private ctrlContainer: FormGroupDirective,
    private jdm: JobDataMapperService,
    private cdr: ChangeDetectorRef,
  ) {}

  get salaryControlNames(): string[] {
    return Object.keys(this.salaryGroup?.controls ?? {}) ?? [];
  }

  ngOnInit(): void {
    const tplBenefits = this.template?.matchData?.general?.benefits;
    const tplBenefitsCustom = this.template?.matchData?.additionalData?.benefits;
    const tplBenefitsHighlight = this.template?.highlight_benefits ?? [];
    const tplVacation = this.template?.vacation;
    const parent = this.ctrlContainer.form;

    this.benefitsGroup = this.jdm.initBenefitsFormGroup(tplBenefits, tplBenefitsCustom);
    this.frmBenefits = this.formBuilder.group({
      salary: this.salaryGroup,
      salaryUnspecified: [!!this.template?.salaryUnspecified],
      benefits: this.benefitsGroup,
      vacation: [tplVacation ?? 30, [Validators.required]],
      highlight_benefits: [tplBenefitsHighlight],
    });
    parent.addControl('benefits', this.frmBenefits);
    this.onAtInterviewToggled(!!this.template?.salaryUnspecified);
  }

  ngAfterViewInit(): void {
    this.updateHighlightBenefitsViewData();
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.salaryFormGroup?.currentValue) {
      return;
    }
    this.salaryGroup = changes?.salaryFormGroup?.currentValue;
    this.frmBenefits?.setControl('salary', this.salaryGroup);
    this.onAtInterviewToggled(!!this.frmBenefits?.get('salaryUnspecified')?.value);
  }

  updateHighlightBenefits(favs: ChipSelectionData[]): void {
    const benefits = this.benefitsGroup.value;
    const amountPredefined: number[] = [];
    Object.values(benefits).forEach((val: any) => amountPredefined.push(val.predefined?.length ?? 0));
    const encoded = this.jdm.encodeHighlightBenefits(favs, BENEFITS, amountPredefined);
    this.frmBenefits.get('highlight_benefits')?.patchValue(encoded);
  }

  private updateHighlightBenefitsViewData(): void {
    const tplBenefitsHighlight = this.template?.highlight_benefits;
    const benefits = this.benefitsGroup.value;
    const selection: string[][] = [];
    const amountPredefined: number[] = [];
    Object.values(benefits).forEach((val: any) => selection.push([...(val.predefined ?? []), ...(val.custom ?? [])]));
    Object.values(benefits).forEach((val: any) => amountPredefined.push(val.predefined?.length ?? 0));
    this.highlightedBenefits = this.jdm.decodeHighlightBenefits(
      tplBenefitsHighlight ?? [],
      BENEFITS,
      selection,
      amountPredefined,
    );
  }

  onAtInterviewToggled(checked: boolean): void {
    if (checked) {
      this.salaryFormGroup.disable();
      for (const salaryControl of Object.values(this.salaryFormGroup.controls)) {
        salaryControl.setValue(0);
      }
    } else {
      this.salaryFormGroup.enable();
    }
  }
}
