import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  template: '',
})
export abstract class CustomFormControlComponent implements ControlValueAccessor {
  touched = false;

  @Input() disabled = false;

  onChange?: (val: any) => void;

  onTouched = () => {};

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChanged(obj: any): void {
    this.markAsTouched();
    if (this.onChange) {
      this.onChange(obj);
    }
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  abstract writeValue(obj: any): void;
}
