import { NgModule } from '@angular/core';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { ContentLayoutComponent } from './content-layout/content-layout.component';
import { InfoCardLayoutComponent } from './info-card-layout/info-card-layout.component';
import { SharedModule } from '@shared/shared.module';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { ExtendedModule, FlexModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ToolbarLayoutComponent } from './toolbar-layout/toolbar-layout.component';
import { MatMenuModule } from '@angular/material/menu';
import { DrawerRailModule } from 'angular-material-rail-drawer';
import { MatBadgeModule } from '@angular/material/badge';
import { PrimaryBgLayoutComponent } from './primary-bg-layout/primary-bg-layout.component';
import { FooterComponent } from './footer/footer.component';
import { NotificationElementsModule } from '@lib/notification-elements/notification-elements.module';
import { SupportElementsModule } from '@lib/support-elements/support-elements.module';
import { LetModule } from '@ngrx/component';
import { SpaceElementsModule } from '@lib/space-elements/space-elements.module';

@NgModule({
  declarations: [
    AuthLayoutComponent,
    ContentLayoutComponent,
    InfoCardLayoutComponent,
    SidenavComponent,
    ToolbarLayoutComponent,
    PrimaryBgLayoutComponent,
    FooterComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,

    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatToolbarModule,
    FlexModule,
    MatMenuModule,
    DrawerRailModule,
    MatBadgeModule,
    SupportElementsModule,
    NotificationElementsModule,
    SpaceElementsModule,
    ExtendedModule,
    LetModule,
  ],
  exports: [InfoCardLayoutComponent],
})
export class LayoutModule {}
