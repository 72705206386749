import { Injectable } from '@angular/core';
import { QueryEntity, SelectAllOptionsB } from '@datorama/akita';
import { Application } from '@data/application/application.model';
import { ApplicationState, ApplicationStore } from '@data/application/application.store';
import { Observable } from 'rxjs';
import { ContextMode } from '@core/enums/app/context-mode';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ApplicationQuery extends QueryEntity<ApplicationState, Application> {
  constructor(protected store: ApplicationStore) {
    super(store);
  }

  selectAllForMode(mode$: Observable<ContextMode>, options?: Partial<SelectAllOptionsB<Application>>) {
    return mode$.pipe(
      switchMap((mode) =>
        this.selectAll({
          ...options,
          filterBy: (s) => s.mode === mode && (!options?.filterBy || (options.filterBy as any)(s)),
        }),
      ),
    );
  }
}
