import { Pipe, PipeTransform } from '@angular/core';
import { Match } from '@data/match/match.model';

@Pipe({
  name: 'matchUserName',
})
export class MatchUserNamePipe implements PipeTransform {
  transform(match: Match): string {
    return match.isWebMatch ? match.user.name : match?.user?.name?.split(' ')[0];
  }
}
