import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template[cell]',
})
export class TableCellDirective {
  @Input() cell?: string;

  @Input() columnTitle?: string;

  @Input() hideLtMd?: boolean;

  @Input() hideLtSm?: boolean;

  constructor(public templateRef: TemplateRef<any>) {}
}
