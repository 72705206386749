import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'recrewt-rail-sidenav',
  templateUrl: './rail-sidenav.component.html',
  styleUrls: ['./rail-sidenav.component.scss'],
})
export class RailSidenavComponent implements OnInit {
  @Input() primary = false;

  @Input() badge: string | number | undefined | null;

  @Input() opened = false;

  @Input() hideOnClose = false;

  @Input() preventOpenOnClick = false;

  @Input() expandedWidth = 350;

  @Input() closedWidth = 64;

  @Input() expandDelay = 500;

  @Input() position: 'start' | 'end' = 'start';

  @Output() openClicked = new EventEmitter<boolean>();

  private openedByButton = false;

  private sideNavClick = false;

  ngOnInit(): void {
    this.openedByButton = this.opened;
  }

  expand(): void {
    if (!this.opened && !this.sideNavClick) {
      this.opened = true;
    }
  }

  collapse(): void {
    if (this.opened && !this.openedByButton) {
      this.opened = false;
    }
    this.sideNavClick = false;
  }

  expandButtonClicked(): void {
    const tmpSideNavClick = this.sideNavClick;
    this.sideNavClick = false;
    if (this.opened) {
      this.openedByButton = false;
      this.collapse();
    } else {
      this.openedByButton = true;
      this.expand();
    }
    this.openClicked.emit(this.opened);
    this.sideNavClick = tmpSideNavClick;
  }

  onSideNavClicked(): void {
    if (!this.preventOpenOnClick) {
      return;
    }
    this.sideNavClick = true;
  }
}
