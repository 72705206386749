import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UserData } from './user-data.model';

export interface UserDataState extends EntityState<UserData> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-data', resettable: true })
export class UserDataStore extends EntityStore<UserDataState, UserData> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
