import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '@data/notification/notification.model';

@Component({
  selector: 'recrewt-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent {
  @Input() notifications: Notification[] = [];

  @Input() loading = false;

  @Output() markAllAsRead = new EventEmitter();

  @Output() markOneAsRead = new EventEmitter<string>();
}
