<div class="btn-container" fxLayoutAlign="start center">
  <label class="switch btn-color-mode-switch">
    <input (change)="onChange()" [checked]="isChecked" id="color_mode" name="color_mode" type="checkbox" />
    <label
      [attr.data-off]="'MODE.0' | translate"
      [attr.data-on]="'MODE.1' | translate"
      class="btn-color-mode-switch-inner mat-h4 no-pad"
      for="color_mode"
    ></label>
  </label>
</div>
