import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IChangesService } from '@core/services/changes/interface.changes.service';
import { IStorageService } from '@core/services/storage/storage.service';
import { SERVICE_LOCAL_STORAGE } from '@core/constants/system/service-providers';

@Injectable({
  providedIn: 'root',
})
export class ChangesService<T> implements IChangesService<T> {
  constructor(@Inject(SERVICE_LOCAL_STORAGE) private localStorage: IStorageService) {}

  public commit(key: any, change: any): void {
    this.localStorage.get(key).subscribe((data: any) => {
      if (!data) {
        this.localStorage.set(key, change);
      } else {
        data = { ...data, ...change };
        this.localStorage.set(key, data);
      }
    });
  }

  public get(key: any): Observable<Partial<T>> {
    return this.localStorage.get(key);
  }

  public clear(key: any): void {
    this.localStorage.remove(key);
  }

  public isDirty(key: any): Observable<boolean> {
    return this.localStorage.get(key).pipe(
      map((data: any) => {
        if (!!data) {
          if (Array.isArray(data)) {
            return !!data.length;
          } else if (typeof data === 'object') {
            return !!Object.keys(data).length;
          }
          return true;
        }
        return false;
      }),
    );
  }
}
