import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { createFromDTO, createFromFile, DocType, FileDataDTO, Realm } from '@data/file/file.model';
import { FileStore } from '@data/file/file.store';
import { IIonosService, UploadOptions } from '@core/services/http/upload/interface.upload.service';

@Injectable({
  providedIn: 'root',
})
export class IonosService implements IIonosService {
  private url = `${environment.apiUrl}/upload`;

  constructor(private http: HttpClient, private fileStore: FileStore) {}

  public upload(
    realm: Realm,
    type: DocType,
    id: string,
    file: File,
    options: UploadOptions,
  ): Observable<HttpEvent<string[]> | null> {
    if (!file) {
      return of(null);
    }
    const fileData = createFromFile(file, id, type);
    this.fileStore.upsert(fileData.fqn, fileData);
    const formData: FormData = new FormData();
    formData.append('file', file);
    formData.append('options', JSON.stringify(options));
    return this.http.post<string[]>(`${this.url}/${realm}/${type}/${id}`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  delete(realm: Realm, type: DocType, id: string, fileName: string): void {
    this.fileStore.removeFor(id, type, (f) => f.name === fileName);
    this.http.delete(`${this.url}/${realm}/${type}/${id}/${fileName}`).subscribe();
  }

  meta(realm: Realm, type: DocType, id: string) {
    return this.http
      .get<{ files: FileDataDTO[] }>(`${this.url}/${realm}/${type}/${id}`)
      .pipe(map((value) => value.files))
      .pipe(map((value) => value.map((f) => createFromDTO(f, id, type))))
      .pipe(tap((files) => this.fileStore.add(files)));
  }

  download(realm: Realm, type: DocType, id: string, name: string): Observable<Blob> {
    return this.http.get(`${this.url}/${realm}/${type}/${id}/${name}`, {
      responseType: 'blob',
    });
  }
}
