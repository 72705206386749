import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { appRouteNames } from '../../../app.routing.names';
import { errorRouteNames } from '../../../modules/error/error.routing.names';
import { SpaceService } from '@data/space/space.service';
import { SpaceQuery } from '@data/space/space.query';
import { SpaceStore } from '@data/space/space.store';
import { CollaboratorStore } from '@data/collaborator/collaborator.store';
import { CollaboratorQuery } from '@data/collaborator/collaborator.query';

@Injectable({
  providedIn: 'root',
})
export class ValidSpaceGuard implements CanActivate {
  constructor(
    private spaceService: SpaceService,
    private collaboratorStore: CollaboratorStore,
    private collaboratorQuery: CollaboratorQuery,
    private spaceQuery: SpaceQuery,
    private spaceStore: SpaceStore,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
    const id = route.paramMap.get('id');
    if (!id) {
      return this.to404();
    }

    if (this.spaceQuery.getHasCache()) {
      const valid = this.collaboratorQuery.isValid(id);
      if (valid) {
        const space = this.spaceQuery.getEntity(id);
        if (space) {
          this.spaceService.activate(space);
        }
      }
      return valid;
    }

    return this.spaceService.validate(id).pipe(
      map((data) => {
        if (!data?.space) {
          return this.to404();
        }
        if (data.parent) {
          this.spaceStore.upsert(data.parent.id, data.parent);
          this.collaboratorStore.addForSpace(data.parent.id, data.collaborators);
        }
        data.branches.forEach((branch) => {
          this.spaceStore.upsert(branch.id, branch);
          this.collaboratorStore.addForSpace(branch.id, data.collaborators);
        });
        data.siblings.forEach((branch) => {
          this.spaceStore.upsert(branch.id, branch);
          this.collaboratorStore.addForSpace(branch.id, data.collaborators);
        });

        this.spaceStore.upsert(id, data.space);
        this.collaboratorStore.addForSpace(id, data.collaborators);
        this.spaceStore.setHasCache(false);
        this.spaceService.activate(data.space);
        return true;
      }),
      catchError(() => this.to404Async()),
    );
  }

  private to404Async(): Observable<boolean> {
    this.router.navigate([appRouteNames.ERROR, errorRouteNames.ERROR404]).then();
    return of(false);
  }

  private to404(): boolean {
    this.router.navigate([appRouteNames.ERROR, errorRouteNames.ERROR404]).then();
    return false;
  }
}
