import { Injectable } from '@angular/core';
import { ChipSelectionData } from '@lib/job-elements/model/chip-selection-data';
import { MapperUtil } from '@shared/util/mapper.util';

const CAT_MASK = 0b11111111;
const CUSTOM_MASK = 0b1;
const VALUE_MASK = 0b11111111111111111;

@Injectable({
  providedIn: 'root',
})
export class ChipSelectionCodecService {
  encodeChipSelection(
    favs: ChipSelectionData[],
    predefined: { [key: string]: string[] },
    amountPredefined: number[],
  ): number[] {
    const encoded: number[] = [];
    const categories = Object.keys(predefined);
    /* eslint-disable no-bitwise */
    favs.forEach((fav) => {
      const listIdxAsBin = 1 << fav.listIdx;
      const fromAuto = fav.fromAuto ? 1 : 0;
      const category = categories[fav.listIdx];
      let valueAsBin;
      if (fromAuto) {
        valueAsBin = MapperUtil.mapArray([fav.value], false, predefined[category]);
      } else {
        valueAsBin = fav.chipIdx - amountPredefined[fav.listIdx];
      }
      const bin = ((CAT_MASK & listIdxAsBin) << 18) | ((CUSTOM_MASK & fromAuto) << 17) | (VALUE_MASK & valueAsBin);
      encoded.push(bin);
    });
    return encoded;
  }

  decodeChipSelection(
    encoded: number[],
    predefined: { [key: string]: string[] },
    selection: string[][],
    amountPredefined: number[],
  ): ChipSelectionData[] {
    const selected: ChipSelectionData[] = [];
    const categories = Object.keys(predefined);

    encoded.forEach((val) => {
      const listIdx = Math.log2(CAT_MASK & (val >> 18));
      const fromAuto = !!(CUSTOM_MASK & (val >> 17));
      const index = VALUE_MASK & val;
      let chipIdx;
      let value;
      if (fromAuto) {
        const valueIdx = Math.log2(index);
        const category = categories[listIdx];
        const predefinedElement = predefined[category];
        if (!predefinedElement) {
          return;
        }
        value = predefinedElement[valueIdx];
        chipIdx = selection[listIdx]?.indexOf(value);
      } else {
        const valueIdx = index + amountPredefined[listIdx];
        value = selection[listIdx][valueIdx];
        chipIdx = valueIdx;
      }
      if (!value || chipIdx === null) {
        return;
      }
      selected.push({ value, fromAuto, chipIdx, listIdx });
    });
    return selected;
  }
}
