<h1 mat-dialog-title>{{ 'BUDGET.BOOKING.INVOICE_EMAIL_DIALOG.title' | translate }}</h1>
<form (submit)="submit()" [formGroup]="form" fxLayout="column" mat-dialog-content>
  <p>{{ 'BUDGET.BOOKING.INVOICE_EMAIL_DIALOG.message' | translate }}</p>
  <mat-form-field appearance="outline" class="block">
    <input formControlName="email" matInput required />
    <mat-error>{{ 'LOGIN.ERROR.invalid_email' | translate }}</mat-error>
    <mat-label>{{ 'LOGIN.email' | translate }}</mat-label>
  </mat-form-field>
  <recrewt-location-selector
    [formGroup]="form"
    [predefined]="addresses$ | async"
    class="width-match-parent"
    controlName="location"
    placeholder="BUDGET.BOOKING.INVOICE_EMAIL_DIALOG.address"
    require="true"
  ></recrewt-location-selector>
  <mat-form-field appearance="outline" class="block">
    <input formControlName="referral" matInput />
    <mat-label>{{ 'BUDGET.BOOKING.INVOICE_EMAIL_DIALOG.referral' | translate }}</mat-label>
  </mat-form-field>
  <div class="p-t-1">
    <recrewt-stripe-agreement-checkbox (consentChange)="agreed = $event"></recrewt-stripe-agreement-checkbox>
  </div>
</form>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  <button (click)="submit()" [disabled]="!agreed" color="accent" mat-flat-button type="submit">
    {{ 'BUDGET.BOOKING.pay' + mode | translate }}
  </button>
</div>
