export const FRAME_FIELDS: { name: string; showWhen: any }[] = [
  { name: 'SPECIALIZED', showWhen: 'always' },
  { name: 'STRUCTURE', showWhen: 'always' },
  { name: 'INSTRUCTIONS', showWhen: 'always' },
  { name: 'SITE', showWhen: 'always' },
  { name: 'CUSTOMERS', showWhen: 'always' },
  { name: 'REPRESENT', showWhen: 'always' },
  { name: 'TEAMWORK', showWhen: 'always' },
  { name: 'INSIDE', showWhen: 'always' },
  { name: 'HEIGHT', showWhen: 'always' },
  { name: 'COMMUNICATION', showWhen: 'always' },
  { name: 'ACTIVITY', showWhen: 'always' },
  { name: 'PHYSICAL', showWhen: 'prev_falsy' },
];
