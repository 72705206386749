<div>
  <recrewt-breadcrumbs
    (homeClick)="toCategories()"
    *ngIf="currentCategory != null"
    [segments]="segments"
  ></recrewt-breadcrumbs>
  <div class="m-t-1" fxLayout="row wrap">
    <ng-template [ngIfElse]="gallery" [ngIf]="currentCategory == null">
      <ng-container *ngFor="let category of categories">
        <div
          (click)="showImagesForCategory(category)"
          fxFlex="25"
          fxLayout="column"
          fxLayoutAlign="start center"
          fxLayoutGap="12px"
        >
          <div class="folder medium orange clickable mat-elevation-z2"></div>
          <p>{{ displayCategoryWith(category) | translate }}</p>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #gallery>
      <ng-container *ngFor="let image of imagesForCategory">
        <recrewt-loadable-image
          (click)="imageClicked.emit(image.image)"
          [src]="image.thumbnail"
          class="pointer"
          height="200"
        ></recrewt-loadable-image>
      </ng-container>
    </ng-template>
  </div>
</div>
