export class LimitedStack<T> {
  private readonly limit: number;

  private data: T[] = [];

  constructor(limit: number) {
    this.limit = limit;
  }

  push(item: T): T | undefined {
    const len = this.data.push(item);
    if (len > this.limit) {
      return this.data.shift();
    }
  }

  set(item: T, idx: number): T | undefined {
    if (idx >= this.limit || idx < 0) {
      return;
    }
    this.data[idx] = item;
  }

  remove(idx: number): T | undefined {
    if (idx >= this.limit || idx < 0) {
      return;
    }
    if (idx > -1) {
      this.data.splice(idx, 1);
    }
  }

  filter(predicate: (item: T) => boolean): void {
    this.data = this.data.filter(predicate);
  }

  pop(): T | undefined {
    if (!!this.data.length) {
      return this.data.pop();
    }
  }

  peek(): T | undefined {
    const len = this.data.length;
    if (!!len) {
      return this.data[len - 1];
    }
  }

  clear(): void {
    this.data = [];
  }

  some(fn: (this: void, value: T, index: number, obj: T[]) => boolean): boolean {
    return this.data.some(fn);
  }

  forEach(fn: (value: T, index: number, array: T[]) => void): void {
    this.data.forEach(fn);
  }

  toArray(): T[] {
    return Object.assign(this.data, []);
  }
}
