<mat-expansion-panel
  #panel
  (expandedChange)="setActive($event)"
  [expanded]="filter?.isActive"
  [hideToggle]="true"
  class="mat-elevation-z0 light-bg"
>
  <mat-expansion-panel-header (click)="cb.checked = panel.expanded" collapsedHeight="36px" expandedHeight="36px">
    <mat-checkbox
      #cb
      (change)="panel.toggle()"
      (click)="$event.stopPropagation()"
      (keydown)="$event.stopPropagation()"
      [checked]="filter?.isActive"
      color="primary"
      disableRipple
    >
      {{ filter?.name | translate }}
    </mat-checkbox>
  </mat-expansion-panel-header>
  <form [formGroup]="frmGroup">
    <ng-container [formArrayName]="frmArrayName">
      <ng-template [ngIfElse]="listFilter" [ngIf]="filter?.type !== 'list'">
        <mat-form-field appearance="outline" class="m-t-1 slim clear-infix no-hint">
          <mat-select (selectionChange)="updateOption($event.value)" [value]="activeOption">
            <mat-option (click)="$event.stopPropagation()" *ngFor="let opt of filter?.options" [value]="opt">
              {{ opt.filterOption }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <ng-template [ngIf]="filter?.type === 'number'">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <mat-icon color="primary">subdirectory_arrow_right</mat-icon>
            <mat-form-field appearance="outline" class="slim clear-infix no-hint" fxFlex="96px">
              <input [formControlName]="frmControlName" autocomplete="off" matInput type="number" />
            </mat-form-field>
          </div>
        </ng-template>
        <ng-template [ngIf]="filter?.type === 'date'">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <mat-icon color="primary">subdirectory_arrow_right</mat-icon>
            <mat-form-field
              (click)="$event.stopPropagation()"
              appearance="outline"
              class="slim clear-infix no-hint"
              fxFlex="grow"
              style="width: 0"
            >
              <input [formControlName]="frmControlName" [matDatepicker]="datePicker" matInput />
              <mat-datepicker-toggle [for]="datePicker" matSuffix></mat-datepicker-toggle>
              <mat-datepicker #datePicker startView="multi-year"></mat-datepicker>
            </mat-form-field>
          </div>
        </ng-template>
        <ng-template [ngIf]="filter?.type === 'string'">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
            <mat-icon color="primary">subdirectory_arrow_right</mat-icon>
            <mat-form-field appearance="outline" class="slim clear-infix no-hint" fxFlex="grow" style="width: 0">
              <input [formControlName]="frmControlName" autocomplete="off" matInput type="text" />
            </mat-form-field>
          </div>
        </ng-template>
      </ng-template>
      <ng-template #listFilter>
        <div [formArrayName]="frmControlName" class="m-t-1" fxLayout="column" fxLayoutGap="4px">
          <mat-checkbox
            (change)="opt.isActive = $event.checked"
            *ngFor="let opt of filter?.options; index as i"
            [formControlName]="i"
          >
            <span class="ellipsize-checkbox-label">{{ opt.filterOption | translate }}</span>
          </mat-checkbox>
        </div>
      </ng-template>
    </ng-container>
  </form>
</mat-expansion-panel>
