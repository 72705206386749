import { Component, Input } from '@angular/core';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { Requirement } from '@data/job/job.model';

@Component({
  selector: 'recrewt-extendable-category-list',
  templateUrl: './extendable-requirements-list.component.html',
  styleUrls: ['./extendable-requirements-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ExtendableRequirementsListComponent,
    },
  ],
})
export class ExtendableRequirementsListComponent extends CustomFormControlComponent {
  @Input() headline = '';

  @Input() data: Requirement[] = [];

  writeValue(obj: Requirement[]): void {
    this.data = obj;
  }

  onAdded(req: Requirement): void {
    this.data.push(req);
    this.onChanged(this.data);
  }

  onDeleted(i: number): void {
    this.data.splice(i, 1);
    this.onChanged(this.data);
  }

  onUpdated(req: Requirement, i: number): void {
    this.data[i] = req;
    this.onChanged(this.data);
  }

  onCancelled(hadData: boolean): void {
    if (!hadData) {
      this.data.pop();
    }
    this.onChanged(this.data);
  }
}
