import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ApplicationFlags {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  jobDirty = false;

  resourceDirty = false;

  companyDirty = false;

  get dirty(): boolean {
    return this.jobDirty || this.companyDirty || this.resourceDirty;
  }
}
