import { Injectable } from '@angular/core';
import { NgxImageCompressService } from 'ngx-image-compress';

function getCompression(byteSize: number): [number, number] {
  const kb = byteSize / 1000;
  if (kb > 10000) {
    return [35, 35];
  } else if (kb > 5000) {
    return [45, 35];
  } else if (kb > 3000) {
    return [55, 45];
  } else if (kb > 500) {
    return [70, 60];
  } else {
    return [90, 90];
  }
}

@Injectable({
  providedIn: 'root',
})
export class ImageCompressService {
  constructor(private imageCompress: NgxImageCompressService) {}

  public compressFile(
    file: File,
    callback: (compressed: { file: File | undefined; url: string }) => void,
    compression?: [number, number],
  ): void {
    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result as string;
      const compressionRate = compression ?? getCompression(file.size);
      this.imageCompress.compressFile(imageUrl, 1, compressionRate[0], compressionRate[1]).then((path) => {
        this.fromUrl(path, file.name, file.type, (compressed) => {
          if (!compressed) {
            return;
          }
          callback({ file: compressed, url: path });
        });
      });
    };
    reader.readAsDataURL(file);
  }

  fromUrl(url: string, fileName: string, mimeType: string, onload: (file: File | undefined) => void): void {
    const request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.responseType = 'blob';
    request.onload = () => {
      const blob = request.response as Blob;
      const file = new File([blob], fileName, { type: mimeType });
      onload(file);
    };
    request.send();
  }
}
