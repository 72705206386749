import { Component, OnInit } from '@angular/core';
import { SKELETON_COLOR } from '@core/constants/system/skeleton';

@Component({
  selector: 'recrewt-chip-editor-skeleton',
  templateUrl: './chip-editor-skeleton.component.html',
  styleUrls: ['./chip-editor-skeleton.component.scss'],
})
export class ChipEditorSkeletonComponent implements OnInit {
  color = SKELETON_COLOR;

  width1 = '200px';

  width2 = '200px';

  width3 = '200px';

  width4 = '200px';

  get randomWidth(): string {
    const width = Math.floor(Math.random() * (200 - 100 + 1)) + 100;
    return width + 'px';
  }

  ngOnInit(): void {
    this.width1 = this.randomWidth;
    this.width2 = this.randomWidth;
    this.width3 = this.randomWidth;
    this.width4 = this.randomWidth;
  }
}
