import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Job } from '@data/job/job.model';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BookableType, JobGeneral } from '@data/job-general/job-general.model';
import { JobGeneralQuery } from '@data/job-general/job-general.query';
import { QuillConfiguration } from '@core/constants/system/quill';

@Component({
  selector: 'recrewt-scholastic-job-data-page',
  templateUrl: './scholastic-job-data-page.component.html',
  styleUrls: ['./scholastic-job-data-page.component.scss'],
})
export class ScholasticJobDataPageComponent implements OnInit, OnChanges {
  @Input() template: Partial<Job> = {};

  @Input() course: string | null = null;

  @Input() type: BookableType = BookableType.CLASSIC_TRAINING;

  courses: JobGeneral[] = [];

  frmScholastic!: UntypedFormGroup;

  readonly QUILL_CONFIG = QuillConfiguration;

  readonly BookableType = BookableType;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: UntypedFormBuilder,
    private jobGeneralQuery: JobGeneralQuery,
  ) {}

  ngOnInit(): void {
    const parentForm = this.ctrlContainer.form;
    const tplSchoolData = this.template?.scholasticData;
    this.frmScholastic = this.formBuilder.group({
      schoolName: [tplSchoolData?.schoolName],
      schoolLocation: [tplSchoolData?.schoolLocation],
      courseId: [tplSchoolData?.courseId],
      courseName: [tplSchoolData?.courseName],
      degree: [tplSchoolData?.degree],
      phases: [tplSchoolData?.phases],
      jobOpportunities: [tplSchoolData?.jobOpportunities],
      semesterAbroad: [tplSchoolData?.semesterAbroad ?? false],
    });
    parentForm.addControl('scholasticData', this.frmScholastic);

    this.courses = this.jobGeneralQuery.getStudies();
    if (this.course) {
      this.updateCourse(this.course);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.course?.currentValue) {
      this.updateCourse(changes.course.currentValue);
    }
  }

  updateCourse(id: string): void {
    const idControl = this.frmScholastic.controls.courseId;
    const nameControl = this.frmScholastic.controls.courseName;
    const course = this.courses.find((item) => item.id === id);
    if (course) {
      idControl.setValue(id);
      nameControl.setValue(course.name.de);
    }
  }
}
