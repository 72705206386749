export const spaceRouteNames = {
  GETTING_STARTED: 'getting-started',
  DASHBOARD: 'dashboard',
  JOB_ADMIN: 'job-administration',
  JOB_CREATOR: 'job-creator',
  COMPANY_PROFILE: 'company-profile',
  BRANCHES: 'branches',
  BUDGET: 'budget',
  FAIRS: 'job-fairs',
  MATCHES: 'matches',
  APPLICATIONS: 'applications',
};
