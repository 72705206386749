import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EntityState, EntityStore, QueryEntity } from '@datorama/akita';
import { map, take, tap } from 'rxjs/operators';
import { Identifiable } from '@data/identifiable';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DataBaseService<In, T extends Identifiable> {
  protected url?: string;

  constructor(
    protected http: HttpClient,
    protected store: EntityStore<EntityState<T>, T>,
    protected query: QueryEntity<EntityState<T>, T>,
    protected route: string,
  ) {
    this.url = `${environment.apiUrl}/${this.route}`;
  }

  getById(id: string, params?: any): Observable<T | undefined> {
    const item = this.query.getEntity(id);
    if (item) {
      return of(item).pipe(take(1));
    }
    const httpParams = new HttpParams({ fromObject: params });
    return this.http.get<T[] | undefined>(`${this.url}/${id}`, { params: httpParams }).pipe(
      map((it) => it?.[0]),
      tap((it) => {
        this.store.setLoading(false);
        if (it) this.store.upsert(it.id, it);
      }),
    );
  }

  get(params?: any, useCache = true): void {
    const httpParams = new HttpParams({ fromObject: params });
    if (!useCache || !this.query.getHasCache()) {
      this.http.get<T[]>(`${this.url}`, { params: httpParams }).subscribe((it) => {
        this.store.set(it ?? []);
      });
    }
  }

  create(data: In): Observable<any> {
    return this.http.post<T>(`${this.url}`, data).pipe(
      tap((it) => {
        if (!it) {
          return;
        }
        this.store.add(it);
      }),
    );
  }

  update(id: string, data: Partial<T>, params: any) {
    const httpParams = new HttpParams({ fromObject: params });
    this.http.put(`${this.url}/${id}`, data, { params: httpParams }).subscribe(() => {
      this.store.update(id, data);
    });
  }

  delete(id: string, params?: any): void {
    const httpParams = new HttpParams({ fromObject: params });
    this.http.delete(`${this.url}/${id}`, { params: httpParams }).subscribe(() => {
      this.store.remove(id);
    });
  }
}
