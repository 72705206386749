import { Component, OnInit } from '@angular/core';
import { CollaboratorQuery } from '@data/collaborator/collaborator.query';
import { of } from 'rxjs';
import { SpaceQuery } from '@data/space/space.query';
import { ContextMode } from '@core/enums/app/context-mode';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'recrewt-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  readonly ContextMode = ContextMode;

  hideGettingStarted$ = of(true);

  isBranch$ = of(false);

  hasBranches$ = of(false);

  mode$ = of(ContextMode.DEFAULT);

  showSidenav = false;

  private readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(distinctUntilChanged());

  constructor(
    private breakpointObserver: BreakpointObserver,
    private collaboratorQuery: CollaboratorQuery,
    private spaceQuery: SpaceQuery,
  ) {}

  ngOnInit(): void {
    this.hideGettingStarted$ = this.collaboratorQuery.isAdvanced$;
    this.mode$ = this.spaceQuery.mode$;
    this.isBranch$ = this.spaceQuery.isBranch$;
    this.hasBranches$ = this.spaceQuery.hasBranches$;
    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched([Breakpoints.Medium, Breakpoints.Large])) {
      this.showSidenav = false;
    } else if (this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall])) {
      this.showSidenav = true;
    }
  }
}
