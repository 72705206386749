import { ApplicationMetaStatus, ApplicationStage } from '@core/enums/data/application-status';
import { ContextMode } from '@core/enums/app/context-mode';

export interface Application {
  id: string;
  mode: ContextMode;
  jobRef: string;
  userId: string;
  userName: string;
  created: Date;
  isInternship?: boolean;
  isExpress?: boolean;
  hasDocument?: boolean;
  status: ApplicationMetaStatus;
  currentStage: ApplicationStage;
  currentStageDate?: Date;
}

export const ApplicationStatusKeys = ['new', 'invited', 'assessment', 'offer', 'rejected', 'archived', 'deleted'];

export function isNotArchived(application: Application): boolean {
  return application.status !== ApplicationMetaStatus.ARCHIVED && application.status !== ApplicationMetaStatus.DELETED;
}

export function isFlowFinished(application: Application): boolean {
  return application.currentStage >= ApplicationStage.REJECTED;
}

export function hasAppointment(application: Application): boolean {
  const stagesWithAppointment = [ApplicationStage.INTERVIEW, ApplicationStage.ASSESSMENT];
  return stagesWithAppointment.includes(application.currentStage);
}

export function isNotArchivedOrFinished(application: Application): boolean {
  return !isFlowFinished(application) && isNotArchived(application);
}
