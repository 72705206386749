import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'recrewt-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
})
export class ColorPickerComponent implements OnInit {
  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() formCtrlName = '';

  @Input() color = '#000000';

  @Input() title = null;

  @Output() changed = new EventEmitter<string>();

  @Output() closed = new EventEmitter<string>();

  ngOnInit(): void {
    this.color = this.parentFormGroup?.get(this.formCtrlName)?.value ?? '#000000';
  }

  onChange(color: string): void {
    this.changed.emit(color);
  }

  updateColor(color: string): void {
    this.parentFormGroup?.get(this.formCtrlName)?.patchValue(color);
    this.parentFormGroup?.get(this.formCtrlName)?.markAsDirty();
    this.onChange(color);
  }

  onClosed(color: string): void {
    this.closed.emit(color);
  }
}
