<div
  [class.mobile-accent-back]="!darken"
  [class.mobile-primary-back]="darken"
  class="p-2 mat-elevation-z3"
  fxLayout="column"
  fxLayoutAlign="end start"
  style="min-height: 80px; border-radius: 16px"
>
  <span class="mat-subheading-1 no-pad">{{ text | translate }}</span>
</div>
