import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Notification } from '@data/notification/notification.model';
import { SpaceRouter } from '@core/services/space-router/space-router.service';

@Component({
  selector: 'recrewt-notification-list-item',
  templateUrl: './notification-list-item.component.html',
  styleUrls: ['./notification-list-item.component.scss'],
})
export class NotificationListItemComponent {
  @Input() notification?: Notification;

  @Output() read = new EventEmitter<string>();

  constructor(private router: SpaceRouter) {}

  goTo(href: string | undefined, params: any | undefined): void {
    this.read.emit(this.notification?.id);
    if (!href) return;
    if (href.startsWith('http')) {
      window.open(href, '_blank');
    } else {
      this.router.navigate([href], { queryParams: params }).then();
    }
  }
}
