<div fxLayout="row" fxLayoutAlign="start center" class="container m-b-2">
  <mat-icon class="segment" routerLink="/job-administration">home</mat-icon>
  <mat-icon class="segment">chevron_right</mat-icon>
  <ngx-skeleton-loader
    [theme]="{ width: '120px', height: '30px', 'background-color': color, 'margin-bottom': '0', 'margin-left': '8px' }"
  ></ngx-skeleton-loader>
  <mat-icon class="segment">chevron_right</mat-icon
  ><ngx-skeleton-loader
    [theme]="{ width: '120px', height: '30px', 'background-color': color, 'margin-bottom': '0', 'margin-left': '8px' }"
  ></ngx-skeleton-loader>
</div>
