<h1 mat-dialog-title>{{ 'FAIRS.ADD_JOB.title' | translate }}</h1>
<div mat-dialog-content>
  <p class="info-box">{{ 'FAIRS.ADD_JOB.message' | translate }}</p>
  <recrewt-fairs-table
    (action)="onSelectChange($event)"
    [data$]="sessions$"
    [emptyMessage]="'FAIRS.ADD_JOB.EMPTY.message'"
    [emptyTitle]="'FAIRS.ADD_JOB.EMPTY.title'"
    [isSelectTable]="true"
    [loading$]="this.loading$"
    [selected$]="preselectedSessions$"
    [showActions]="false"
  ></recrewt-fairs-table>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  <button (click)="submit()" color="accent" mat-flat-button type="submit">
    {{ 'COMMON.confirm' | translate }}
  </button>
</div>
