import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
declare var gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    eventValue?: number,
  ): void {
    gtag('event', eventName, { eventCategory, eventLabel, eventAction, eventValue });
  }
}

export const GA_EVENT_ID = {
  job_created: 'job_created',
  job_updated: 'job_updated',
  job_deleted: 'job_deleted',
  space_created: 'space_created',
  space_updated: 'space_updated',
  space_deleted: 'space_deleted',
  company_profile_updated: 'company_profile_updated',
  chat_message_sent: 'chat_message_sent',
};
