<div class="p-h-3 p-h-6-desktop">
  <h3>{{ 'JOB_CREATOR.FRAME.headline' | translate }}*</h3>
  <recrewt-question-deck
    [initialValues]="initialValues"
    [parentFormGroup]="frmFrame"
    [questionSet]="questionSet"
    completeMessage="JOB_CREATOR.FRAME.PAGES.COMPLETED.title"
    formArrName="frameValues"
  ></recrewt-question-deck>
</div>
