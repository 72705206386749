import { Injectable } from '@angular/core';
import {
  combineQueries,
  EntityUIQuery,
  Order,
  QueryEntity,
  SelectAllOptionsA,
  SelectAllOptionsB,
  SelectAllOptionsD,
} from '@datorama/akita';
import { ResourceState, ResourceStore, ResourceUIState } from './resource.store';
import { combineLatest, Observable } from 'rxjs';
import { CommitState, PopulatedResource, Resource, ResourceType, ResourceUI } from '@data/resource/resource.model';
import { map } from 'rxjs/operators';
import { JobQuery } from '@data/job/job.query';
import { Job } from '@data/job/job.model';
import { ContextMode } from '@core/enums/app/context-mode';

@Injectable({ providedIn: 'root' })
export class ResourceQuery extends QueryEntity<ResourceState, Resource> {
  ui!: EntityUIQuery<ResourceUIState, ResourceUI>;

  constructor(protected store: ResourceStore, private jobQuery: JobQuery) {
    super(store);
    this.createUIQuery();
  }

  static getJobIdFromResource(resource: Resource) {
    return typeof resource.jobRef === 'string' ? resource.jobRef : resource.jobRef?.id ?? '';
  }

  static isPopulated(resource: Resource): boolean {
    return typeof resource.jobRef !== 'string';
  }

  static hasCommitFailed(status: CommitState | undefined) {
    return status === 'failed';
  }

  static isCommitInProgress(status: CommitState | undefined) {
    return status === 'in_progress';
  }

  static isCommitted(status: CommitState | undefined) {
    return !status || status === 'OK';
  }

  getCommitStatus(resourceId: string) {
    return this.ui.getEntity(resourceId)?.commitState;
  }

  isCommitted(id: string): boolean {
    const commitState = this.ui.getEntity(id)?.commitState;
    return ResourceQuery.isCommitted(commitState);
  }

  isCommitFailed(id: string): boolean {
    const commitState = this.ui.getEntity(id)?.commitState;
    return ResourceQuery.hasCommitFailed(commitState);
  }

  isPinned(id: string): boolean {
    return !!this.ui.getEntity(id)?.pinned;
  }

  getResourceFromJobRef(id: string): Resource | undefined {
    return this.getAll({ filterBy: (it) => it.jobRef === id })[0];
  }

  getJobFromResource(resource: Resource): Job | undefined {
    if (ResourceQuery.isPopulated(resource)) {
      return resource.jobRef as Job;
    }
  }

  selectAllPopulated(
    mode$: Observable<ContextMode | null>,
    resourceOptions?: SelectAllOptionsA<Resource> | SelectAllOptionsB<Resource> | SelectAllOptionsD<Resource>,
  ): Observable<PopulatedResource[]> {
    const resources$ = this.selectAll(resourceOptions ?? {});
    const jobs$ = this.jobQuery.selectAll();
    const populatedResources$ = combineQueries([resources$, jobs$]).pipe(
      map(([resources, jobs]) => {
        return this.populateResources(resources, jobs);
      }),
    );
    return combineLatest([populatedResources$, mode$]).pipe(
      map(([resources, mode]) => resources.filter((res) => res.jobRef?.mode === mode || mode == null)),
    );
  }

  selectPopulatedForOtherBranches(currentBranchId: string): Observable<PopulatedResource[]> {
    const resources$ = this.selectAll();
    const jobs$ = this.jobQuery.selectAll({ filterBy: (it) => it.company !== currentBranchId });
    const populatedResources$ = combineQueries([resources$, jobs$]).pipe(
      map(([resources, jobs]) => {
        return this.populateResources(resources, jobs);
      }),
    );
    return combineLatest([populatedResources$]).pipe(
      map(([resources]) => resources.filter((res) => res.jobRef?.mode === ContextMode.DEFAULT)),
    );
  }

  selectPinned(type: ResourceType, mode$: Observable<ContextMode>): Observable<Resource[]> {
    const resources = this.selectAllPopulated(mode$, { sortBy: 'identifiableName' });
    const pinnedIds = this.getPinnedResourceIds();
    return combineLatest([resources, pinnedIds]).pipe(
      map(([res, ids]) => {
        return res.filter((resource) => resource.type === type && ids.includes(resource.id));
      }),
      map((value) => this.filterArchived(value)),
    );
  }

  filterArchived(resources: Resource[]): Resource[] {
    return resources.filter((value) => {
      if (value.jobRef instanceof String) {
        return true;
      } else {
        return (value.jobRef as Job)?.status !== 'archived';
      }
    });
  }

  private populateResources(resources: Resource[], jobs: Job[]) {
    return resources
      .map((res) => {
        if (!res || ResourceQuery.isPopulated(res)) {
          return res as PopulatedResource;
        }
        const jobRef = res.jobRef as string;
        const job = jobs.find((j) => j.id === jobRef);
        if (!job) {
          return;
        }
        return { ...res, jobRef: job } as PopulatedResource;
      })
      .filter((r) => !!r) as PopulatedResource[];
  }

  private getPinnedResourceIds() {
    return this.ui
      .selectAll({
        filterBy: (resource) => !!resource.pinned,
        sortByOrder: Order.DESC,
      })
      .pipe(map((rui: ResourceUI[]) => rui.map((it) => it.id)));
  }
}
