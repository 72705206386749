import { Job, JobDraft } from '@data/job/job.model';

export type ResourceType = 'folder' | 'job' | 'draft' | 'template';
export type CommitState = 'OK' | 'in_progress' | 'failed';

export interface Resource {
  id: string;
  identifiableName: string;
  type: ResourceType;
  parents: string[];
  jobRef?: Job | string;
  companyRef?: string;
}

export interface PopulatedResource {
  id: string;
  identifiableName: string;
  type: ResourceType;
  parents: string[];
  jobRef?: Job;
  companyRef?: string;
}

export interface ResourceUI {
  id: string;
  localEditTimestamp?: number;
  pinned?: boolean;
  commitState?: CommitState;
}

export function createResourceFromDraft(draft: JobDraft, id: string, type: ResourceType): Resource {
  return {
    type,
    identifiableName: draft?.identifiableName ?? '-',
    id,
    jobRef: draft as Job,
    parents: [],
    companyRef: draft.company,
  };
}
