<div class="width-match-parent" fxLayout="row wrap">
  <form
    [class.finished]="finished"
    [formGroup]="parentFormGroup"
    class="container-questions"
    fxLayoutAlign="start start"
    fxLayoutAlign.gt-lg="start start"
  >
    <recrewt-card-deck-form
      #stepper
      (finished)="finished = $event"
      (pageChange)="updateAnswer($event.prev); accordion.setStep($event.next)"
      [completeMessage]="completeMessage"
      [formArrayName]="formArrName"
      [initialValues]="initialValues"
      [parentFormGroup]="parentFormGroup"
      [questionSet]="questionSet"
      class="width-match-parent"
      errorMessage="JOB_CREATOR.FRAME.ERRORS.answer_all"
    ></recrewt-card-deck-form>
  </form>
  <recrewt-accordion-list
    #accordion
    (itemClicked)="stepper.selected = $event; finished = false"
    [class.finished]="finished"
    [items]="answers"
    class="container-answers m-t-1"
  ></recrewt-accordion-list>
</div>
