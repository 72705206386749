import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-labelled-line',
  templateUrl: './labelled-component-container.component.html',
  styleUrls: ['./labelled-component-container.component.scss'],
})
export class LabelledContainerComponent {
  @Input() center = true;

  @Input() headline = '';

  @Input() desc?: string;

  @Input() labelWidth = '150px';
}
