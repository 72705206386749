import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';
import { COLOR_CONFIGS } from '@core/constants/app/status-colors';

export const BRANCH_TAG: StatusTagConfig = {
  text: 'SPACES.branch',
  backgroundColor: COLOR_CONFIGS.WARN.backgroundColor,
  color: COLOR_CONFIGS.WARN.color,
  icon: 'business',
};

export const PARENT_TAG: StatusTagConfig = {
  text: 'SPACES.parent',
  backgroundColor: COLOR_CONFIGS.INFO.backgroundColor,
  color: COLOR_CONFIGS.INFO.color,
  icon: 'business',
};

export const CURRENT_TAG: StatusTagConfig = {
  text: 'SPACES.current',
  backgroundColor: COLOR_CONFIGS.GRAY.backgroundColor,
  color: COLOR_CONFIGS.GRAY.color,
  icon: 'business',
};
