import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { QuillConfiguration } from '@core/constants/system/quill';

@Component({
  selector: 'recrewt-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
})
export class RichTextEditorComponent {
  @Input() config = QuillConfiguration;

  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() formCrtlName?: string;

  @Input() placeholder = '';

  editorStyle = {
    'min-height': '120px',
    backgroundColor: '#ffffff',
    'border-width': '1px',
    'font-family': 'Inter',
  };
}
