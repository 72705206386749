import { Component, EventEmitter, Output } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { Application, isNotArchivedOrFinished } from '@data/application/application.model';
import { ApplicationStage } from '@core/enums/data/application-status';

function showActionForStage(application: Application, stage: ApplicationStage): boolean {
  return isNotArchivedOrFinished(application) && application.currentStage < stage;
}

@Component({
  selector: 'recrewt-application-status-menu',
  templateUrl: './application-status-menu.component.html',
  styleUrls: ['./application-status-menu.component.scss'],
})
export class ApplicationStatusMenuComponent extends MenuComponent<Application> {
  @Output() stateChange = new EventEmitter<[Application, ApplicationStage]>();

  strategy: ApplicationMenuStrategy = {
    showDecline: (a: Application) => isNotArchivedOrFinished(a),
    showInterview: (a: Application) => showActionForStage(a, ApplicationStage.INTERVIEW),
    showOffer: (a: Application) => showActionForStage(a, ApplicationStage.OFFER),
    showAssessment: (a: Application) => showActionForStage(a, ApplicationStage.ASSESSMENT),
  };

  readonly INTERVIEW = ApplicationStage.INTERVIEW;

  readonly ASSESSMENT = ApplicationStage.ASSESSMENT;

  readonly OFFER = ApplicationStage.OFFER;

  readonly REJECTED = ApplicationStage.REJECTED;
}

export interface ApplicationMenuStrategy extends MenuStrategy {
  showDecline(a: Application): boolean;

  showInterview(a: Application): boolean;

  showOffer(a: Application): boolean;

  showAssessment(a: Application): boolean;
}
