import { Injectable } from '@angular/core';
import { AddEntitiesOptions, EntityState, EntityStore, isArray, StoreConfig } from '@datorama/akita';
import { Collaborator } from './collaborator.model';
import { SpaceQuery } from '@data/space/space.query';

export interface CollaboratorState extends EntityState<Collaborator> {}

type OrArray<T> = T | T[];

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'collaborators', resettable: true })
export class CollaboratorStore extends EntityStore<CollaboratorState, Collaborator> {
  constructor(private spaceQuery: SpaceQuery) {
    super();
  }

  addForSpace(spaceId: string, entities: OrArray<Collaborator>, options?: AddEntitiesOptions): void {
    const oldCollaborators = this.spaceQuery.getEntity(spaceId)?.collaborators;
    this.remove(oldCollaborators);
    if (isArray(entities)) {
      this.add(entities as Collaborator[], options);
    } else {
      this.add(entities as Collaborator, options);
    }
  }
}
