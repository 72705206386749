import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-contact-sheet',
  templateUrl: './contact-sheet.component.html',
  styleUrls: ['./contact-sheet.component.scss'],
})
export class ContactSheetComponent {
  @Input() contact: any;
}
