<div [formGroup]="form" hidden>
  <input [formControlName]="controlName" [required]="require" />
</div>

<mat-form-field *ngIf="useMatStyle" [class.no-hint]="!error" appearance="outline" class="on-surface" fxFlex="grow">
  <input
    #input
    (input)="searchControl.setValue(input.value)"
    [matAutocomplete]="auto"
    [ngModel]="initValue"
    [placeholder]="placeholder | translate"
    autocomplete="new-address"
    matInput
    name="query"
  />
  <mat-error *ngIf="error">{{ error | translate }}</mat-error>
  <mat-label>{{ placeholder | translate }}</mat-label>
</mat-form-field>

<div *ngIf="!useMatStyle" fxFlex="grow">
  <input
    #input
    (input)="searchControl.setValue(input.value)"
    [matAutocomplete]="auto"
    [ngModel]="initValue"
    [placeholder]="'LOCATION_SELECT.placeholder' | translate"
    autocomplete="new-address"
    class="no-pad p-1 mat-styled-input"
    fxFlex="grow"
    name="query"
    takeFocus
  />
</div>

<mat-autocomplete
  #auto="matAutocomplete"
  (optionSelected)="setFormFieldValue($event.option.value)"
  [displayWith]="displayValue"
>
  <mat-option *ngFor="let value of results$ | async" [value]="value">
    <mat-icon class="on-surface-medium">place</mat-icon>
    <span>{{ street(value) }}</span> |
    <small class="on-surface-medium">{{ city(value) }}</small>
  </mat-option>
</mat-autocomplete>
