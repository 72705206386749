import { ResourceHandler } from '@core/services/resource/resource.handler';
import { Resource } from '@data/resource/resource.model';
import { ResourceQuery } from '@data/resource/resource.query';
import { Job } from '@data/job/job.model';

export class DraftHandler extends ResourceHandler {
  onClicked(resource: Resource): void {
    if (!ResourceQuery.isPopulated(resource)) return;
    this.jobService.deleteLocalDraft(resource.id);
    this.openJobCreator(resource.jobRef as Job);
  }

  delete(resource: Resource) {
    super.delete(resource);
    this.jobService.deleteLocalDraft(resource.id);
  }
}
