<div class="phone mat-elevation-z2 no-scrollbar mobile-text" style="overflow-y: scroll; overflow-x: hidden">
  <header>
    <mat-card class="header-card mat-elevation-z3 p-1 p-t-4" fxLayoutAlign="start center">
      <button (click)="back.emit()" mat-icon-button>
        <mat-icon class="no-pad" color="primary">chevron_left</mat-icon>
      </button>
      <p class="mat-body-1 primary text-align-center" style="padding-top: 2px">{{ selected | translate }}</p>
    </mat-card>
  </header>
  <div>
    <div class="image-wrapper">
      <img [src]="imageUrl" class="center-crop" style="min-height: 164px" />
    </div>
    <mat-card class="body-card mat-elevation-z3 no-pad">
      <div class="icon-circle mat-elevation-z3" fxLayoutAlign="center center">
        <ng-content select="[gradicon]"></ng-content>
      </div>
      <div style="margin-top: -16px">
        <ng-content select="[content]"></ng-content>
      </div>
    </mat-card>
  </div>
</div>
