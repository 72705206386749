<recrewt-click-editable (cancel)="onCancel()" (update)="onUpdate()">
  <ng-template viewMode>
    <p>{{ value }}</p>
  </ng-template>
  <ng-template editMode>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-slider
        (valueChange)="onValueChanged()"
        [(ngModel)]="newValue"
        color="primary"
        max="50"
        thumbLabel="true"
      ></mat-slider>
      <p fxFlex="0px"></p>
      <!--The p tag is somehow required for the initial slider animation to play. Please do not remove it. -->
    </div>
  </ng-template>
</recrewt-click-editable>
