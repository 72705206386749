import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'recrewt-radio-editor',
  templateUrl: './radio-editor.component.html',
  styleUrls: ['./radio-editor.component.scss'],
})
export class RadioEditorComponent implements OnInit {
  @Input() type: 'yes-no' | null = null;

  @Input() config: { name: string; value: boolean }[] = [];

  @Input() model: any;

  @Output() update = new EventEmitter<any>();

  @Output() cancel = new EventEmitter<any>();

  selectedName = '';

  dirty = false;

  private readonly YES_NO = [
    {
      name: 'COMMON.yes',
      value: true,
    },
    {
      name: 'COMMON.no',
      value: false,
    },
  ];

  ngOnInit(): void {
    if (this.type === 'yes-no') {
      this.config = this.YES_NO;
    }
  }

  onUpdate(): void {
    if (this.dirty) {
      this.update.emit(this.model);
      this.dirty = false;
    }
  }

  onCancel(): void {
    this.dirty = false;
    this.cancel.emit();
  }

  onChange(event: MatRadioChange): void {
    this.dirty = true;
    this.selectedName = event.source.name;
  }
}
