import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'recrewt-details-page-base',
  templateUrl: './details-page-base.component.html',
  styleUrls: ['./details-page-base.component.scss'],
})
export class DetailsPageBaseComponent {
  @Output() back = new EventEmitter<void>();

  @Output() changePage = new EventEmitter<number>();

  @Input() imageUrl = '';

  @Input() selected = '';
}
