import { Component, Input } from '@angular/core';
import { bubbleGrowAnimaiton } from '@shared/animation/grow-shrink';

@Component({
  selector: 'recrewt-high-emphasis-button',
  templateUrl: './high-emphasis-button.component.html',
  styleUrls: ['./high-emphasis-button.component.scss'],
  animations: [bubbleGrowAnimaiton],
})
export class HighEmphasisButtonComponent {
  @Input() icon = 'arrow_forward';

  @Input() text = '';

  @Input() show = false;

  @Input() disabled = false;

  @Input() position: 'left' | 'right' = 'right';

  @Input() iconPosition: 'left' | 'right' = 'right';
}
