<recrewt-editable-list-group
  (chooseClicked)="openSiteDialog(null)"
  (removed)="remove($event)"
  [additionalActions]="additionalActions"
  [allowDelete]="false"
  [error]="error"
  [itemTpl]="itemTpl"
  [items]="sites"
  emptyHint="COMPANY_PROFILE.GENERAL.sites_hint"
></recrewt-editable-list-group>

<ng-template #additionalActions let-selection>
  <ng-container *ngIf="selection?.length === 1 && editable">
    <button (click)="openSiteDialog(selection[0])" color="accent" mat-flat-button type="button">
      {{ 'COMMON.edit' | translate }}
    </button>
  </ng-container>
</ng-template>

<ng-template #itemTpl let-site="item">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-icon>business</mat-icon>
    <p class="no-pad single-line m-h-1">
      <span>{{ site.name }}</span> |
      <small class="on-surface-medium">{{ site?.location?.address }}</small>
    </p>
    <mat-chip *ngIf="site?.hq" color="primary" selected>{{ 'COMPANY_PROFILE.GENERAL.DIALOG.hq' | translate }}</mat-chip>
  </div>
</ng-template>
