import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-highlight-item',
  templateUrl: './highlight-item.component.html',
  styleUrls: ['./highlight-item.component.scss', '../../mobile-theme.scss'],
})
export class HighlightItemComponent {
  @Input() icon = 'add';

  @Input() category = '';

  @Input() desc = '';
}
