import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColoredOption } from './data/colored-option';

@Component({
  selector: 'recrewt-colored-select',
  templateUrl: './colored-select.component.html',
  styleUrls: ['./colored-select.component.scss'],
})
export class ColoredSelectComponent implements OnInit {
  @Input() selected?: string;

  @Input() options: ColoredOption[] = [];

  @Output() selectionChanged = new EventEmitter<string>();

  model?: ColoredOption;

  selectedItemStyle: any;

  resetFlag = true;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (!this.selected) {
      return;
    }
    this.updateSelection(this.selected);
  }

  updateSelection(status: string): void {
    if (status) {
      this.model = this.options.find((option) => option.value === status);
    }
    if (!this.model) {
      this.model = this.options[0];
    }
    this.updateSelectionColor();
    this.resetFlag = false;
    this.cdr.detectChanges();
    this.resetFlag = true;
  }

  onSelectionChanged(): void {
    this.updateSelectionColor();
    this.selectionChanged.emit(this.model?.value);
  }

  private updateSelectionColor(): void {
    this.selectedItemStyle = {
      'border-color': this.model?.color,
      'background-color': this.model?.backgroundColor,
    };
  }
}
