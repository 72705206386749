import { Filter } from '@data/job/filter';

export const TRAINEE_FILTER: Filter[] = [
  {
    id: 'dl_class_b',
    type: 'yes-no',
    filterName: 'JOB_CREATOR.REQUIREMENTS.FILTER.dl_class_b',
  },
  {
    id: 'job_experience',
    type: 'yes-no',
    filterName: 'JOB_CREATOR.REQUIREMENTS.FILTER.job_experience',
  },
];
