<div class="on-surface-medium m-b-1" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="24px">
  <mat-icon
    [class.accent-icon]="color === 'accent'"
    [class.primary-icon]="color === 'primary'"
    class="circle-icon"
    style="min-width: 24px"
  >
    {{ icon }}
  </mat-icon>
  <div>
    <p class="no-pad mat-caption">{{ title | translate | uppercase }}</p>
    <span class="mat-subheading-1 no-pad p-b-2">{{ desc | translate: _param }}</span>
  </div>
</div>
