import { Component, OnInit } from '@angular/core';
import { FilterableTableComponent } from '@shared/components/filterable-table/filterable-table.component';
import { Sort } from '@angular/material/sort';
import { Space } from '@data/space/space.model';
import { compare } from '@shared/util/array.util';

@Component({
  selector: 'recrewt-branches-table',
  templateUrl: './branches-table.component.html',
  styleUrls: ['./branches-table.component.scss'],
})
export class BranchesTableComponent extends FilterableTableComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
    this.emptyConfig = {
      icon: 'business',
      text: 'COMPANY_PROFILE.ADMIN.BRANCHES.TABLE.EMPTY.desc',
      headline: 'COMPANY_PROFILE.ADMIN.BRANCHES.TABLE.EMPTY.headline',
      buttonIcon: 'add',
      buttonText: 'COMPANY_PROFILE.ADMIN.BRANCHES.TABLE.EMPTY.button',
    };
  }

  onDeleteSpace(spaceId: string, spaceName: string): void {
    this.onActionClick('deleteClicked', { spaceId, spaceName });
  }

  sortBy = (sort: Sort, data: Space[]) => {
    const isAsc = sort.direction === 'asc';
    return data.sort((a, b) => {
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'customerNr':
          return compare(a.customerNr, b.customerNr, isAsc);
        default:
          return 0;
      }
    });
  };
}
