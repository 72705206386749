<mat-card class="width-match-parent p-1" style="border-radius: 16px">
  <div fxFlex="40" fxLayoutAlign="center center">
    <div class="avatar mat-elevation-z3 m-b-1" fxLayoutAlign="center start">
      <img [src]="contact?.male ? 'assets/images/character_male.svg' : 'assets/images/character_female.svg'" />
    </div>
  </div>
  <div fxFlex="60" fxLayout="column">
    <p class="no-pad on-surface-medium">NAME</p>
    <p class="mat-body-2 text-weight-bold m-b-1">{{ contact?.name ?? '-' }}</p>
    <p class="no-pad on-surface-medium">TELEFON</p>
    <p class="mat-body-2 text-weight-bold m-b-1">{{ contact?.phone ?? '-' }}</p>
    <p class="no-pad on-surface-medium">EMAIL</p>
    <p class="mat-body-2 text-weight-bold m-b-1" style="overflow: hidden; text-overflow: ellipsis">
      {{ contact?.email ?? '-' }}
    </p>
  </div>
</mat-card>
