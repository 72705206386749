import { Component, OnInit } from '@angular/core';
import { MessageQuery } from '@data/message/message.query';
import { MessageState } from '@data/message/message.store';
import { EMPTY, Observable, of } from 'rxjs';
import { MessageService } from '@data/message/message.service';

@Component({
  selector: 'recrewt-info-box-once',
  templateUrl: './info-box-once.component.html',
  styleUrls: ['./info-box-once.component.scss'],
})
export class InfoBoxOnceComponent implements OnInit {
  message$: Observable<MessageState> = EMPTY;

  loading$: Observable<boolean> = of(false);

  constructor(private messageQuery: MessageQuery, private messageService: MessageService) {}

  ngOnInit(): void {
    this.message$ = this.messageQuery.select();
    this.loading$ = this.messageQuery.selectLoading();
    this.messageService.getMessage();
  }

  hide(): void {
    this.messageService.hideMessage();
  }
}
