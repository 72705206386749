import { Injectable } from '@angular/core';
import { DataBaseService } from '@data/data.base.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SessionQuery } from '@data/session/session.query';
import { SessionStore } from '@data/session/session.store';
import { Session } from '@data/session/session.model';
import { CompanyQuery } from '@data/company/company.query';
import { switchMap, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends DataBaseService<Session, Session> {
  constructor(
    private companyQuery: CompanyQuery,
    http: HttpClient,
    fairsQuery: SessionQuery,
    fairsStore: SessionStore,
  ) {
    super(http, fairsStore, fairsQuery, `fairs`);
  }

  getForCompany() {
    const company$ = this.companyQuery.getActiveIdWhenReady();
    return company$.pipe(
      switchMap((company) => {
        if (!company) return EMPTY;
        const params = new HttpParams({ fromObject: { company } });
        return this.http.get<Session[]>(`${this.url}`, { params });
      }),
      tap((it) => this.store.set(it ?? [])),
    );
  }
}
