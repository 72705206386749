<div class="width-match-parent empty-state-container height-match-parent" fxLayoutAlign="center center">
  <div>
    <recrewt-info-section
      (buttonClicked)="buttonClick.emit()"
      [buttonIcon]="config?.buttonIcon"
      [buttonText]="config?.buttonText"
      [headline]="config?.headline"
      [icon]="config?.icon"
      [text]="config?.text"
    ></recrewt-info-section>
  </div>
</div>
