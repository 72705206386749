import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'recrewt-card-deck-form',
  templateUrl: './card-deck-form.component.html',
  styleUrls: ['./card-deck-form.component.scss'],
})
export class CardDeckFormComponent implements OnInit {
  @Input() questionSet: QuestionConfig[] = [];

  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() formArrayName = '';

  @Input() initialValues: any[] = [];

  @Input() completeMessage = '';

  @Input() errorMessage = 'Error';

  @Output() finished = new EventEmitter<boolean>();

  @Output() pageChange = new EventEmitter<{ prev: number; next: number }>();

  stepValues?: UntypedFormArray;

  selected = 0;

  ngOnInit(): void {
    if (!this.parentFormGroup || !this.formArrayName) {
      throw Error('Form is undefined no FormArray name is passed');
    }
    this.stepValues = this.parentFormGroup?.controls[this.formArrayName] as UntypedFormArray;
    if (!this.stepValues) {
      throw Error(`Form does not contain a FormArray with name: ${this.formArrayName}`);
    }
  }

  boolToInt(value: boolean): 1 | 0 {
    return value ? 1 : 0;
  }

  onPageChange(prev: number, next: number): void {
    if (
      next > 0 &&
      next < this.questionSet.length &&
      this.stepValues?.value[next - 1]?.value !== 0 &&
      this.questionSet[next]?.showWhen === 'prev_falsy'
    ) {
      this.stepValues?.at(next)?.setValue({ value: 0 });
      next++;
    }
    this.finished.emit(next === this.questionSet.length);
    this.pageChange.emit({ prev, next });
    this.selected = next;
  }
}

export interface QuestionConfig {
  title: string;
  falsyOption: string;
  truthyOption: string;
  showWhen: 'always' | 'prev_falsy' | 'prev_truthy';
}
