import { ResourceHandler } from '@core/services/resource/resource.handler';
import { CommitState, Resource } from '@data/resource/resource.model';
import { ResourceQuery } from '@data/resource/resource.query';
import { JobTemplate } from '@data/job/job.model';
import { Observable } from 'rxjs';
import {
  ConfirmDialogComponent,
  ConfirmDialogData,
} from '@shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { ResourceService } from '@data/resource/resource.service';
import { JobService } from '@data/job/job.service';
import { JobCreatorWizardService } from '../../../modules/job-creator/core/job-creator-wizard.service';
import { JobStore } from '@data/job/job.store';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { SpaceQuery } from '@data/space/space.query';
import { cloneDeep } from 'lodash';

export class TemplateHandler extends ResourceHandler {
  constructor(
    ionos: SpaceIonosService,
    spaceQuery: SpaceQuery,
    resourceQuery: ResourceQuery,
    resourceService: ResourceService,
    jobService: JobService,
    jobCreator: JobCreatorWizardService,
    dialog: DialogService<any, any>,
    private jobStore: JobStore,
  ) {
    super(ionos, spaceQuery, resourceQuery, resourceService, jobService, jobCreator, dialog);
  }

  onClicked(resource: Resource): void {
    const status = this.resourceQuery.getCommitStatus(resource.id);
    this.handleClickAccordingToCommitState(status, resource);
  }

  delete(resource: Resource) {
    super.delete(resource);
    this.resourceService.delete(resource.id);
  }

  editTemplate(draft?: JobTemplate): void {
    this.jobCreator.open(draft);
    this.jobCreator.confirmed().subscribe((data) => {
      if (!data?.job || !draft?.id) {
        return;
      }
      delete data.job.assignee;
      this.jobService.update(draft.id, data.job)?.subscribe(() => this.jobStore.update(draft.id ?? '', data.job));
    });
  }

  private handleClickAccordingToCommitState(status: CommitState | undefined, resource: Resource) {
    if (ResourceQuery.isCommitInProgress(status)) return;
    this.handleUncommittedClick(status!, resource);
    this.handleCommittedClick(status!, resource);
  }

  private handleCommittedClick(status: CommitState, resource: Resource) {
    if (!ResourceQuery.isCommitted(status)) {
      return;
    }
    const job = cloneDeep(resource.jobRef as any);
    if (job.status != 'template') {
      this.openJobCreator(job);
      return;
    }
    this.openTemplateDialog().subscribe((res) => {
      if (res == null) return;
      if (res) {
        job.status = 'none';
        this.openJobCreator(job);
      } else {
        this.editTemplate(job);
      }
    });
  }

  private handleUncommittedClick(status: CommitState, resource: Resource) {
    if (status === 'failed') {
      this.tryToPersistJob(resource);
    }
  }

  private tryToPersistJob(resource: Resource) {
    const job = this.resourceQuery.getJobFromResource(resource);
    if (!job) return;
    this.jobService.syncJob(resource.id, job, true);
  }

  private openTemplateDialog(): Observable<boolean | null> {
    const data: ConfirmDialogData = {
      altConfirmText: 'MENUS.RESOURCE_CREATE.template',
      cancelText: 'COMMON.cancel',
      confirmColor: 'accent',
      confirmText: 'MENUS.RESOURCE_CREATE.job',
      message: 'TEMPLATE_DIALOG.message',
      title: 'TEMPLATE_DIALOG.headline',
    };
    this.dialog.open(ConfirmDialogComponent, data, { width: '600px' });
    return this.dialog.confirmed();
  }
}
