<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <div class="info-box">
    <p style="white-space: pre-line">{{ data.message | translate }}</p>
    <p *ngIf="data.warning">
      <strong>{{ data.warning | translate }}</strong>
    </p>
  </div>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="noActionClicked()" mat-button>{{ data.cancelText ?? 'COMMON.cancel' | translate }}</button>
  <button *ngIf="data.altConfirmText" [mat-dialog-close]="false" mat-button>
    {{ data.altConfirmText | translate }}
  </button>
  <button [color]="data.confirmColor ?? 'accent'" [mat-dialog-close]="true" mat-flat-button>
    {{ data.confirmText | translate }}
  </button>
</div>
