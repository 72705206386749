<recrewt-rail-sidenav
  (openClicked)="openClicked($event)"
  *ngIf="showSidenav"
  [badge]="(items | async)?.length"
  [expandedWidth]="325"
  [opened]="opened"
  closedWidth="32"
  hideOnClose="true"
  position="end"
>
  <div sidenav>
    <div class="header p-l-4 p-t-3 p-r-2">
      <p class="mat-h3">{{ headline | translate }}</p>
    </div>
    <div>
      <recrewt-no-matches *ngIf="!(items | async)?.length"></recrewt-no-matches>
      <div (click)="onItemClicked(item.data)" *ngFor="let item of items | async" class="m-h-3 m-b-2">
        <recrewt-list-sidenav-item [itemType]="itemType" [item]="item"></recrewt-list-sidenav-item>
      </div>
    </div>
  </div>
  <div sidenav-content>
    <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
  </div>
</recrewt-rail-sidenav>

<ng-container *ngIf="!showSidenav">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-container>

<ng-template #contentTpl><ng-content></ng-content></ng-template>
