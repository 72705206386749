import { Component, Inject, OnInit } from '@angular/core';
import { SessionQuery } from '@data/session/session.query';
import { SessionService } from '@data/session/session.service';
import { SubscriptionService } from '@data/payment/subscription/subscription.service';
import { SubscriptionQuery } from '@data/payment/subscription/subscription.query';
import { Observable, of } from 'rxjs';
import { ObservableUtil } from '@shared/util/observable.util';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContextMode } from '@core/enums/app/context-mode';
import { map, switchMap } from 'rxjs/operators';
import { ActionEvent } from '@shared/components/filterable-table/filterable-table.component';
import { PopulatedFairBooth } from '@data/fair-booth/fair-booth.model';
import { FairBoothQuery } from '@data/fair-booth/fair-booth.query';
import { FairBoothService } from '@data/fair-booth/fair-booth.service';
import { Job } from '@data/job/job.model';

@Component({
  selector: 'recrewt-add-job-to-fairs-dialog',
  templateUrl: './add-job-to-fairs-dialog.component.html',
  styleUrls: ['./add-job-to-fairs-dialog.component.scss'],
})
export class AddJobToFairsDialogComponent implements OnInit {
  sessions$: Observable<PopulatedFairBooth[]> = of([]);

  preselectedSessions$: Observable<PopulatedFairBooth[]> = of([]);

  loading$ = of(false);

  private selectedFairs: PopulatedFairBooth[] = [];

  constructor(
    private subscriptionService: SubscriptionService,
    private subscriptionQuery: SubscriptionQuery,
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    private fairBoothService: FairBoothService,
    private fairBoothQuery: FairBoothQuery,
    private dialogRef: MatDialogRef<AddJobToFairsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: { preselect?: string[]; job: Job },
  ) {}

  ngOnInit(): void {
    this.fairBoothService.get();
    this.subscriptionService.get();
    this.sessionService.getForCompany();

    this.loading$ = ObservableUtil.combineBoolean(
      this.sessionQuery.selectLoading(),
      this.subscriptionQuery.selectLoading(),
      this.fairBoothQuery.selectLoading(),
    );

    this.sessions$ = this.selectFairsSupportingJobType();
    this.preselectedSessions$ = this.sessions$.pipe(
      map((fairs) => fairs.filter((fair) => this.data.preselect?.includes(fair.session.id!))),
    );
  }

  onSelectChange(event: ActionEvent) {
    if (event.actionId !== 'selectChange') {
      return;
    }
    this.selectedFairs = event.target;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    for (const fair of this.selectedFairs) {
      this.fairBoothService.update(fair.id!, { jobs: [...fair.jobs, this.data.job.id!] });
    }
    this.dialogRef.close();
  }

  private selectFairsSupportingJobType() {
    return this.subscriptionQuery.selectAllForMode(of(ContextMode.FAIR)).pipe(
      map((subs) => subs.filter(this.subscriptionQuery.isActiveSubscriptionForJob(this.data.job, true))),
      map((subs) => subs.map((_subs) => _subs.fairId!)),
      switchMap((fairIds) => this.fairBoothQuery.selectPopulated(fairIds)),
    );
  }
}
