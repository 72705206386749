import { Component } from '@angular/core';
import { SKELETON_COLOR } from '@core/constants/system/skeleton';

@Component({
  selector: 'recrewt-breadcrumb-skeleton',
  templateUrl: './breadcrumb-skeleton.component.html',
  styleUrls: ['../breadcrumb.component.scss', './breadcrumb-skeleton.component.scss'],
})
export class BreadcrumbSkeletonComponent {
  color = SKELETON_COLOR;
}
