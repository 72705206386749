import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { Match, MatchUI } from './match.model';

export interface MatchState extends EntityState<Match> {}

export interface MatchUIState extends EntityState<MatchUI> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'match', resettable: true, idKey: '_id' })
export class MatchStore extends EntityStore<MatchState, Match> {
  ui!: EntityUIStore<MatchUIState, MatchUI>;

  constructor() {
    super();
    this.createUIStore();
  }
}
