import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Role, Space, SpaceDTO } from '@data/space/space.model';
import { UserDataService } from '@data/user-data/user-data.service';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { UserDataQuery } from '@data/user-data/user-data.query';
import { SpaceQuery } from '@data/space/space.query';
import { map, takeUntil } from 'rxjs/operators';
import { TextFieldEditorComponent } from '@shared/components/editor/text-field-editor/text-field-editor.component';
import { UserQuery } from '@data/user/user.query';
import { CollaboratorQuery } from '@data/collaborator/collaborator.query';
import { SpaceService } from '@data/space/space.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDynamicComponent } from '@core/services/template/dynamic.component';
import { CompanySite } from '@data/company/company.model';
import { guid } from '@datorama/akita';

export type CollaboratorTableData = {
  email: string;
  role?: Role;
  footer: boolean;
  resend: boolean;
  active: boolean;
  registered: boolean;
};

export type SpaceEditData = {
  email: string;
  edit?: boolean;
  parentRef?: string;
  site?: CompanySite;
};

@UntilDestroy()
@Component({
  selector: 'recrewt-space-edit',
  templateUrl: './space-edit.component.html',
  styleUrls: ['./space-edit.component.scss'],
})
export class SpaceEditComponent implements IDynamicComponent, OnInit, OnDestroy {
  loading$: Observable<boolean> = of(false);

  atLeastOneAdmin = true;

  form!: UntypedFormGroup;

  displayedColumns: string[] = ['email', 'role', 'actions'];

  dataSource: MatTableDataSource<CollaboratorTableData>;

  space?: Space;

  readonly OPTIONS = [
    { name: 'SPACES.CREATE.ROLES.admin', value: 'admin' },
    { name: 'SPACES.CREATE.ROLES.collaborator', value: 'collaborator' },
  ];

  readonly FOOTER_DATA: CollaboratorTableData = {
    email: '',
    footer: true,
    resend: false,
    active: true,
    registered: true,
  };

  @Input() data: SpaceEditData = { email: '' };

  @Output() submitted = new EventEmitter<SpaceDTO>();

  isAddSiteAllowed$ = this.spaceQuery.isBranch$.pipe(
    map(
      (isBranch) =>
        !(isBranch || !!this.data.parentRef) || //
        ((isBranch || !!this.data.parentRef) && this.form.value.sites.length == 0),
    ),
  );

  private destroyed$ = new ReplaySubject(1);

  private readonly EMAIL_REGEX =
    /^(([^<>+()\[\]\\.,;:\s@"-#$%&=]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userDataService: UserDataService,
    private userDataQuery: UserDataQuery,
    private spaceQuery: SpaceQuery,
    private spaceService: SpaceService,
    private userQuery: UserQuery,
    private collaboratorQuery: CollaboratorQuery,
  ) {
    this.dataSource = new MatTableDataSource([this.FOOTER_DATA]);
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      sites: [!this.data.parentRef && this.data.site ? [this.data.site] : []],
      location: [this.data.parentRef && this.data.site ? [this.data.site.location] : []],
      copyProfile: [!!this.data.parentRef],
      branched: [false],
    });
    if (this.data.edit) {
      this.userDataService.getForActive();
      this.loading$ = this.userDataQuery.selectLoading();
      this.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
        if (!loading) this.initFromActiveSpace();
      });
    } else {
      this.form.get(this.data.parentRef ? 'location' : 'sites')?.setValidators(Validators.required);
      const email = this.userQuery.email();
      if (!!email) {
        this.addRow(email, 'admin', false, true, true);
      }
    }
  }

  addRow(email: string, role: Role = 'collaborator', resend = false, active = false, registered = false): void {
    const data = this.dataSource.data;
    if (this.isValidEmail(email, data)) {
      data.splice(1, 0, { email, role, footer: false, resend, active, registered });
    }
    this.dataSource.data = data;
  }

  submit(): void {
    const { value, valid, pristine } = this.form;
    if (valid && !pristine) {
      if (!this.data.edit) {
        this.dataSource.data.pop();
      }
      const sites = this.getSites(value);
      this.form.markAsPristine();
      this.submitted.emit({
        name: value.name,
        sites,
        collaborators: this.dataSource.data.slice(1).map((d) => ({
          email: d.email.toLowerCase(),
          role: d.role ?? 'collaborator',
        })),
        parentRef: this.data.parentRef,
        copyProfile: value.copyProfile,
        branched: this.data.edit ? undefined : value.branched,
      });
    }
  }

  deleteRow(email: string): void {
    let data = this.dataSource.data;
    data = data.filter((value) => value.email !== email);
    this.dataSource.data = data;
    this.form.markAsDirty();
    this.submit();
  }

  isValidEmail(email: string, emails: CollaboratorTableData[]): boolean {
    const notYetSelected = !emails.map((v) => v.email).includes(email);
    const isEmail = !!this.EMAIL_REGEX.exec(email);
    const emailNotEmpty = !!email?.length;
    return emailNotEmpty && notYetSelected && isEmail;
  }

  ngOnDestroy(): void {
    this.submit();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  clearInput(input: TextFieldEditorComponent): void {
    input.writeValue(null);
    this.form.markAsDirty();
  }

  updateHasAdmin(): void {
    this.atLeastOneAdmin = this.dataSource.data.some((c) => c.role === 'admin');
  }

  resendInvite(email: string): void {
    const spaceName = this.space?.name;
    const spaceId = this.space?.id;
    if (!email?.length || !spaceName?.length || !spaceId?.length) {
      return;
    }
  }

  private getSites(value: any) {
    let sites;
    if (!this.data.edit) {
      if (this.data.parentRef) {
        sites = [
          {
            location: value.location,
            name: value.name,
            hq: false,
            id: `${Date.now()}${guid()}`,
          },
        ];
      } else {
        sites = value.sites as CompanySite[];
      }
    }
    return sites;
  }

  private initFromActiveSpace(): void {
    const collaborators = this.userDataQuery.selectAll();
    const space = this.spaceQuery.getActiveWhenReady();

    space.subscribe((s) => {
      if (!s) return;
      this.space = s;
      this.form.controls.name.patchValue(s?.name);
    });

    combineLatest([collaborators, space])
      .pipe(
        takeUntil(this.destroyed$),
        map(([u, s]) => {
          return s?.collaborators?.map((sc) => {
            const collaborator = this.collaboratorQuery.getEntity(sc);
            const cd = u?.find((c) => c.id === collaborator?.userRef);
            return {
              email: cd?.email,
              name: cd?.name,
              role: collaborator?.role,
              active: collaborator?.status === 'active',
            };
          });
        }),
      )
      .subscribe((cds) => {
        cds?.forEach((cd) => {
          if (!cd.email) {
            return;
          }
          this.addRow(cd.email, cd.role, !cd.active, cd.active, !!cd.name);
        });
      });
  }
}
