export class MutableFileList {
  length = 0;

  [index: number]: File;

  constructor(files: File[]) {
    for (let i = 0; i < files.length; i++) {
      this[i] = files[i];
    }

    this.length = files.length;
  }

  item(index: number): File {
    return this[index];
  }
}
