import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { appRouteNames } from '../../../app.routing.names';
import { SpaceQuery } from '@data/space/space.query';
import { switchMap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { errorRouteNames } from '../../../modules/error/error.routing.names';

@Injectable({
  providedIn: 'root',
})
export class SpaceRouter {
  constructor(private router: Router, private spaceQuery: SpaceQuery) {}

  async navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    const nav$ = this.spaceQuery.getActiveIdWhenReady().pipe(
      switchMap((id) => {
        if (!id) {
          return of(false);
        }
        const space = appRouteNames.SPACE.replace(':id', id);
        return this.router.navigate([space, ...commands], extras);
      }),
    );

    return firstValueFrom(nav$);
  }

  error404() {
    this.router.navigate([appRouteNames.ERROR, errorRouteNames.ERROR404]).then();
  }
}
