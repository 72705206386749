import { ResourceHandler } from '@core/services/resource/resource.handler';
import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { ResourceQuery } from '@data/resource/resource.query';
import { SpaceRouter } from '@core/services/space-router/space-router.service';
import { JobService } from '@data/job/job.service';
import { ResourceService } from '@data/resource/resource.service';
import { JobCreatorWizardService } from '../../../modules/job-creator/core/job-creator-wizard.service';
import { CommitState, Resource } from '@data/resource/resource.model';
import { spaceRouteNames } from '../../../modules/space/space.routing.names';
import { jobAdminRouteNames } from '../../../modules/job/job-admin.routing.names';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { SpaceQuery } from '@data/space/space.query';

export class JobHandler extends ResourceHandler {
  constructor(
    ionos: SpaceIonosService,
    spaceQuery: SpaceQuery,
    resourceQuery: ResourceQuery,
    resourceService: ResourceService,
    jobService: JobService,
    jobCreator: JobCreatorWizardService,
    dialog: DialogService<any, any>,
    private router: SpaceRouter,
  ) {
    super(ionos, spaceQuery, resourceQuery, resourceService, jobService, jobCreator, dialog);
  }

  delete(resource: Resource): void {
    super.delete(resource);
    if (!this.isCommitted(resource.id)) {
      this.deleteJobLocally(resource);
    }
  }

  onClicked(resource: Resource, args?: any): void {
    const status = this.resourceQuery.getCommitStatus(resource.id);
    this.handleClickAccordingToCommitState(status, resource, args);
  }

  tryToPersistJob(resource: Resource) {
    const job = this.resourceQuery.getJobFromResource(resource);
    if (!job) return;
    this.jobService.syncJob(resource.id, job, true);
  }

  private handleClickAccordingToCommitState(status: CommitState | undefined, resource: Resource, args: any) {
    if (ResourceQuery.isCommitInProgress(status)) return;
    this.handleUncommittedClick(status!, resource);
    this.handleCommittedClick(status!, resource, args);
  }

  private handleCommittedClick(status: CommitState, resource: Resource, args: any) {
    if (ResourceQuery.isCommitted(status)) {
      this.navigateToJobDetails(resource, args);
    }
  }

  private navigateToJobDetails(resource: Resource, queryParams: any) {
    this.router.navigate([spaceRouteNames.JOB_ADMIN, jobAdminRouteNames.JOB, resource.id], { queryParams }).then();
  }

  private handleUncommittedClick(status: CommitState, resource: Resource) {
    if (status === 'failed') {
      this.tryToPersistJob(resource);
    }
  }

  private isCommitted(resourceId: string): boolean {
    const status = this.resourceQuery.getCommitStatus(resourceId);
    return ResourceQuery.isCommitted(status);
  }

  private deleteJobLocally(resource: Resource) {
    this.jobService.deleteUncommittedJob(resource.id);
  }
}
