import { Component, Input, OnInit } from '@angular/core';
import { Job } from '@data/job/job.model';
import {
  AbstractControl,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Education } from '@core/constants/job/education';
import { TRAINEE_FILTER } from '@data/job/job.filter';
import { get } from 'lodash';
import { enumValues } from '@shared/util/enum.util';
import { MapperUtil } from '@shared/util/mapper.util';

function thresholdFilterValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const val = control.value;
    return val === false || typeof val === 'number' ? null : { thresholdFilterError: true };
  };
}

function validators(type: 'yes-no' | 'threshold'): any {
  switch (type) {
    case 'threshold':
      return [Validators.required, thresholdFilterValidator()];
  }
  return [];
}

@Component({
  selector: 'recrewt-requirements-job-data-page',
  templateUrl: './requirements-job-data-page.component.html',
  styleUrls: ['./requirements-job-data-page.component.scss'],
})
export class RequirementsJobDataPageComponent implements OnInit {
  @Input() template: Partial<Job> = {};

  frmRequirements!: UntypedFormGroup;

  readonly EDU_LEVEL = enumValues(Education);

  readonly FILTER = TRAINEE_FILTER;

  constructor(private ctrlContainer: FormGroupDirective, private formBuilder: UntypedFormBuilder) {}

  eduMapping = (id: number) => MapperUtil.decToStr(id, 'education');

  ngOnInit(): void {
    const tplMatchData = this.template?.matchData;
    const education = tplMatchData?.general?.educationLevel;
    const requirements = tplMatchData?.general?.requirements;
    const filter = this.formBuilder.group({});
    this.FILTER.forEach((f) =>
      filter.addControl(f.id, this.formBuilder.control(get(tplMatchData?.filter, f.id, false), validators(f.type))),
    );

    this.frmRequirements = this.formBuilder.group({
      filter,
      education: [education ?? 0, [Validators.required]],
      requirements: [requirements ?? []],
    });

    const parentForm = this.ctrlContainer.form;
    parentForm.addControl('requirements', this.frmRequirements);
  }

  isEduOptionActive(option: any): boolean {
    return option === this.frmRequirements.value.education;
  }

  selectEduOption(i: number): void {
    this.frmRequirements.controls.education?.setValue(i);
  }
}
