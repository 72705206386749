<h1 mat-dialog-title>{{ 'SUPPORT.FEEDBACK.headline' | translate }}</h1>
<form [formGroup]="form">
  <div fxLayout="column" fxLayoutAlign="start stretch" mat-dialog-content>
    <p>{{ 'SUPPORT.FEEDBACK.message' | translate }}</p>
    <mat-form-field appearance="outline">
      <mat-select formControlName="type" required>
        <mat-option *ngFor="let type of FeedbackType | fn : enumKeys" [value]="FeedbackType[type]">
          {{ 'SUPPORT.FEEDBACK.TYPE.' + type | translate }}
        </mat-option>
      </mat-select>
      <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
      <mat-label>{{ 'SUPPORT.FEEDBACK.type' | translate }}</mat-label>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <input formControlName="desc" matInput required />
      <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
      <mat-label>{{ 'SUPPORT.FEEDBACK.desc' | translate }}</mat-label>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <textarea cdkTextareaAutosize formControlName="additional" matAutosizeMaxRows="10" matInput></textarea>
      <mat-label>{{ 'SUPPORT.FEEDBACK.additional' | translate }}</mat-label>
    </mat-form-field>
  </div>
  <div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
    <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
    <button (click)="submit()" color="accent" mat-flat-button>
      {{ 'COMMON.send' | translate }}
    </button>
  </div>
</form>
