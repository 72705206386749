import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FairBoothState, FairBoothStore } from '@data/fair-booth/fair-booth.store';
import { FairBooth, PopulatedFairBooth } from '@data/fair-booth/fair-booth.model';
import { combineLatest } from 'rxjs';
import { Session } from '@data/session/session.model';
import { map } from 'rxjs/operators';
import { SessionQuery } from '@data/session/session.query';

@Injectable({ providedIn: 'root' })
export class FairBoothQuery extends QueryEntity<FairBoothState, FairBooth> {
  constructor(protected store: FairBoothStore, private sessionQuery: SessionQuery) {
    super(store);
  }

  selectPopulated(sessionIds?: string[]) {
    const sessions$ = sessionIds ? this.sessionQuery.selectMany(sessionIds) : this.sessionQuery.selectAll();
    const fairBooths$ = this.selectAll();
    return combineLatest([fairBooths$, sessions$]).pipe(
      map(([fairBooths, sessions]) => {
        return this.expandFairBoothSessions(fairBooths, sessions);
      }),
    );
  }

  selectByIdPopulated(id: string) {
    const sessions$ = this.sessionQuery.selectAll();
    const fairBooth$ = this.selectEntity(id);
    return combineLatest([fairBooth$, sessions$]).pipe(
      map(([fairBooth, sessions]) => {
        if (!fairBooth) {
          return null;
        }

        return this.expandFairBoothSessions([fairBooth], sessions)[0];
      }),
    );
  }

  private expandFairBoothSessions(fairBooths: FairBooth[], sessions: Session[]) {
    const populatedBooths: PopulatedFairBooth[] = [];
    for (let booth of fairBooths) {
      const session = sessions.find((s) => s.id === booth.session);
      if (!session) {
        continue;
      }
      populatedBooths.push({ ...booth, session });
    }
    return populatedBooths;
  }
}
