import { Directive, HostListener } from '@angular/core';
import { EditableComponent } from '@shared/components/editor/inplace-editable/doubleclick-editable/editable.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[doubleClickEditableKeyListener]',
})
export class DoubleClickEditableKeyListenerDirective {
  constructor(private editable: EditableComponent) {}

  @HostListener('keyup.enter')
  onEnter(): void {
    this.editable.toViewMode();
  }

  @HostListener('keydown.escape')
  onEscape(): void {
    this.editable.cancelEdit();
  }
}
