import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { appRouteNames } from '../../../app.routing.names';
import { verificationRouteNames } from '../../../modules/verification/verification.routing.names';

@Injectable({
  providedIn: 'root',
})
export class VerifiedGuard implements CanActivate {
  constructor(private auth0: AuthService, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.auth0.idTokenClaims$.pipe(
      filter((t) => !!t),
      map((t) => {
        if (t?.email_verified) {
          return true;
        } else {
          this.router.navigate([appRouteNames.VERIFICATION, verificationRouteNames.CONFIRM]).then();
          return false;
        }
      }),
      take(1),
    );
  }
}
