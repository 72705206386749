import { Injectable } from '@angular/core';
import { MapperUtil } from '@shared/util/mapper.util';
import { BENEFITS, BENEFITS_SELECTOR_CONFIG } from '@core/constants/job/benefits';
import { ChipSelectionCodecService } from '@lib/job-elements/services/chip-selection-codec.service';
import { ChipSelectionData } from '@lib/job-elements/model/chip-selection-data';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FRAME_FIELDS } from '@core/constants/job/frame-questions';
import { QuestionConfig } from '@shared/components/question-panel/card-deck-form/card-deck-form.component';

@Injectable({
  providedIn: 'root',
})
export class JobDataMapperService {
  constructor(private benefitsCodec: ChipSelectionCodecService, private formBuilder: UntypedFormBuilder) {}

  arrayToBinary(binArray: number[]): number | undefined {
    // eslint-disable-next-line no-bitwise
    return binArray?.reverse()?.reduce((res, x) => (res << 1) | x);
  }

  frameDataArrToBinary(frame: { frameValues: { [s: string]: { value: number } } }): number {
    if (!frame?.frameValues) {
      return 0;
    }
    const frameData = Object.values(frame.frameValues).map((fval: any) => fval.value);
    let binaryFrameData = 0;
    if (!frameData.includes(-1)) {
      binaryFrameData = this.arrayToBinary(frameData) ?? 0;
    }
    return binaryFrameData;
  }

  frameDataBinToArr(length: number, frame: number): number[] {
    const initial = new Array(length).fill(frame >= 0 ? 0 : -1);
    const frameVals = Array.from(frame >= 0 ? frame?.toString(2) : '')
      .reverse()
      .map(Number);
    for (let i = 0; i < frameVals.length; i++) {
      initial[i] = frameVals[i];
    }
    return initial;
  }

  getFrameDataQuestions(): QuestionConfig[] {
    const questionSet: QuestionConfig[] = [];
    for (const field of FRAME_FIELDS) {
      questionSet.push({
        title: `JOB_CREATOR.FRAME.PAGES.${field.name}.title`,
        truthyOption: `JOB_CREATOR.FRAME.PAGES.${field.name}.truthyOption`,
        falsyOption: `JOB_CREATOR.FRAME.PAGES.${field.name}.falsyOption`,
        showWhen: field.showWhen,
      });
    }
    return questionSet;
  }

  splitPredefinedAndCustomBenefits(benefits: { [key: string]: { predefined: string[]; custom: string[] } }): {
    general: { [p: string]: number };
    custom: { [p: string]: string[] };
  } {
    const general: { [key: string]: number } = {};
    const custom: { [key: string]: string[] } = {};
    for (const [cat, values] of Object.entries(benefits)) {
      general[cat] = MapperUtil.mapArray(values.predefined ?? [], false, BENEFITS[cat]);
      custom[cat] = values.custom;
    }
    return { general, custom };
  }

  initBenefitsFormGroup(predefined?: { [p: string]: number }, custom?: { [p: string]: string[] }): UntypedFormGroup {
    const benefits = this.formBuilder.group({});
    BENEFITS_SELECTOR_CONFIG.forEach((category) => {
      let benefitsCustom: string[] = [];
      let benefitsPredef: string[] = [];
      if (!!predefined) {
        const benefitsByCategory = predefined[category.groupName];
        // @ts-ignore
        benefitsPredef = MapperUtil.mapBinary(benefitsByCategory, BENEFITS[category.groupName]) ?? [];
      }
      if (!!custom) {
        benefitsCustom = custom[category.groupName] ?? [];
      }
      benefits.addControl(
        category.groupName,
        this.formBuilder.control({ predefined: benefitsPredef, custom: benefitsCustom }),
      );
    });
    return benefits;
  }

  encodeHighlightBenefits(
    favs: ChipSelectionData[],
    predefined: { [key: string]: string[] },
    amountPredefinedPerCategory: number[],
  ): number[] {
    return this.benefitsCodec.encodeChipSelection(favs, predefined, amountPredefinedPerCategory);
  }

  decodeHighlightBenefits(
    encodedFavs: number[],
    predefined: { [key: string]: string[] },
    selectedBenefits: string[][],
    amountPredefinedPerCategory: number[],
  ): ChipSelectionData[] {
    return this.benefitsCodec.decodeChipSelection(
      encodedFavs,
      predefined,
      selectedBenefits,
      amountPredefinedPerCategory,
    );
  }

  updateSalary(oldSalary: number[], newSalary: number[], duration: number): UntypedFormGroup {
    if (duration <= 0) {
      duration = 3;
    }
    const oldValues = Object.assign(oldSalary, []);
    const salaryData = Object.assign(newSalary, []);
    const salaryForm = this.formBuilder.group({});
    const years = duration;
    for (let y = 0; y < years; y++) {
      salaryForm.addControl(
        y.toString(),
        this.formBuilder.control(oldValues[y] ?? salaryData[y] ?? 0, [Validators.required, Validators.min(0)]),
      );
    }
    return salaryForm;
  }
}
