<recrewt-click-editable (cancel)="onCancel()" (update)="onUpdate()">
  <ng-template viewMode>
    <ng-template [ngIfElse]="defaultView" [ngIf]="type === 'yes-no'">
      <div fxLayoutAlign="center center">
        <mat-icon>{{ model ? 'check' : 'close' }}</mat-icon>
      </div>
    </ng-template>
    <ng-template #defaultView>
      {{ model.name | translate }}
    </ng-template>
  </ng-template>
  <ng-template editMode>
    <mat-radio-group (change)="onChange($event)" [(ngModel)]="model" color="primary" fxLayoutAlign="center center">
      <ng-container *ngFor="let radio of config">
        <mat-radio-button [value]="radio.value">{{ radio.name | translate }}</mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </ng-template>
</recrewt-click-editable>
