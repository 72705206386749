import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'recrewt-animated-binary-selection',
  templateUrl: './animated-binary-selection.component.html',
  styleUrls: ['./animated-binary-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AnimatedBinarySelectionComponent,
    },
  ],
})
export class AnimatedBinarySelectionComponent extends CustomFormControlComponent {
  selection?: boolean;

  @Output() selectionChanged = new EventEmitter<boolean>();

  @Input() truthyText = 'true';

  @Input() falsyText = 'false';

  @Input() valueAs: (value: boolean) => any = (value) => value;

  onSelectionChanged(value: boolean): void {
    this.selection = value;
    this.onChanged(this.valueAs ? this.valueAs(value) : value);
    this.selectionChanged.emit(value);
  }

  writeValue(obj: boolean): void {
    this.selection = obj;
  }
}
