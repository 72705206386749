<div #origin (click)="open(popup, origin)" fxLayoutAlign="center">
  <button
    [matBadgeHidden]="!activeAmount"
    [matBadge]="activeAmount"
    mat-raised-button
    matBadgeSize="small"
    type="button"
  >
    <mat-icon class="no-pad m-r-1">filter_list</mat-icon>
    {{ 'MENUS.RESOURCE_CREATE.filter' | translate }}
  </button>
</div>
<ng-template #popup>
  <div class="popup mat-elevation-z3">
    <ng-container>
      <recrewt-filter-group (apply)="onApply()" [filters]="filters"></recrewt-filter-group>
    </ng-container>
  </div>
</ng-template>
