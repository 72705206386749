<mat-menu #statusActions="matMenu">
  <ng-template let-item="target" matMenuContent>
    <ng-container>
      <button (click)="stateChange.emit([item, INTERVIEW])" *ngIf="strategy.showInterview(item)" mat-menu-item>
        <mat-icon fontIcon="fa-paper-plane" fontSet="fas"></mat-icon>
        <span>{{ 'MENUS.APPLICATION.interview' | translate }}</span>
      </button>
      <button (click)="stateChange.emit([item, ASSESSMENT])" *ngIf="strategy.showAssessment(item)" mat-menu-item>
        <mat-icon>fact_check</mat-icon>
        <span>{{ 'MENUS.APPLICATION.assessment' | translate }}</span>
      </button>
      <button (click)="stateChange.emit([item, OFFER])" *ngIf="strategy.showOffer(item)" mat-menu-item>
        <mat-icon>badge</mat-icon>
        <span>{{ 'MENUS.APPLICATION.offer' | translate }}</span>
      </button>
      <button (click)="stateChange.emit([item, REJECTED])" *ngIf="strategy.showDecline(item)" mat-menu-item>
        <mat-icon>not_interested</mat-icon>
        <span>{{ 'MENUS.APPLICATION.decline' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
