<h1 mat-dialog-title>{{ 'APPLICATIONS.DIALOG.STAGE.TITLE.' + stageKey | translate }}</h1>
<form (submit)="submit()" [formGroup]="form" fxLayout="column" fxLayoutGap="16px" mat-dialog-content>
  <p>{{ 'APPLICATIONS.DIALOG.STAGE.MESSAGE.' + stageKey | translate }}</p>
  <div fxLayout="row" fxLayoutGap="16px">
    <mat-form-field *ngIf="useDate" appearance="outline" fxFlex="40">
      <input [matDatepicker]="datepicker" formControlName="date" matInput required />
      <mat-datepicker-toggle [for]="datepicker" matSuffix></mat-datepicker-toggle>
      <mat-datepicker #datepicker startView="multi-year"></mat-datepicker>
      <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
      <mat-label>{{ 'APPLICATIONS.DIALOG.STAGE.DATE.' + stageKey | translate }}</mat-label>
    </mat-form-field>
    <mat-form-field *ngIf="useDate" appearance="outline" fxFlex="40">
      <input formControlName="time" matInput type="time" />
      <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
      <mat-label>{{ 'COMMON.time' | translate }}</mat-label>
    </mat-form-field>
  </div>
  <mat-form-field appearance="outline" fxFlex="grow">
    <textarea
      cdkTextareaAutosize
      class="keep-linebreaks"
      formControlName="message"
      matAutosizeMaxRows="10"
      matInput
      required
    ></textarea>
    <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
    <mat-label>{{ 'APPLICATIONS.DIALOG.STAGE.label_msg' | translate }}</mat-label>
  </mat-form-field>
</form>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  <button (click)="submit()" [disabled]="form.invalid" color="accent" mat-flat-button type="submit">
    {{ 'COMMON.send' | translate }}
  </button>
</div>
