import { AfterViewInit, Component, Input } from '@angular/core';

/**
 * A labelled circular progress indicator that also displays its value in a textual form.
 */
@Component({
  selector: 'recrewt-labelled-circle-progress',
  templateUrl: './labelled-circle-progress.component.html',
  styleUrls: ['./labelled-circle-progress.component.scss'],
})
export class LabelledCircleProgressComponent implements AfterViewInit {
  /**
   * The value/progress of the progress bar.
   */
  @Input() value = 0;

  /**
   * The amount by which the crud needs to be scaled up/down in order to be relative to the progress bars scale of 0 to 100.
   * Does not affect the textually displayed value.
   */
  @Input() scale = 1;

  /**
   * The label to display below the progress bar.
   */
  @Input() label = '';

  @Input() trailing = '';

  @Input() color: 'primary' | 'accent' = 'primary';

  displayValue = 0;

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.displayValue = this.value;
    }, 100);
  }
}
