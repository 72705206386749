import { Injectable } from '@angular/core';
import { DEFAULT_ACCENT, DEFAULT_ACCENT_FAIR, DEFAULT_PRIMARY, DEFAULT_PRIMARY_FAIR } from '@core/constants/app/theme';
import { Subject } from 'rxjs';
import { TinyColor } from '@ctrl/tinycolor';
import { ContextMode } from '@core/enums/app/context-mode';

export interface Color {
  name: string;
  hex: string;
  darkContrast: boolean;
}

function updateTheme(colors: Color[], theme: string): void {
  colors.forEach((color) => {
    document.documentElement.style.setProperty(`--theme-${theme}-${color.name}`, color.hex);
    document.documentElement.style.setProperty(
      `--theme-${theme}-contrast-${color.name}`,
      color.darkContrast && !(color.hex === DEFAULT_PRIMARY) ? 'rgba(0,0,0, 0.87)' : 'white',
    );
  });
  document.documentElement.style.setProperty(
    `--theme-on-${theme}`,
    colors[5].darkContrast && !(colors[5].hex === DEFAULT_PRIMARY) ? 'rgba(0,0,0, 0.87)' : 'white',
  );
  document.documentElement.style.setProperty(
    `--theme-on-${theme}-medium`,
    colors[5].darkContrast && !(colors[5].hex === DEFAULT_PRIMARY)
      ? 'rgba(0, 0, 0, 0.74)'
      : 'rgba(255, 255, 255, 0.74)',
  );
  document.documentElement.style.setProperty(
    `--theme-on-${theme}-low`,
    colors[5].darkContrast && !(colors[5].hex === DEFAULT_PRIMARY)
      ? 'rgba(0, 0, 0, 0.38)'
      : 'rgba(255, 255, 255, 0.38)',
  );
}

function getColorObject(color: TinyColor, name: string): Color {
  let c = color.clone();
  if (c.getBrightness() > 245) {
    c = c.darken(5);
  }
  return {
    name,
    hex: c.toHexString(),
    darkContrast: c.isLight(),
  };
}

function computeColors(hex: string): Color[] {
  return [
    getColorObject(new TinyColor(hex).lighten(52), '50'),
    getColorObject(new TinyColor(hex).lighten(37), '100'),
    getColorObject(new TinyColor(hex).lighten(26), '200'),
    getColorObject(new TinyColor(hex).lighten(12), '300'),
    getColorObject(new TinyColor(hex).lighten(6), '400'),
    getColorObject(new TinyColor(hex), '500'),
    getColorObject(new TinyColor(hex).darken(6), '600'),
    getColorObject(new TinyColor(hex).darken(12), '700'),
    getColorObject(new TinyColor(hex).darken(18), '800'),
    getColorObject(new TinyColor(hex).darken(24), '900'),
    getColorObject(new TinyColor(hex).lighten(50).saturate(30), 'A100'),
    getColorObject(new TinyColor(hex).lighten(30).saturate(30), 'A200'),
    getColorObject(new TinyColor(hex).lighten(10).saturate(15), 'A400'),
    getColorObject(new TinyColor(hex).lighten(5).saturate(5), 'A700'),
  ];
}

@Injectable({
  providedIn: 'root',
})
export class DynamicThemeService {
  primaryColor = DEFAULT_PRIMARY;

  accentColor = DEFAULT_ACCENT;

  primaryColorPalette: Color[] = [];

  accentColorPalette: Color[] = [];

  contrastChanged = new Subject<boolean>();

  constructor() {
    this.savePrimaryColor();
    this.saveAccentColor();
  }

  savePrimaryColor(): void {
    const hasDarkContrastOld = this.primaryColorPalette[8]?.darkContrast ?? false;
    this.primaryColorPalette = computeColors(this.primaryColor);
    updateTheme(this.primaryColorPalette, 'primary');
    const hasDarkContrastNew = this.primaryColorPalette[8].darkContrast;
    if (hasDarkContrastOld !== hasDarkContrastNew) {
      this.contrastChanged.next(hasDarkContrastNew);
    }
  }

  saveAccentColor(): void {
    this.accentColorPalette = computeColors(this.accentColor);
    updateTheme(this.accentColorPalette, 'accent');
  }

  reset(mode?: ContextMode): void {
    if (!mode) {
      this.primaryColor = DEFAULT_PRIMARY;
      this.accentColor = DEFAULT_ACCENT;
    } else if (mode === ContextMode.FAIR) {
      this.primaryColor = DEFAULT_PRIMARY_FAIR;
      this.accentColor = DEFAULT_ACCENT_FAIR;
    }
    this.savePrimaryColor();
    this.saveAccentColor();
  }
}
