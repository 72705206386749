import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { JobCreatorWizardComponent } from './job-creator-wizard/job-creator-wizard.component';
import { JobCreatorFinishedPayload, JobDraft } from '@data/job/job.model';

@Injectable({
  providedIn: 'root',
})
export class JobCreatorWizardService {
  dialogRef?: MatDialogRef<JobCreatorWizardComponent>;

  constructor(private dialog: MatDialog) {}

  public open(template?: Partial<JobDraft>): void {
    this.dialogRef = this.dialog.open(JobCreatorWizardComponent, {
      height: '90vh',
      data: template,
      closeOnNavigation: false,
    });
  }

  public confirmed(): Observable<JobCreatorFinishedPayload> {
    return (
      this.dialogRef?.afterClosed().pipe(
        take(1),
        map((res) => {
          return res;
        }),
      ) ?? of(null)
    );
  }
}
