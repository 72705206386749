import { Component, Input } from '@angular/core';
import { ControlContainer, UntypedFormGroup, NgForm } from '@angular/forms';

@Component({
  selector: 'recrewt-input-slider',
  templateUrl: './input-slider.component.html',
  styleUrls: ['./input-slider.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class InputSliderComponent {
  @Input() parentFormGroup?: UntypedFormGroup;

  @Input() formCtrlName?: string;

  @Input() min = 0;

  @Input() max = 100;

  @Input() require = false;

  @Input() showThumb = true;

  @Input() step = 1;
}
