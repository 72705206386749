import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'recrewt-slider-editor',
  templateUrl: './slider-editor.component.html',
  styleUrls: ['./slider-editor.component.scss'],
})
export class SliderEditorComponent implements OnInit {
  @Input() value = 0;

  @Output() update = new EventEmitter<number>();

  @Output() cancel = new EventEmitter<any>();

  newValue = 0;

  private dirty = false;

  ngOnInit(): void {
    this.newValue = this.value;
  }

  onUpdate(): void {
    if (this.dirty) {
      this.update.emit(this.newValue);
      this.dirty = false;
    }
  }

  onCancel(): void {
    this.newValue = this.value;
    this.dirty = false;
  }

  onValueChanged(): void {
    this.dirty = true;
  }
}
