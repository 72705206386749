import { MatchStatus } from '@core/enums/data/match-status';
import { ContextMode } from '@core/enums/app/context-mode';
import { LookingFor } from '@core/constants/match/looking-for';

export interface MatchUI {
  id: string;
}

export interface Match {
  _id: string;
  user: {
    id: string;
    name: string;
    age?: string;
    education?: number;
    settings: number;
  };
  jobRef: string;
  companyScore: number;
  jobScore: number;
  score: number;
  status: MatchStatus;
  contacted?: boolean;
  mode: ContextMode;
  sessionId: string | undefined;
  creationTime: Date | undefined;
  isWebMatch: boolean;
  lookingFor: LookingFor;
}

const SETTING_ALLOW_CONTACT = 0b1;
const SETTING_FULLNAME = 0b10;

export function showFullName(match: Match): boolean {
  return !!((match.user?.settings ?? 0) & SETTING_FULLNAME);
}

export function allowContactSettingSet(match: Match): boolean {
  return !!((match.user?.settings ?? 0) & SETTING_ALLOW_CONTACT);
}

export function isContactAllowed(match: Match): boolean {
  return allowContactSettingSet(match) && [2, 3, 5].includes(match.status);
}
