<div class="p-h-6">
  <form [formGroup]="frmRequirements">
    <div>
      <h4>{{ 'JOB_CREATOR.REQUIREMENTS.HEADLINES.education' | translate }}*</h4>
      <mat-radio-group color="primary" formControlName="education" required>
        <div fxLayout="row wrap">
          <ng-container *ngFor="let edu of EDU_LEVEL">
            <mat-radio-button
              (click)="selectEduOption(edu)"
              [class.active]="isEduOptionActive(edu)"
              [value]="edu"
              class="form-field-box m-h-1"
              fxFlex="1 1 auto"
              ><div class="ellipsize width-match-parent">{{ edu | fn : eduMapping | translate }}</div></mat-radio-button
            >
          </ng-container>
        </div>
      </mat-radio-group>
    </div>
    <div class="m-t-2">
      <recrewt-extendable-category-list
        formControlName="requirements"
        headline="JOB_CREATOR.REQUIREMENTS.HEADLINES.requirements"
      >
      </recrewt-extendable-category-list>
    </div>
  </form>
</div>
