import { Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FeedbackType } from '@core/enums/support/feedback-type';
import { HttpClient } from '@angular/common/http';
import { UserQuery } from '@data/user/user.query';

export interface Feedback {
  type: FeedbackType;
  desc: string;
  email?: string;
  additional?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SupportService {
  private url = `${environment.apiUrl}/support`;

  constructor(private http: HttpClient, private userQuery: UserQuery) {}

  sendFeedback(feedback: Feedback) {
    feedback.email = this.userQuery.email();
    return this.http.post(`${this.url}/feedback`, feedback);
  }
}
