<div class="p-1">
  <mat-accordion>
    <ng-container *ngFor="let item of items; index as i; trackBy: trackByTitle">
      <mat-expansion-panel
        (click)="onItemClicked(i)"
        (opened)="step = i"
        [class.flat]="flat"
        [class.mat-elevation-z0]="flat"
        [expanded]="step === i"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ title(item) | translate }}
          </mat-panel-title>
          <mat-panel-description>
            {{ item?.description | translate }}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-template [ngIf]="!itemTpl">
          <div>{{ item?.value | translate }}</div>
        </ng-template>
        <ng-template *ngTemplateOutlet="itemTpl; context: { $implicit: item?.value }"></ng-template>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
