import { Injectable } from '@angular/core';
import { map, switchMap, tap } from 'rxjs/operators';
import { createEffect, ofType } from '@ngneat/effects';
import { ApplicationService } from '@data/application/application.service';
import { ApplicationActions } from '@data/application/application.actions';
import { ApplicationStore } from '@data/application/application.store';
import { ChatActions } from '@data/chat/chat.actions';
import { createChatMessage } from '@data/chat/chat.model';
import { compare } from '@shared/util/array.util';
import { ApplicationMetaStatus } from '@core/enums/data/application-status';

@Injectable({ providedIn: 'root' })
export class ApplicationEffects {
  loadApplications = createEffect((actions) =>
    actions.pipe(
      ofType(ApplicationActions.load),
      switchMap(() => this.applicationService.get()),
      tap((applications) => {
        if (!applications) return;

        const sorted = applications.sort((a, b) => compare(a.created?.valueOf(), b.created?.valueOf(), true));
        this.applicationStore.set(sorted);
      }),
    ),
  );

  changeStatus = createEffect((actions) =>
    actions.pipe(
      ofType(ApplicationActions.updateStatus),
      switchMap(({ aid, status }) =>
        this.applicationService.updateStatus(aid, status).pipe(
          tap(() => {
            if (status === ApplicationMetaStatus.DELETED) {
              this.applicationStore.remove(aid);
            } else {
              this.applicationStore.update(aid, { status });
            }
          }),
        ),
      ),
    ),
  );

  changeStage = createEffect(
    (actions) =>
      actions.pipe(
        ofType(ApplicationActions.updateStage),
        switchMap(({ application, stage }) => this.applicationService.updateStage(application, stage)),
        tap(({ updatedApplication }) => this.applicationStore.update(updatedApplication.id, updatedApplication)),
        map(({ updatedApplication, message, cid }) => {
          if (!message) return;
          const chatMessage = createChatMessage(message, cid, updatedApplication.userId, updatedApplication.jobRef);
          return ChatActions.add({ message: chatMessage });
        }),
      ),
    { dispatch: true },
  );

  download = createEffect((actions) =>
    actions.pipe(
      ofType(ApplicationActions.download),
      switchMap(({ aid, userId, onload }) => this.applicationService.getDownloadUrl(aid, userId, onload)),
    ),
  );

  constructor(private applicationService: ApplicationService, private applicationStore: ApplicationStore) {}
}
