import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SpaceService } from '@data/space/space.service';
import { ContextMode } from '@core/enums/app/context-mode';
import { SpaceQuery } from '@data/space/space.query';
import { SpaceRouter } from '@core/services/space-router/space-router.service';
import { spaceRouteNames } from '../../../modules/space/space.routing.names';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'recrewt-mode-switch',
  templateUrl: './mode-switch.component.html',
  styleUrls: ['./mode-switch.component.scss'],
})
export class ModeSwitchComponent implements OnInit {
  isChecked = false;

  constructor(
    private spaceService: SpaceService,
    private spaceQuery: SpaceQuery,
    private spaceRouter: SpaceRouter,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.spaceQuery
      .selectActiveMetadata()
      .pipe(untilDestroyed(this))
      .subscribe((meta) => {
        this.isChecked = meta.mode === ContextMode.FAIR;
        this.cdr.detectChanges();
      });
  }

  onChange() {
    this.isChecked = !this.isChecked;
    const mode = this.isChecked ? ContextMode.FAIR : ContextMode.DEFAULT;
    this.spaceService.toggleMode(mode);
    this.spaceRouter.navigate([spaceRouteNames.GETTING_STARTED], { replaceUrl: true }).then();
  }
}
