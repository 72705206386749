<h1 mat-dialog-title>{{ 'BRANCHES.ADD_JOB.title' | translate }}</h1>
<div mat-dialog-content>
  <p class="info-box">{{ 'BRANCHES.ADD_JOB.message' | translate }}</p>
  <recrewt-branches-table
    (action)="onSelectChange($event)"
    [data$]="branches$"
    [isSelectTable]="!submitLoading"
    [loading$]="loading$"
    [showActions]="false"
  ></recrewt-branches-table>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" [loading]="submitLoading" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  <button (click)="submit()" [loading]="submitLoading" color="accent" mat-flat-button type="submit">
    {{ 'COMMON.create' | translate }}
  </button>
</div>
