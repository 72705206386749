import { Component, Inject, OnInit } from '@angular/core';
import { FeedbackType } from '@core/enums/support/feedback-type';
import { enumKeys } from '@shared/util/enum.util';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Feedback } from '@core/services/http/support/support.service';

@Component({
  selector: 'recrewt-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss'],
})
export class FeedbackDialogComponent implements OnInit {
  readonly FeedbackType = FeedbackType;

  readonly enumKeys = enumKeys;

  form!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<FeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Feedback,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const { value, valid } = this.form;
    if (valid) {
      this.dialogRef.close(value);
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      type: [this.data?.type, [Validators.required]],
      desc: [this.data?.desc, [Validators.required]],
      additional: [this.data?.additional],
    });
  }
}
