import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'recrewt-salary-bars',
  templateUrl: './salary-bars.component.html',
  styleUrls: ['./salary-bars.component.scss', '../../mobile-theme.scss'],
})
export class SalaryBarsComponent implements OnInit {
  @Input() bars: number[] = [];

  maxBar = 0;

  maxHeight = 100;

  ngOnInit(): void {
    this.maxBar = Math.max(...this.bars);
  }
}
