import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DEFAULT_PRIMARY, DEFAULT_TERTIARY } from '@core/constants/app/theme';

@Component({
  template: '',
})
export class MobilePreviewComponent implements OnInit, OnChanges, OnDestroy {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _theme?: { accent?: string; primary?: string } = {
    primary: DEFAULT_PRIMARY,
    accent: DEFAULT_TERTIARY,
  };

  get theme(): { accent?: string; primary?: string } | undefined {
    return this._theme;
  }

  @Input() set theme(newTheme: { accent?: string; primary?: string } | undefined) {
    this._theme = newTheme;
    this.updateTheme();
  }

  ngOnInit(): void {
    this.updateTheme();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const theme = changes.theme.currentValue;
    if (!theme) {
      return;
    }
    this.updateTheme();
  }

  ngOnDestroy(): void {
    document.documentElement.style.removeProperty('--mobile-primary');
    document.documentElement.style.removeProperty('--mobile-accent');
  }

  private updateTheme(): void {
    document.documentElement.style.setProperty('--mobile-primary', this.theme?.primary ?? DEFAULT_PRIMARY);
    document.documentElement.style.setProperty('--mobile-accent', this.theme?.accent ?? DEFAULT_TERTIARY);
  }
}
