<div>
  <div #origin (click)="open(popup, origin)">
    <button mat-icon-button>
      <mat-icon
        [matBadgeHidden]="(unreadAmount$ | async) < 1"
        [matBadge]="unreadAmount$ | async"
        class="on-primary"
        matBadgeColor="warn"
        matBadgeSize="small"
        >notifications
      </mat-icon>
    </button>
  </div>
  <ng-template #popup>
    <div class="popup mat-elevation-z3">
      <ng-container>
        <recrewt-notification-list
          (markAllAsRead)="markAllAsRead()"
          (markOneAsRead)="markOneAsRead($event)"
          [loading]="loading$ | async"
          [notifications]="notifications$ | async"
        ></recrewt-notification-list>
      </ng-container>
    </div>
  </ng-template>
</div>
