<recrewt-editable-list-group
  (chooseClicked)="fileInput.click()"
  (dropped)="handleDrop($event)"
  (removed)="removeFiles($event)"
  [additionalActions]="additionalActions"
  [error]="error"
  [itemTpl]="itemTpl"
  [items]="selectedFiles$ | async"
  allowDnD="true"
  emptyHint="FILE_UPLOAD.hint"
></recrewt-editable-list-group>

<ng-template #itemTpl let-file="item">
  <div fxFlex="row" fxLayoutAlign="start center">
    <img *ngIf="file | fn: fileIcon as icon" [src]="icon" alt="File icon" mat-list-icon />
    <p class="m-l-1 no-pad single-line" style="display: inline">{{ file.name }}</p>
    <mat-progress-bar
      *ngIf="progress.get(file.name) > 0 && progress.get(file.name) < 100"
      [value]="progress.get(file.name) ?? 100"
    ></mat-progress-bar>
  </div>
</ng-template>

<ng-template #additionalActions let-selection>
  <button (click)="download(selection)" class="m-2" color="accent" mat-flat-button>
    {{ 'COMMON.download' | translate }}
  </button>
</ng-template>

<input
  #fileInput
  (change)="onFileSelected($event)"
  (click)="fileInput.value = null"
  [accept]="accept.join(',')"
  [multiple]="multiple"
  style="display: none"
  type="file"
/>
