import { REQUIREMENTS } from '@core/constants/job/requirements';
import { PERKS } from '@core/constants/job/benefits';
import { EDUCATION_LEVEL } from '@core/constants/job/education';
import { BRANCHES } from '@core/constants/job/branches';
import { TOPICS } from '@core/constants/company/topics';
import { COMPANY_SIZE, COMPANY_SIZE_AMOUNT } from '@core/constants/company/size';
import { WORK_MODELS } from '@core/constants/job/work-model';

export class MapperUtil {
  static readonly OTHERS_FLAG = 0b10000000;

  public static binToArr(value: number, mapping: ArrayMapping): string[] {
    switch (mapping) {
      case 'perks':
        return MapperUtil.mapBinary(value, PERKS);
      case 'topics':
        return MapperUtil.mapBinary(value, TOPICS);
      case 'requirements':
        return MapperUtil.mapBinary(value, REQUIREMENTS);
      case 'branches':
        return MapperUtil.mapBinary(value, BRANCHES);
    }
  }

  public static decToStr(value: number, mapping: CardinalMapping): string | null {
    if (value === null || value === undefined) {
      return null;
    }
    switch (mapping) {
      case 'education':
        return MapperUtil.mapDecimal(value, EDUCATION_LEVEL);
      case 'branch':
        return MapperUtil.mapDecimal(value, BRANCHES);
      case 'company-size':
        return MapperUtil.mapDecimal(value, COMPANY_SIZE);
      case 'company-size-nr':
        return MapperUtil.mapDecimal(value, COMPANY_SIZE_AMOUNT);
      case 'work-model':
        return MapperUtil.mapDecimal(value, WORK_MODELS);
      case 'requirements':
        return MapperUtil.mapDecimal(value, REQUIREMENTS);
    }
  }

  public static arrToBin(value: string[], othersSet: boolean, mapping: ArrayMapping): number {
    switch (mapping) {
      case 'perks':
        return MapperUtil.mapArray(value, othersSet, PERKS);
      case 'requirements':
        return MapperUtil.mapArray(value, othersSet, REQUIREMENTS);
      case 'topics':
        return MapperUtil.mapArray(value, false, TOPICS);
      case 'branches':
        return MapperUtil.mapArray(value, false, BRANCHES);
    }
  }

  public static strToDec(value: string, mapping: CardinalMapping): number {
    switch (mapping) {
      case 'education':
        return MapperUtil.mapString(value, EDUCATION_LEVEL);
      case 'branch':
        return MapperUtil.mapString(value, BRANCHES);
      case 'company-size':
        return MapperUtil.mapString(value, COMPANY_SIZE);
      case 'company-size-nr':
        return MapperUtil.mapString(value, COMPANY_SIZE_AMOUNT);
      case 'work-model':
        return MapperUtil.mapString(value, WORK_MODELS);
      case 'requirements':
        return MapperUtil.mapString(value, REQUIREMENTS);
    }
  }

  public static mapBinary(value: number, dataSource: string[]): string[] {
    if (!value) {
      return [];
    }
    const values = [];
    let index = 0;
    while (value > 0 && index < dataSource.length) {
      if (value % 2 !== 0) {
        values.push(dataSource[index]);
      }
      index++;
      value >>= 1;
    }
    return values;
  }

  public static mapArray(values: string[], othersSet: boolean, dataSource: string[]): number {
    let mapping = 0;
    for (const value of values) {
      const idx = dataSource.indexOf(value);
      if (idx >= 0) {
        mapping |= 0b1 << idx;
      }
    }
    if (othersSet) {
      mapping |= this.OTHERS_FLAG;
    }
    return mapping;
  }

  private static mapDecimal(value: number, dataSource: string[]): string {
    if (value < 0 || value >= dataSource.length) {
      throw new Error(`Invalid Mapping ${value} for [${dataSource[0]}, ...]`);
    }
    return dataSource[value];
  }

  private static mapString(value: string, dataSource: string[]): number {
    const mapping = dataSource.indexOf(value);
    if (mapping < 0) {
      return 0;
    }
    return mapping;
  }
}

export type CardinalMapping =
  | 'education'
  | 'requirements'
  | 'branch'
  | 'company-size'
  | 'company-size-nr'
  | 'work-model';

export type ArrayMapping = 'requirements' | 'perks' | 'topics' | 'branches';
