import { Collaborator, CollaboratorData, createCollaborator } from '@data/collaborator/collaborator.model';
import { CompanySite } from '@data/company/company.model';
import { ContextMode } from '@core/enums/app/context-mode';

export type Role = 'admin' | 'collaborator';
export type CollabStatus = 'active' | 'pending';

export type SpaceContext = {
  space: SpaceInput;
  branches: SpaceInput[];
  parent: SpaceInput | null;
  siblings: SpaceInput[];
};

export type NormalizedSpaceContext = { space: Space; branches: Space[]; parent: Space | null; siblings: Space[] };

export type NormalizedSpaceData = NormalizedSpaceContext & { collaborators: Collaborator[] };

export interface SpaceUI {
  id: string;
  applyTheme: boolean;
  mode: ContextMode;
}

export interface SpaceInput {
  id: string;
  name: string;
  companyRef: string;
  ownerRef: string;
  collaborators: CollaboratorData[];
  usedStorageSize: number;
  onboarded: boolean;
  parentRef?: string;
  customerNr: string;
  branched: boolean;
}

export interface Space {
  id: string;
  name: string;
  companyRef: string;
  ownerRef: string;
  collaborators: string[];
  usedStorageSize: number;
  onboarded: boolean;
  parentRef?: string;
  customerNr: string;
  tags?: string[];
  branched: boolean;
}

export interface CollaboratorDTO {
  email: string;
  role: Role;
  advanced?: boolean;
}

export interface SpaceDTO {
  name?: string;
  collaborators?: CollaboratorDTO[];
  sites?: CompanySite[];
  parentRef?: string;
  copyProfile?: boolean;
  tags?: string[];
  branched?: boolean;
}

export function normalizeOne(space: SpaceInput): { space: Space; collaborators: Collaborator[] } {
  const collaborators = space.collaborators.map((it) => createCollaborator(it, space.id));
  return {
    space: {
      ...space,
      collaborators: collaborators.filter((it) => !!it).map((it) => it.id?.toString()!),
    },
    collaborators,
  };
}

export function normalize(space: SpaceContext): NormalizedSpaceData {
  const collaborators = space.space.collaborators.map((it) => createCollaborator(it, space.space.id));
  const cids = collaborators.filter((it) => !!it).map((it) => it.id?.toString()!);
  return {
    space: {
      ...space.space,
      collaborators: cids,
    },
    branches: space.branches.map((it) => ({
      ...it,
      collaborators: cids,
    })),
    siblings: space.siblings.map((it) => ({
      ...it,
      collaborators: cids,
    })),
    parent: space.parent && {
      ...space.parent,
      collaborators: cids,
    },
    collaborators,
  };
}

export function normalizeAll(spaces: SpaceInput[]): { spaces: Space[]; collaborators: Collaborator[] } {
  const normSpaces = spaces.map((space) => normalizeOne(space));
  const flat: any = { spaces: [], collaborators: [] };
  for (const data of normSpaces) {
    flat.spaces.push(data.space);
    flat.collaborators.push(...data.collaborators);
  }
  return flat;
}
