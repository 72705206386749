<p *ngIf="title" class="mat-caption m-b-1 text-weight-bold">{{ title | translate }}</p>
<div
  (colorPickerChange)="updateColor($event); onChange($event)"
  [(colorPicker)]="color"
  (colorPickerClose)="onClosed($event)"
  [style.background]="color"
  class="p-4 b-rad-1"
  cpOutputFormat="hex"
></div>
<p class="mat-caption on-surface-medium text-align-center m-t-1">{{ color }}</p>
