import { Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { bubbleGrowAnimaiton } from '@shared/animation/grow-shrink';

@Component({
  selector: 'recrewt-extended-fab',
  templateUrl: './extended-fab.component.html',
  styleUrls: ['./extended-fab.component.scss'],
  animations: [bubbleGrowAnimaiton],
})
export class ExtendedFabComponent {
  @Input() icon = 'arrow_forward';

  @Input() text = '';

  @Input() color: ThemePalette = 'accent';

  @Input() show = false;

  @Input() disabled = false;

  @Input() position: 'left' | 'right' = 'right';

  @Input() iconPosition: 'left' | 'right' = 'right';
}
