import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageState, MessageStore } from '@data/message/message.store';
import { environment } from '../../../environments/environment';
import { MessageQuery } from '@data/message/message.query';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private url = `${environment.apiUrl}/msg`;

  constructor(private http: HttpClient, private msgStore: MessageStore, private msgQuery: MessageQuery) {}

  getMessage(): void {
    this.http.get<MessageState>(this.url).subscribe((msg) => {
      if (msg.key !== this.msgQuery.getValue()?.key) {
        this.msgStore.update({ ...msg, hidden: false });
      }
      this.msgStore.setLoading(false);
    });
  }

  hideMessage(): void {
    this.msgStore.update({ hidden: true });
  }
}
