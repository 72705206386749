import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Job } from '@data/job/job.model';

@Component({
  selector: 'recrewt-add-job-for-start-years-dialog',
  templateUrl: './add-job-for-start-years-dialog.component.html',
  styleUrls: ['./add-job-for-start-years-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddJobForStartYearsDialogComponent implements OnInit {
  startYearOptions: number[] = [];

  private selectedYears: number[] = [];

  constructor(
    private dialogRef: MatDialogRef<AddJobForStartYearsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private job: Job,
  ) {}

  ngOnInit(): void {
    this.startYearOptions = this.getYearOptions();
  }

  onYearToggled(year: number, add: boolean) {
    if (add) {
      this.selectedYears.push(year);
    } else {
      this.selectedYears = this.selectedYears.filter((y) => y !== year);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    this.dialogRef.close(this.selectedYears);
  }

  private getYearOptions() {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 4 }, (_, i) => currentYear + i);
    return years.filter((year) => year !== this.job.startDate?.getFullYear());
  }
}
