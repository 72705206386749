import { Component } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AbstractEditableComponent } from '@shared/components/editor/inplace-editable/abstract-editable.component';

@Component({
  selector: 'recrewt-on-click-confirm-with-button-editable',
  templateUrl: './on-click-confirm-with-button-editable.component.html',
  styleUrls: ['./on-click-confirm-with-button-editable.component.scss'],
})
export class OnClickConfirmWithButtonEditableComponent extends AbstractEditableComponent {
  confirmed(): void {
    this.editMode$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.update.emit();
      this.mode = 'view';
    });
  }

  protected viewModeHandler(): void {
    fromEvent(document, 'click')
      .pipe(
        filter(({ target }) => this.element.contains(target)),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.mode = 'edit';
        this.editMode.next(true);
      });
  }

  protected editModeHandler(): void {}
}
