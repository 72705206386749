import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ResourceStore } from '@data/resource/resource.store';
import { Resource } from '@data/resource/resource.model';
import { catchError, takeUntil } from 'rxjs/operators';
import { ResourceQuery } from '@data/resource/resource.query';
import { JobStore } from '@data/job/job.store';
import { Job } from '@data/job/job.model';
import { Observable, of } from 'rxjs';
import { NotificationService } from '@core/services/notification/notification.service';
import { DRAFTS_STORAGE_KEY, RES_UI_STORAGE_KEY } from '@core/constants/system/storage-key';
import { SpaceQuery } from '@data/space/space.query';
import { Space } from '@data/space/space.model';
import { isEmpty } from 'lodash';
import { SERVICE_LOCAL_STORAGE } from '@core/constants/system/service-providers';
import { IStorageService } from '@core/services/storage/storage.service';
import { GA_EVENT_ID, GoogleAnalyticsService } from '@core/services/ga/google-analytics.service';
import { StopSubService } from '@core/services/stop-sub/stop-sub.service';
import { LocalJobDatabase } from '@data/job/local-job-database.service';
import { JobService } from '@data/job/job.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  private url = `${environment.apiUrl}/resources`;

  constructor(
    private http: HttpClient,
    private spaceQuery: SpaceQuery,
    private resourceStore: ResourceStore,
    private jobStore: JobStore,
    private jobService: JobService,
    private localJobDb: LocalJobDatabase,
    private resourceQuery: ResourceQuery,
    private notify: NotificationService,
    private stopService: StopSubService,
    private ga: GoogleAnalyticsService,
    @Inject(SERVICE_LOCAL_STORAGE) private localStorage: IStorageService,
  ) {}

  public get(): void {
    if (this.resourceQuery.getHasCache()) return;
    this.spaceQuery.getActiveWhenReady().subscribe((space) => {
      if (!space) return;
      this.loadResourcesForSpace(space);
    });
  }

  public update(resourceId: string, changes: Partial<Resource>): Observable<any> | null {
    if (isEmpty(changes)) return null;
    const sid = this.spaceQuery.getActiveId();
    return this.http.put(`${this.url}/${resourceId}`, { resource: changes, sid }).pipe(
      catchError((err) => {
        this.notify.error('SNACKBAR.JOB.ERROR.update');
        return of(err);
      }),
    );
  }

  public delete(resourceId: string): void {
    const cid = this.spaceQuery.getActive()?.companyRef;
    this.http.delete<{ deleted: string[]; children: string[] }>(`${this.url}/${cid}/${resourceId}`).subscribe(
      (deleted) => {
        if (!deleted) return;
        const deletedIds = deleted.deleted;
        deletedIds?.forEach((id) => {
          const res = this.resourceQuery.getEntity(id);
          this.ga.eventEmitter(GA_EVENT_ID.job_deleted, 'job', GA_EVENT_ID.job_deleted);
          this.jobStore.remove(res?.jobRef as string);
        });
        this.resourceStore.remove(deletedIds);
        this.localStorage.removeManyFromArray(RES_UI_STORAGE_KEY, deletedIds);
        this.notify.success('SNACKBAR.JOB.SUCCESS.delete');
      },
      () => {
        this.notify.error('SNACKBAR.JOB.ERROR.delete');
      },
    );
  }

  private loadResourcesForSpace(space: Space): void {
    if (!space?.companyRef) return;
    this.http
      .get<{ resources: Resource[]; jobs: Job[] }>(`${this.url}/company/${space?.companyRef}`)
      .pipe(takeUntil(this.stopService.stopped()))
      .subscribe(
        (resData) => {
          this.resourceStore.set(resData.resources);
          this.jobStore.set(resData.jobs);
          this.jobService.syncUncommitted();
        },
        () => {
          this.notify.error('SNACKBAR.JOB.ERROR.load');
        },
        () => {
          this.localJobDb.loadMetadata();
          this.localJobDb.loadLocal(DRAFTS_STORAGE_KEY, 'draft');
        },
      );
  }
}
