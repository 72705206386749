import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { Space, SpaceUI } from './space.model';

export interface SpaceState extends EntityState<Space>, ActiveState {}

export interface SpaceUIState extends EntityState<SpaceUI> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'space', resettable: true })
export class SpaceStore extends EntityStore<SpaceState, Space, string> {
  ui!: EntityUIStore<SpaceUIState, SpaceUI>;

  constructor() {
    super();
    this.createUIStore();
  }
}
