import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface QrCodeDialogData {
  url: string;
  title: string;
  message: string;
}

@Component({
  selector: 'recrewt-qrcode-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['./qr-code-dialog.component.scss'],
})
export class QrCodeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<QrCodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: QrCodeDialogData,
  ) {}

  noActionClicked(): void {
    this.dialogRef.close(null);
  }

  saveAsImage(parent: any) {
    let parentElement = parent.qrcElement.nativeElement.querySelector('canvas').toDataURL('image/png');

    if (parentElement) {
      let blobData = this.convertBase64ToBlob(parentElement);
      const blob = new Blob([blobData], { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'QRCode.png';
      link.click();
    }
  }

  copyUrl() {
    navigator.clipboard.writeText(this.data.url).then();
  }

  private convertBase64ToBlob(Base64Image: string) {
    const parts = Base64Image.split(';base64,');
    const imageType = parts[0].split(':')[1];
    const decodedData = window.atob(parts[1]);
    const uInt8Array = new Uint8Array(decodedData.length);
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: imageType });
  }
}
