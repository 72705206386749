import { Injectable } from '@angular/core';
import { QueryEntity, SelectAllOptionsB } from '@datorama/akita';
import { JobState, JobStore } from './job.store';
import { Job } from '@data/job/job.model';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ContextMode } from '@core/enums/app/context-mode';

@Injectable({ providedIn: 'root' })
export class JobQuery extends QueryEntity<JobState, Job> {
  constructor(protected store: JobStore) {
    super(store);
  }

  selectNotArchivedOrTemplate(mode$: Observable<ContextMode>) {
    return this.selectAllForMode(mode$, {
      filterBy: (job: Job) =>
        job.status !== 'template' && job.status !== 'archived' && job.status !== 'creation_paused',
    });
  }

  selectAllForMode(mode$: Observable<ContextMode>, options?: Partial<SelectAllOptionsB<Job>>) {
    return mode$.pipe(
      switchMap((mode) =>
        this.selectAll({
          ...options,
          filterBy: (s) => s.mode === mode && (!options?.filterBy || (options.filterBy as any)(s)),
        }),
      ),
    );
  }

  hasTemplate(jobId: string): boolean {
    return this.hasEntity((entity) => entity.templateId === jobId);
  }

  selectHasTemplate(jobId: string): Observable<boolean> {
    return this.selectCount((entity) => entity.templateId === jobId).pipe(map((it) => it > 0));
  }
}
