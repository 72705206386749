<div
  [class.light-bg]="notification?.read"
  [class.text-weight-bold]="!notification?.read"
  class="light-top-border p-v-2 p-r-4"
  fxLayout="row"
>
  <div class="notification-dot-wrapper p-h-2 p-t-3" fxLayoutAlign="center start">
    <div *ngIf="!notification?.read" class="notification-dot"></div>
  </div>
  <div fxLayout="column">
    <p class="mat-caption no-pad on-surface-medium">{{ notification?.createdAt | date: 'dd. MMM' }}</p>
    <p class="m-b-1">{{ notification?.title | translate }}</p>
    <div *ngIf="notification?.href" fxLayoutAlign="start center" fxLayoutGap="4px">
      <a (click)="goTo(notification?.href, notification?.params)" class="primary pointer">{{
        notification?.actionLabel | translate
      }}</a>
      <mat-icon color="primary">arrow_forward</mat-icon>
    </div>
  </div>
</div>
