<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content>
  <div class="info-box">
    <p class="keep-linebreaks">{{ data.message | translate }}</p>
  </div>
  <div fxLayout="row" fxLayoutAlign="center center">
    <mat-form-field fxFlex="grow">
      <input [value]="data.url" matInput readonly type="text" />
    </mat-form-field>
    <button (click)="copyUrl()" mat-button><mat-icon>content_copy</mat-icon></button>
  </div>
  <qrcode
    #qr
    [allowEmptyString]="true"
    [qrdata]="data.url"
    [width]="300"
    colorDark="#000000"
    colorLight="#ffffff"
    cssClass="center"
    elementType="canvas"
  ></qrcode>
</div>
<div class="p-b-2" fxLayoutAlign="space-between start" mat-dialog-actions>
  <button (click)="saveAsImage(qr)" mat-button>{{ 'COMMON.download' | translate }}</button>
  <button (click)="noActionClicked()" mat-button>{{ 'COMMON.close' | translate }}</button>
</div>
