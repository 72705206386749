import { Component, Input, OnInit } from '@angular/core';
import { Filter, FilterOption } from '@data/filter/filter';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'recrewt-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Input() filter?: Filter<any, any>;

  @Input() frmGroup?: UntypedFormGroup;

  @Input() frmArrayName?: string;

  @Input() frmControlName?: string | number;

  activeOption?: FilterOption<any, any>;

  private get formControl() {
    return this.frmGroup?.get([this.frmArrayName ?? '', this.frmControlName ?? '']);
  }

  ngOnInit(): void {
    this.activeOption = this.filter?.options.find((it) => it.isActive) ?? this.filter?.options[0];
    if (this.activeOption) {
      this.activeOption.isActive = true;
    }
    this.formControl?.valueChanges.pipe(untilDestroyed(this)).subscribe((val) => this.filter?.setValue(val));
  }

  updateOption(option: FilterOption<any, any>) {
    if (this.activeOption) {
      this.activeOption.isActive = false;
    }
    this.activeOption = option;
    this.activeOption.isActive = true;
  }

  setActive(active: boolean) {
    if (!this.filter) return;
    this.filter.isActive = active;
    this.formControl?.updateValueAndValidity();
    if (active) {
      this.formControl?.markAsUntouched();
    }
  }
}
