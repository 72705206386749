import { Injectable } from '@angular/core';
import { ApplicationStore } from '@data/application/application.store';
import { ApplicationQuery } from '@data/application/application.query';
import { CompanyQuery } from '@data/company/company.query';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Application } from '@data/application/application.model';
import { StopSubService } from '@core/services/stop-sub/stop-sub.service';
import { SpaceIonosService } from '@core/services/http/upload/space-ionos.service';
import { EMPTY, forkJoin, Observable, of } from 'rxjs';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { ApplicationStageDialogComponent } from '../../modules/application/components/application-stage-dialog/application-stage-dialog.component';
import { JobQuery } from '@data/job/job.query';
import { ApplicationMetaStatus, ApplicationStage } from '@core/enums/data/application-status';

type StageChangeData = {
  aid?: string;
  cid?: string;
  uid?: string;
  jid?: string;
  stage: ApplicationStage;
  message: string;
  date: Date;
};

@Injectable({
  providedIn: 'root',
})
export class ApplicationService {
  private url = `${environment.apiUrl}/application`;

  constructor(
    private applicationStore: ApplicationStore,
    private applicationQuery: ApplicationQuery,
    private companyQuery: CompanyQuery,
    private jobQuery: JobQuery,
    private http: HttpClient,
    private ionos: SpaceIonosService,
    private stopService: StopSubService,
    private dialogHelper: DialogService<any, any>,
  ) {}

  get(): Observable<Application[] | undefined> {
    if (this.applicationQuery.getHasCache()) return of();
    const cid$ = this.companyQuery.getActiveIdWhenReady();
    return cid$.pipe(switchMap((cid) => this.loadApplications(cid)));
  }

  updateStatus(aid: string, status: ApplicationMetaStatus) {
    const cid$ = this.companyQuery.getActiveIdWhenReady();
    return cid$.pipe(switchMap((cid) => this.setStatus(aid, cid, status)));
  }

  updateStage(application: Application, stage: ApplicationStage) {
    if (stage !== ApplicationStage.REVIEW) {
      this.openMessageForStateChangeDialog(stage);
    }
    const messageAndDate$ = stage !== ApplicationStage.REVIEW ? this.waitForDialogResult() : of(null);
    const cid$ = this.companyQuery.getActiveIdWhenReady();
    const aid = application.id;
    const uid = application.userId;
    const jid = application.jobRef;
    const startDate = this.jobQuery.getEntity(jid)?.startDate;

    return forkJoin([messageAndDate$, cid$]).pipe(
      switchMap(([data, cid]) => {
        if (stage !== ApplicationStage.REVIEW && !data) {
          return EMPTY;
        }
        if (!aid || !cid || !uid || !jid) {
          return EMPTY;
        }
        if (startDate && stage === ApplicationStage.OFFER) {
          data.date = startDate ?? data.date;
        }
        const updatedApplication: Application = {
          ...application,
          currentStageDate: data?.date ?? new Date(),
          currentStage: stage,
        };
        const put$ = this.setStage({ aid, cid, uid, jid, stage, message: data?.message, date: data?.date });
        return put$.pipe(map(() => ({ updatedApplication, message: data?.message, cid })));
      }),
    );
  }

  getDownloadUrl(applicationId: string | null, userId: string | null, onload: (url: string) => void) {
    const cid$ = this.companyQuery.getActiveIdWhenReady();
    return cid$.pipe(switchMap(this.downloadApplicationWhenReady(applicationId, userId, onload)));
  }

  sendTestApplication(to: string) {
    const cid = this.companyQuery.getId();
    return this.http.post<void>(`${this.url}/test`, { to, cid });
  }

  private loadApplications(cid?: string) {
    const observable = this.http.get<Application[]>(`${this.url}/${cid}`);
    return observable.pipe(takeUntil(this.stopService.stopped()));
  }

  private download(aid: string, cid: string, uid: string) {
    return this.http.get(`${this.url}/${cid}/${uid}/${aid}/download`, { responseType: 'blob' });
  }

  private setStage(body: StageChangeData) {
    return this.http.put<void>(`${this.url}/stage`, body);
  }

  private setStatus(aid: string, cid: string | undefined, status: ApplicationMetaStatus) {
    if (!cid) return EMPTY;
    return this.http.put(`${this.url}/status`, { cid, aid, status });
  }

  private downloadApplicationWhenReady(applId: string | null, userId: string | null, onload: (url: string) => void) {
    return (cid: string | undefined) => {
      if (!applId || !userId || !cid) return EMPTY;
      return this.download(applId, cid, userId).pipe(tap((it) => this.loadAsUrl(it, onload)));
    };
  }

  private loadAsUrl(application: Blob | undefined, onload: (url: string) => void) {
    if (!application) return;
    const reader = new FileReader();
    reader.onload = () => {
      onload(reader.result as string);
    };
    reader.readAsDataURL(application);
  }

  private openMessageForStateChangeDialog(stage: ApplicationStage): void {
    this.dialogHelper.open(ApplicationStageDialogComponent, stage);
  }

  private waitForDialogResult(): Observable<any> {
    return this.dialogHelper.confirmed();
  }
}
