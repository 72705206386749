import { Injectable } from '@angular/core';
import { EntityState, EntityStore, EntityUIStore, StoreConfig } from '@datorama/akita';
import { Resource, ResourceUI } from './resource.model';

export interface ResourceState extends EntityState<Resource> {}

export interface ResourceUIState extends EntityState<ResourceUI> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resource', resettable: true, cache: { ttl: 600000 } })
export class ResourceStore extends EntityStore<ResourceState, Resource, string> {
  ui!: EntityUIStore<ResourceUIState, ResourceUI>;

  constructor() {
    super();
    this.createUIStore();
  }
}
