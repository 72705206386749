import { ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { AbstractEditableComponent } from '@shared/components/editor/inplace-editable/abstract-editable.component';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'recrewt-click-editable',
  templateUrl: './on-click-editable.component.html',
  styleUrls: ['./on-click-editable.component.scss'],
})
export class OnClickEditableComponent extends AbstractEditableComponent {
  constructor(host: ElementRef, private cdr: ChangeDetectorRef) {
    super(host);
  }

  protected viewModeHandler(): void {
    fromEvent(document, 'focusin')
      .pipe(
        filter(({ target }) => this.element.contains(target)),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.mode = 'edit';
        this.editMode.next(true);
        this.cdr.detectChanges();
      });
  }
}
