<div fxLayout="column">
  <h1 mat-dialog-title>{{ 'FILE_ELEMENTS.GALLERY.headline' | translate }}</h1>
  <div mat-dialog-content fxFlex="grow">
    <recrewt-image-gallery
      [images]="data.images"
      [displayCategoryWith]="data.displayWith"
      (imageClicked)="dialogRef.close($event)"
    ></recrewt-image-gallery>
  </div>
  <div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
    <button (click)="noActionClicked()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  </div>
</div>
