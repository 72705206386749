import { Inject, Injectable } from '@angular/core';
import { IIonosService, UploadOptions } from '@core/services/http/upload/interface.upload.service';
import { createFromBlob, createFromFile, DocType, FileData, Realm } from '@data/file/file.model';
import { first, forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { FileStore, STORAGE_FULL } from '@data/file/file.store';
import { SERVICE_UPLOAD } from '@core/constants/system/service-providers';
import { FileQuery } from '@data/file/file.query';
import { SpaceQuery } from '@data/space/space.query';
import { map, tap } from 'rxjs/operators';
import { ImageCompressService } from '@lib/file-elements/services/image-compress.service';
import { NotificationService } from '@core/services/notification/notification.service';
import { ConfirmDialogService } from '@shared/components/dialog/confirm-dialog/confirm-dialog.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceIonosService implements IIonosService {
  constructor(
    private notify: NotificationService,
    private spaceQuery: SpaceQuery,
    private fileQuery: FileQuery,
    private fileStore: FileStore,
    private imageCompress: ImageCompressService,
    private dialog: ConfirmDialogService,
    @Inject(SERVICE_UPLOAD) private fileService: IIonosService,
  ) {}

  delete(realm: Realm, type: DocType, id: string, fileName: string): void {
    this.fileService.delete(realm, type, id, fileName);
  }

  deleteLocally(realm: Realm, type: DocType, id: string, fileName: string): void {
    this.fileStore.removeFor(id, type, (f) => f.name === fileName);
  }

  download(realm: Realm, type: DocType, id: string, name: string): Observable<Blob> {
    return this.fileService.download(realm, type, id, name);
  }

  downloadAll(realm: Realm, type: DocType, id: string): Observable<File[]> {
    return this.meta(realm, type, id).pipe(
      switchMap((meta) => {
        if (meta.length === 0) {
          return of([]);
        }
        const downloadObservables = meta.map((m) =>
          this.download(realm, type, id, m.name).pipe(map((blob) => new File([blob], m.name, { type: m.type }))),
        );
        return forkJoin(downloadObservables);
      }),
    );
  }

  meta(realm: Realm, type: DocType, id: string): Observable<FileData[]> {
    if (this.fileQuery.getHasCacheFor(realm, id, type)) {
      return this.fileQuery.selectFor(realm, id, type).pipe(first());
    }

    return this.fileService.meta(realm, type, id);
  }

  upload(
    realm: Realm,
    type: DocType,
    id: string,
    file: File,
    options: UploadOptions = { compress: true },
  ): Observable<HttpEvent<string[]> | null> {
    try {
      this.checkFileSpace(realm, id, type, file);
      if (options.compress && file.type.startsWith('image') && !file.type.includes('svg')) {
        const subject = new Subject<any>();
        this.compressAndUpload(realm, type, id, file, options, subject);
        return subject.asObservable();
      } else {
        const upload = this.uploadForSpaceAndBranchesIfNecessary(realm, type, id, file, options);
        upload?.subscribe();
        return upload;
      }
    } catch (e) {
      this.notify.error((e as Error).message);
      return of(null);
    }
  }

  uploadLocally(realm: Realm, type: DocType, id: string, file: File) {
    try {
      const fileData = createFromFile(file, id, type);
      this.fileStore.upsert(fileData.fqn, fileData);
      this.fileStore.setLoading(false);
    } catch (e) {
      this.notify.error((e as Error).message);
      return null;
    }
  }

  uploadDisplayImage(
    realm: Realm,
    context: 'company/logo' | 'company/banner' | 'job/banner',
    id: string,
    file: File | Blob,
    options: UploadOptions = { compress: true },
  ): Observable<FileData | null> {
    if (!file?.size) {
      return of(null);
    }

    const name = context === 'company/logo' ? 'logo' : 'banner';
    const img = new File([file], name, { type: file.type });
    if (!id) {
      return of(null);
    }
    return (
      this.upload(realm, context, id, img, options)?.pipe(
        map((evt) => {
          if (evt instanceof HttpResponse) {
            return createFromFile(img, id, context);
          } else {
            return null;
          }
        }),
      ) ?? of(null)
    );
  }

  loadDisplayImage(
    realm: Realm,
    context: 'company/logo' | 'company/banner' | 'job/banner',
    id: string,
  ): Observable<Blob> {
    const name = context === 'company/logo' ? 'logo' : 'banner';
    if (this.fileQuery.getHasCacheFor(realm, id, context)) {
      return of(this.fileQuery.getEntityByNameFor(realm, id, context, name)?.blob ?? new Blob());
    }
    return this.fileService.download(realm, context, id, name).pipe(
      tap((blob) => {
        const fileData = createFromBlob(blob, id, name, context);
        this.fileStore.upsert(fileData.fqn, fileData);
        this.fileStore.setLoading(false);
      }),
    );
  }

  removeDisplayImage(realm: Realm, forId: string, context: DocType, name: string): void {
    const oldImg = this.fileQuery.getFor(realm, forId, context);
    for (const img of oldImg) {
      this.fileStore.remove(img.fqn);
      this.fileService.delete(realm, context, forId, name);
    }
  }

  private uploadForSpaceAndBranchesIfNecessary(
    realm: Realm,
    type: DocType,
    id: string,
    file: File,
    options: UploadOptions,
  ) {
    if (realm === 'companies' && options.forBranches) {
      return this.uploadForBranches().pipe(
        switchMap((upload) => {
          const newOptions = { ...options, forBranches: !!upload };
          return this.fileService.upload(realm, type, id, file, newOptions);
        }),
      );
    }

    return this.fileService.upload(realm, type, id, file, options);
  }

  private checkFileSpace(realm: Realm, forId: string, context: DocType, file: File | Blob) {
    const overallSize = +(this.spaceQuery.getActive()?.usedStorageSize ?? 0);
    if (overallSize + file.size > STORAGE_FULL) {
      throw Error('Speicher für diesen Space ist voll (max. 10 GB)');
    }
  }

  private compressAndUpload(
    realm: Realm,
    type: DocType,
    id: string,
    document: File,
    options: UploadOptions,
    sub: Subject<any>,
  ) {
    return this.imageCompress.compressFile(document, ({ file }) => {
      if (!file) {
        this.notify.error('FILE_UPLOAD.err_compression');
        return null;
      }
      this.uploadForSpaceAndBranchesIfNecessary(realm, type, id, file, options)?.subscribe((evt) => sub.next(evt));
    });
  }

  private uploadForBranches() {
    const branches$ = this.spaceQuery.hasBranches$;
    return branches$.pipe(
      first(),
      switchMap((branches) => {
        if (!branches) {
          return of(false);
        } else {
          this.dialog.open({
            title: 'FILE_UPLOAD.upload_for_branches',
            message: 'FILE_UPLOAD.upload_for_branches_message',
            confirmText: 'COMMON.yes',
            cancelText: 'COMMON.no',
            confirmColor: 'accent',
          });
          return this.dialog.confirmed() as Observable<boolean | null>;
        }
      }),
    );
  }
}
