import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BookableType, GeneralJobType, getBookableType, JobGeneral } from './job-general.model';

export interface JobGeneralState extends EntityState<JobGeneral, string> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'job-general', resettable: true })
export class JobGeneralStore extends EntityStore<JobGeneralState, JobGeneral, string> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }

  override akitaPreAddEntity(newEntity: JobGeneral): JobGeneral {
    if (newEntity.type == GeneralJobType.BOOKABLE || newEntity.type == GeneralJobType.SPECIALIZATION) {
      newEntity.bookableType = getBookableType(newEntity);
    } else {
      newEntity.bookableType = null;
    }

    if (newEntity.bookableType == BookableType.COMPOUND_STUDY || newEntity.bookableType == BookableType.DUAL_STUDY) {
      newEntity.name.de = newEntity.name.de.replace('Duales Studium - ', '');
      newEntity.name.en = newEntity.name.en.replace('Dual Study - ', '');
    }

    return super.akitaPreAddEntity(newEntity);
  }
}
