import { Directive, HostListener } from '@angular/core';
import { OnClickEditableComponent } from '@shared/components/editor/inplace-editable/on-click-editable/on-click-editable.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[editableKeyListener]',
})
export class EditableKeyListenerDirective {
  constructor(private editable: OnClickEditableComponent) {}

  @HostListener('keyup.enter')
  onEnter(): void {
    this.editable.toViewMode();
  }

  @HostListener('keydown.escape')
  onEscape(): void {
    this.editable.cancelEdit();
  }
}
