<ng-container *ngIf="!loading; else loadingPlaceholder">
  <ng-container *ngIf="!!_dataSource?.data?.length; else emptyPage">
    <mat-table (matSortChange)="sortChanged($event)" [dataSource]="_dataSource" class="width-match-parent" matSort>
      <ng-container *ngIf="isSelectTable" matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? toggleAllRows() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox
            (change)="$event ? toggle(row) : null"
            (click)="$event.stopPropagation()"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container *ngFor="let column of customColumns; index as i" [matColumnDef]="column">
        <mat-header-cell
          *matHeaderCellDef
          [fxHide.lt-md]="(column | fn: get).hideLtMd"
          [fxHide.lt-sm]="(column | fn: get).hideLtSm || (column | fn: get).hideLtMd"
          mat-sort-header
          rememberSort
        >
          {{ (column | fn: get).columnTitle | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let row"
          [fxHide.lt-md]="(column | fn: get).hideLtMd"
          [fxHide.lt-sm]="(column | fn: get).hideLtSm || (column | fn: get).hideLtMd"
        >
          <ng-container
            [ngTemplateOutletContext]="{ $implicit: row }"
            [ngTemplateOutlet]="(column | fn: get).templateRef"
          ></ng-container>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
      <mat-row
        (click)="isSelectTable ? toggle(row) : itemClick.emit(row)"
        *matRowDef="let row; columns: columns"
        [class.hover-effect]="showHover"
        [class.pointer]="showHover"
      ></mat-row>
    </mat-table>
  </ng-container>
</ng-container>
<div [hidden]="!_dataSource.data.length || loading">
  <mat-paginator [hidePageSize]="true" [pageSize]="15"></mat-paginator>
</div>

<ng-template #emptyPage>
  <recrewt-empty-page (buttonClick)="emptyClick.emit()" [config]="empty"></recrewt-empty-page>
</ng-template>

<ng-template #loadingPlaceholder>
  <div class="m-t-3 p-h-3">
    <div class="m-b-2">
      <ngx-skeleton-loader [theme]="{ height: '12px' }"></ngx-skeleton-loader>
    </div>
    <ng-container *ngFor="let i of 6 | iterable">
      <div fxLayout="column" style="height: 40px">
        <ngx-skeleton-loader [theme]="{ marginBottom: '4px', padding: 0 }"></ngx-skeleton-loader>
      </div>
    </ng-container>
  </div>
</ng-template>
