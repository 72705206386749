<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent>
    <ng-container>
      <button (click)="tagSpace()" *ngIf="strategy.showTagSpace()" mat-menu-item>
        <mat-icon>tag</mat-icon>
        <span>{{ 'SPACES.TAGS.headline' | translate }}</span>
      </button>
    </ng-container>
    <ng-container>
      <button (click)="deleteSpace()" *ngIf="strategy.showDeleteSpace()" mat-menu-item>
        <mat-icon>delete</mat-icon>
        <span>{{ 'COMMON.delete' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
