export enum ApplicationMetaStatus {
  NEW,
  ARCHIVED,
  DELETED,
}

export enum ApplicationStage {
  DEFAULT,
  IN_EDIT,
  SENT,
  REVIEW,
  INTERVIEW,
  ASSESSMENT,
  OFFER,
  REJECTED,
  REJECTED_BY_USER,
  ACCEPTED_BY_USER,
}
