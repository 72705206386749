<div class="no-select" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon (click)="homeClick.emit()" [routerLink]="routerLink" class="segment" matRipple style="border-radius: 50%"
    >home
  </mat-icon>
  <mat-icon class="segment">chevron_right</mat-icon>
  <span *ngIf="segments?.length > 4" class="segment mat-h1 previous">... ></span>
  <ng-container *ngFor="let segment of segments?.slice(-4); let last = last">
    <div (click)="!last ? onSegmentClicked(segment.ref) : ''" [class.active]="last" class="segment mat-h1">
      <span>{{ segment.segment | translate }}</span>
      <mat-icon *ngIf="!last">chevron_right</mat-icon>
    </div>
  </ng-container>
</div>
