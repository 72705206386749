export enum NotificationType {
  NEW_MESSAGE,
}

export interface Notification {
  id: string;
  title?: string;
  actionLabel?: string;
  read: boolean;
  type?: NotificationType;
  companyRef: string;
  metadata: {
    [key: string]: string;
  };
  createdAt: Date;
  href?: string;
  params?: any;
}
