import { COLOR_CONFIGS } from '@core/constants/app/status-colors';
import { SessionStatus } from '@data/session/session.model';

export const FAIR_STATUS_TAGS = [
  {
    ...COLOR_CONFIGS.OK,
    icon: 'confirmation_number',
    text: 'FAIRS.STATUS.SOON',
  },
  {
    ...COLOR_CONFIGS.OK,
    icon: 'confirmation_number',
    text: 'FAIRS.STATUS.ACTIVE',
  },
  {
    ...COLOR_CONFIGS.GRAY,
    icon: 'close',
    text: 'FAIRS.STATUS.CLOSED',
  },
];

export function getFairStatusTag(status: SessionStatus) {
  return FAIR_STATUS_TAGS[status] ?? undefined;
}
