import { Injectable } from '@angular/core';
import { EntityUIQuery, Order, QueryEntity } from '@datorama/akita';
import { ChatState, ChatStore, ChatUIState } from '@data/chat/chat.store';
import { ChatMessage } from '@data/chat/chat.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ChatQuery extends QueryEntity<ChatState, ChatMessage> {
  ui!: EntityUIQuery<ChatUIState, ChatMessage>;

  constructor(protected store: ChatStore) {
    super(store);
    this.createUIQuery();
  }

  selectForMatch(companyId: string, userId: string, jobId?: string): Observable<ChatMessage[]> {
    return this.selectAll({
      filterBy: (it) => {
        return (
          (it.sender === companyId || it.receiver === companyId) &&
          (it.sender === userId || it.receiver === userId) &&
          (!jobId || jobId === it.jobRef)
        );
      },
      sortBy: 'timestamp',
      sortByOrder: Order.ASC,
    });
  }
}
