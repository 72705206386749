import { Pipe, PipeTransform } from '@angular/core';
import { ArrayMapping, MapperUtil } from '@shared/util/mapper.util';

@Pipe({
  name: 'binToArr',
})
export class BinToArrPipe implements PipeTransform {
  transform(value: number, mapping: ArrayMapping): string[] {
    return MapperUtil.binToArr(value, mapping) ?? [];
  }
}
