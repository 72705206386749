import { Component, Input, OnInit } from '@angular/core';
import { Job } from '@data/job/job.model';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { APPLICATION_INTERVIEW } from '@core/constants/job/application_interview';
import { TranslateService } from '@ngx-translate/core';
import { UrlValidator } from '@shared/util/validators';
import moment from 'moment/moment';

@Component({
  selector: 'recrewt-application-job-data-page',
  templateUrl: './application-job-data-page.component.html',
  styleUrls: ['./application-job-data-page.component.scss'],
})
export class ApplicationJobDataPageComponent implements OnInit {
  @Input() template: Partial<Job> = {};

  @Input() fallbackEmail: string | undefined;

  frmApplication!: UntypedFormGroup;

  constructor(
    private ctrlContainer: FormGroupDirective,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    let job = this.template;
    this.frmApplication = this.formBuilder.group({
      applicationFlowOverride: this.formBuilder.group({
        careerSite: [job?.applicationFlowOverride?.careerSite, [UrlValidator]],
        internshipSite: [job?.applicationFlowOverride?.internshipSite, [UrlValidator]],
      }),
      internship_possible: [!!job?.internship_possible, []],
      internshipStartDate: [job?.internshipStartDate ? moment(job?.internshipStartDate) : null, []],
      internshipEndDate: [job?.internshipEndDate ? moment(job?.internshipEndDate) : null, []],
      internshipSlots: this.internshipSlotsFormArray(this.formBuilder, job),
      allowExpressApplication: [job?.allowExpressApplication != null ? job?.allowExpressApplication : true],
      allowClassicApplication: [job?.allowClassicApplication != null ? job?.allowClassicApplication : true],
      allowExpressInternshipApplication: [
        job?.allowExpressInternshipApplication != null ? job?.allowExpressInternshipApplication : true,
      ],
      allowClassicInternshipApplication: [
        job?.allowClassicInternshipApplication != null ? job?.allowClassicInternshipApplication : true,
      ],
      contact: this.formBuilder.group({
        applicationEmail: [job?.contact?.applicationEmail ?? this.fallbackEmail, [Validators.email]],
        internshipApplicationEmail: [
          job?.contact?.internshipApplicationEmail ?? this.fallbackEmail,
          [Validators.email],
        ],
      }),
      enableInterview: [!!job?.enableInterview, []],
      application_interview: this.formBuilder.array(
        APPLICATION_INTERVIEW.map((q, i) => [this.generateQuestion(i), Validators.required]),
      ),
    });

    const parentForm = this.ctrlContainer.form;
    parentForm.addControl('application', this.frmApplication);
  }

  private internshipSlotsFormArray(fb: UntypedFormBuilder, job?: Partial<Job>) {
    const hasLegacy = job?.internshipStartDate || job?.internshipEndDate;
    const legacyInit = hasLegacy ? [{ start: job.internshipStartDate, end: job.internshipEndDate }] : [];
    const slots = job?.internshipSlots?.length ? job.internshipSlots : legacyInit;
    return fb.array(
      slots.map((slot) =>
        fb.group({
          start: [slot.start ? moment(slot.start) : null, []],
          end: [slot.end ? moment(slot.end) : null, []],
        }),
      ) ?? [],
    );
  }

  private generateQuestion(i: number): any {
    const ai = this.template?.application_interview;
    const defaultQuestion = this.translate.instant(APPLICATION_INTERVIEW[i]);
    return ai?.[i] ?? defaultQuestion;
  }
}
