import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl, Validators } from '@angular/forms';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';

@Component({
  selector: 'recrewt-text-field-editor',
  templateUrl: './text-field-editor.component.html',
  styleUrls: ['./text-field-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextFieldEditorComponent,
    },
  ],
})
export class TextFieldEditorComponent extends CustomFormControlComponent implements OnInit {
  @Input() value?: string;

  @Input() type = 'text';

  @Input() size: 'p' | 'h2' = 'p';

  @Input() multiline = false;

  @Input() placeholder?: string;

  @Output() update = new EventEmitter<string>();

  @Output() cancel = new EventEmitter<any>();

  editControl?: UntypedFormControl;

  private dirty = false;

  ngOnInit(): void {
    this.editControl = new UntypedFormControl(this.value, Validators.required);
  }

  onUpdate(): void {
    if (this.dirty) {
      this.value = this.editControl?.value;
      this.update.emit(this.editControl?.value);
      this.onChanged(this.editControl?.value);
      this.dirty = false;
    }
  }

  onCancel(): void {
    this.editControl?.setValue(this.value);
    this.dirty = false;
  }

  onInputChanged(): void {
    this.dirty = true;
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.editControl?.setValue(obj);
  }
}
