<mat-sidenav-container>
  <mat-sidenav-content (mouseenter)="collapse()">
    <ng-content select="[sidenav-content]"></ng-content>
  </mat-sidenav-content>
  <mat-sidenav
    (click)="onSideNavClicked()"
    [class.primary]="primary"
    [closeWidth]="closedWidth | px"
    [expandedWidth]="expandedWidth | px"
    [opened]="opened"
    [position]="position"
    class="no-select mat-elevation-z2"
    mode="rail"
  >
    <button
      (click)="expandButtonClicked()"
      [class.end]="position === 'end'"
      [class.on-primary]="primary"
      [class.start]="position === 'start'"
      [matBadge]="!!badge && !opened ? badge : null"
      class="expand-button mat-elevation-z2"
      mat-icon-button
    >
      <mat-icon>{{
        (opened && position === 'end') || (position === 'start' && !opened) ? 'chevron_right' : 'chevron_left'
      }}</mat-icon>
    </button>
    <div
      (delayed-hover)="expand()"
      [delay]="expandDelay"
      class="width-match-parent height-match-parent"
      style="overflow-y: scroll"
    >
      <div *ngIf="opened || (!opened && !hideOnClose)">
        <ng-content select="[sidenav]"></ng-content>
      </div>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
