import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FairBooth } from './fair-booth.model';

export interface FairBoothState extends EntityState<FairBooth> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'fair-booth', resettable: true })
export class FairBoothStore extends EntityStore<FairBoothState, FairBooth> {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor() {
    super();
  }
}
