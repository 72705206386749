<mat-menu #contextMenu="matMenu">
  <ng-template let-item="target" matMenuContent>
    <ng-container>
      <button (click)="downloadApplication(item)" *ngIf="strategy.showDownloadApplication(item)" mat-menu-item>
        <mat-icon>file_download</mat-icon>
        <span>{{ 'MENUS.APPLICATION.download' | translate }}</span>
      </button>
      <mat-divider></mat-divider>
      <button (click)="setStage([item, item.currentStage])" *ngIf="strategy.showDateChange(item)" mat-menu-item>
        <mat-icon>edit_calendar</mat-icon>
        <span>{{ 'MENUS.APPLICATION.date' | translate }}</span>
      </button>
      <button
        *ngIf="strategy.showStatusChange(item)"
        [matMenuTriggerData]="{ target: item }"
        [matMenuTriggerFor]="statusActions.contextMenu"
        mat-menu-item
      >
        <span>{{ 'MENUS.APPLICATION.status' | translate }}</span>
      </button>
      <button (click)="archive(item)" *ngIf="strategy.showArchive(item)" mat-menu-item>
        <mat-icon>archive</mat-icon>
        <span>{{ 'MENUS.APPLICATION.archive' | translate }}</span>
      </button>
      <button (click)="unarchive(item)" *ngIf="strategy.showUnarchive(item)" mat-menu-item>
        <mat-icon>unarchive</mat-icon>
        <span>{{ 'MENUS.APPLICATION.dearchive' | translate }}</span>
      </button>
      <button (click)="delete(item)" mat-menu-item>
        <mat-icon>delete_forever </mat-icon>
        <span>{{ 'COMMON.delete' | translate }}</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<recrewt-application-status-menu #statusActions (stateChange)="setStage($event)"></recrewt-application-status-menu>
