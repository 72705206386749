import { Injectable } from '@angular/core';
import { UserQuery } from '@data/user/user.query';
import { SpaceQuery } from '@data/space/space.query';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LocalStorageService } from '@core/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class SpaceStorageService extends LocalStorageService {
  constructor(private user: UserQuery, private spaceQuery: SpaceQuery) {
    super();
  }

  private static getId(obj: any): any {
    return obj.id ? obj.id : obj._id;
  }

  ukey(key: string): Observable<string> {
    return this.spaceQuery
      .getActiveWhenReady()
      .pipe(map((value) => this.user.getValue()?._id?.toString() + SpaceStorageService.getId(value) + key));
  }
}
