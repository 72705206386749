import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartData } from 'chart.js';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'recrewt-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit, OnChanges {
  public pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
    },
  };

  @Input() public labels: any[] = [];

  @Input() public data: any[] = [];

  public pieChartType = 'pie';

  public pieChartLegend = true;

  public pieChartPlugins = [];

  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    datasets: [],
  };

  colors = [
    {
      backgroundColor: ['#fbc880', '#ff8f00', '#f16100', '#f29dbe', '#e53a7d', '#d3184d'],
    },
  ];

  constructor(private translate: TranslateService) {}

  ngOnInit() {
    this.pieChartData = {
      labels: this.labels.map((label) => this.translate.instant(label)),
      datasets: [{ data: this.data }],
    };
    if (!this.pieChartData?.datasets?.[0]) return;
    this.pieChartData.datasets[0].backgroundColor = this.colors[0].backgroundColor;
  }

  ngOnChanges() {
    if (!this.pieChartData?.datasets?.[0]) return;
    this.pieChartData.datasets[0].backgroundColor = this.colors[0].backgroundColor;
  }
}
