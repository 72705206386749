import { Notification, NotificationType } from '@data/notification/notification.model';
import { MessageNotification } from '@data/notification/impl/message-notification';

export class NotificationFactory {
  static from(notification: Notification) {
    switch (notification.type) {
      case NotificationType.NEW_MESSAGE:
        return new MessageNotification(notification);
      default:
        return notification;
    }
  }
}
