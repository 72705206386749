import { Component, Input } from '@angular/core';
import { DialogService } from '@shared/components/dialog/dialog.service';
import { SiteDialogComponent } from '../site-dialog/site-dialog.component';
import { CompanySite } from '@data/company/company.model';
import { CustomFormControlComponent } from '@shared/components/editor/custom-form-control/custom-form-control.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'recrewt-sites-list',
  templateUrl: './sites-list.component.html',
  styleUrls: ['./sites-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SitesListComponent,
    },
  ],
})
export class SitesListComponent extends CustomFormControlComponent {
  sites: CompanySite[] = [];

  error: string | null = null;

  @Input() editable = true;

  constructor(private siteDialog: DialogService<SiteDialogComponent, CompanySite>) {
    super();
  }

  openSiteDialog(input: unknown): void {
    let site: CompanySite | null = null;
    if (this.isCompanySite(input)) {
      site = input as CompanySite;
    }
    this.siteDialog.open(SiteDialogComponent, site, {
      width: '800px',
    });
    this.siteDialog.confirmed().subscribe((newSite) => {
      if (!newSite) {
        return;
      }
      this.addSite(site, newSite);
    });
  }

  writeValue(obj: CompanySite[]): void {
    this.sites = _.orderBy(obj ?? [], ['name'], ['asc']);
    this.error = this.sites?.length ? null : 'COMPANY_PROFILE.GENERAL.DIALOG.err_empty';
  }

  isCompanySite = (item: unknown): item is CompanySite => {
    return !!(item as CompanySite)?.location;
  };

  remove(sites: unknown[]): void {
    sites.forEach((s) => {
      if (this.isCompanySite(s)) {
        this.sites = _.orderBy(
          this.sites.filter((site) => site.name !== s.name),
          ['name'],
          ['asc'],
        );
      }
    });
    this.onChanged(this.sites);
  }

  private addSite(oldSite: CompanySite | null, newSite: CompanySite): void {
    this.error = null;
    if (this.sites.some((s) => s.name === newSite.name)) {
      if (!oldSite) {
        this.error = 'COMPANY_PROFILE.GENERAL.DIALOG.err_exists';
        return;
      }
    }
    if (!!oldSite) {
      this.sites = this.sites.filter((s) => s.name !== oldSite.name);
    }
    if (newSite.hq && this.sites.some((s) => s.hq)) {
      this.error = 'COMPANY_PROFILE.GENERAL.DIALOG.err_hq';
      newSite.hq = false;
    }
    this.sites.push(newSite);
    this.sites = _.orderBy(this.sites, ['name'], ['asc']);
    this.onChanged(this.sites);
  }
}
