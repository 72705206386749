import { enableProdMode } from '@angular/core';
import { persistState } from '@datorama/akita';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AKITA_STORAGE_KEY } from '@core/constants/system/storage-key';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { deserialize } from '@data/filter/filter.store';
import { initEffects } from '@ngneat/effects';

function getGermanPaginatorIntl(): MatPaginatorIntl {
  const germanRangeLabel = (page: number, pageSize: number, length: number) => {
    length = Math.max(length ?? 0, 0);
    pageSize = pageSize ?? 0;
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} von ${length}`;
  };

  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = 'Elemente pro Seite:';
  paginatorIntl.nextPageLabel = 'Nächste Seite';
  paginatorIntl.previousPageLabel = 'Vorherige Seite';
  paginatorIntl.getRangeLabel = germanRangeLabel;

  return paginatorIntl;
}

if (environment.production) {
  enableProdMode();
}

const providers = [
  {
    provide: 'persistStorage',
    useValue: persistState({
      key: AKITA_STORAGE_KEY,
      include: ['message', 'filter'],
      preStoreUpdate(storeName, state) {
        if (storeName === 'filter') {
          return deserialize(state);
        }
        return state;
      },
    }),
  },
  { provide: MatPaginatorIntl, useValue: getGermanPaginatorIntl() },
];

initEffects();

platformBrowserDynamic(providers)
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
