import { Component, EventEmitter, Inject, Input, OnInit, Output, Type } from '@angular/core';
import { ListSideNavItem } from '@shared/components/list-sidenav/data/list-side-nav-item';
import { Observable } from 'rxjs';
import { animate, style, transition, trigger } from '@angular/animations';
import { SERVICE_LOCAL_STORAGE } from '@core/constants/system/service-providers';
import { IStorageService } from '@core/services/storage/storage.service';
import { SIDE_LIST_EXPANSION_STATE } from '@core/constants/system/storage-key';
import { Identifiable } from '@data/identifiable';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'recrewt-list-sidenav',
  templateUrl: './list-sidenav.component.html',
  styleUrls: ['./list-sidenav.component.scss'],
  animations: [
    trigger('items', [
      transition(':enter', [
        style({ transform: 'scale(0.5)', opacity: 0 }),
        animate('0.7s cubic-bezier(.8, -0.6, 0.2, 1.5)', style({ transform: 'scale(1)', opacity: 1 })),
      ]),
    ]),
  ],
})
export class ListSideNavComponent<T extends Identifiable> implements OnInit {
  @Input() itemType?: Type<any>;

  @Input() items?: Observable<ListSideNavItem<T>[]>;

  @Input() headline = '';

  @Output() itemClicked = new EventEmitter<any>();

  opened = false;

  showSidenav = true;

  private readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(distinctUntilChanged());

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(SERVICE_LOCAL_STORAGE) private localStorage: IStorageService,
  ) {}

  ngOnInit(): void {
    this.localStorage.get(SIDE_LIST_EXPANSION_STATE).subscribe((opened: any) => (this.opened = !!opened));
    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }

  onItemClicked(data: any): void {
    this.itemClicked.emit(data);
  }

  openClicked(opened: boolean): void {
    this.localStorage.set(SIDE_LIST_EXPANSION_STATE, opened);
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched([Breakpoints.Medium, Breakpoints.Large])) {
      this.showSidenav = true;
    } else if (this.breakpointObserver.isMatched([Breakpoints.Small, Breakpoints.XSmall])) {
      this.showSidenav = false;
    }
  }
}
