<div fxLayoutAlign="center center" fxLayoutGap="16px">
  <button
    [title]="'COMMON.delete' | translate"
    (click)="deleteClicked.emit(); $event.stopPropagation()"
    [disabled]="disableDelete"
    mat-icon-button
  >
    <mat-icon>delete</mat-icon>
  </button>
  <button
    title="Einladung erneut senden"
    (click)="onResendClicked(); $event.stopPropagation()"
    [disabled]="disableResend"
    mat-icon-button
  >
    <mat-icon>send</mat-icon>
  </button>
</div>
