<ng-container>
  <recrewt-table
    (emptyClick)="onActionClick('emptyClicked', $event)"
    (itemClick)="onActionClick('itemClicked', $event)"
    (selectChange)="onActionClick('selectChange', $event)"
    [dataSource]="(data$ | async)?.reverse()"
    [empty]="emptyConfig"
    [filter]="filter"
    [isSelectTable]="isSelectTable"
    [loading]="loading$ | async"
    [selected]="selected$ | async"
    [showHover]="true"
    [sortBy]="sortBy"
  >
    <ng-template cell="status" columnTitle="FAIRS.TABLE.COLUMN.status" let-fair>
      <recrewt-status-tag [config]="fair.session.status | statusTag : 'fair'"></recrewt-status-tag>
    </ng-template>

    <ng-template cell="name" columnTitle="FAIRS.TABLE.COLUMN.name" let-fair>
      {{ fair.session.name }}
    </ng-template>

    <ng-template cell="booth-nr" columnTitle="FAIRS.TABLE.COLUMN.booth-nr" let-fair>
      {{ fair.boothNr }}
    </ng-template>

    <ng-template [hideLtMd]="true" [hideLtSm]="true" cell="location" columnTitle="FAIRS.TABLE.COLUMN.location" let-fair>
      {{ fair.session.location?.address ?? '' }}
    </ng-template>

    <ng-template [hideLtMd]="true" [hideLtSm]="true" cell="date" columnTitle="FAIRS.TABLE.COLUMN.date" let-fair>
      <span *ngIf="!fair.session.dates?.length">{{ fair.session.date | date : 'dd.MM.YYYY' }}</span>
      <span *ngIf="fair.session.dates?.length"
        >{{ fair.session.dates[0]?.start | date : 'dd.MM.YYYY HH:mm' }} -<br />
        {{ fair.session.dates[fair.session.dates.length - 1]?.end | date : 'dd.MM.YYYY HH:mm' }}</span
      >
    </ng-template>

    <ng-container *ngIf="showActions && !isSelectTable">
      <ng-template cell="actions" columnTitle="FAIRS.TABLE.COLUMN.actions" let-fair>
        <button
          (click)="$event.stopPropagation()"
          *ngIf="!!m.contextMenu && !fair?.session.status !== 2"
          [matMenuTriggerData]="{ target: fair }"
          [matMenuTriggerFor]="m.contextMenu"
          mat-icon-button
        >
          <mat-icon>more_horiz</mat-icon>
        </button>
        <recrewt-fairs-menu
          #m
          (bookMore)="onActionClick('bookMore', $event)"
          (delete)="onActionClick('delete', $event)"
          (editData)="onActionClick('editData', $event)"
          (editJobs)="onActionClick('editJobs', $event)"
        ></recrewt-fairs-menu>
      </ng-template>
    </ng-container>
  </recrewt-table>
</ng-container>
