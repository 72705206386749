import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TableCellDirective } from '@shared/directives/table-cell.directive';
import { ArrayUtil } from '@shared/util/array.util';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { EmptyConfig } from '@shared/components/placeholder/empty-page/empty-page.component';
import { SelectionModel } from '@angular/cdk/collections';
import { Identifiable } from '@data/identifiable';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'recrewt-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T extends Identifiable> implements AfterContentInit, OnChanges, AfterViewInit {
  @ContentChildren(TableCellDirective) tableCellDirectives?: QueryList<TableCellDirective>;

  _dataSource = new MatTableDataSource<T>();

  customColumns: string[] = [];

  columns: string[] = [];

  cellTemplates: Map<string, TableCellDirective> = new Map();

  selection = new SelectionModel<T>(true, []);

  @ViewChild(MatSort) sort: MatSort | null = null;

  @ViewChild(MatPaginator) paginator: MatPaginator | null = null;

  @Output() itemClick = new EventEmitter<T>();

  @Output() emptyClick = new EventEmitter();

  @Output() selectChange = new EventEmitter<T[]>();

  @Input() showHover = false;

  @Input() empty?: EmptyConfig;

  @Input() dataSource?: any;

  @Input() loading: boolean = false;

  @Input() isSelectTable = false;

  @Input() selected: T[] = [];

  Breakpoints = Breakpoints;

  currentBreakpoint: string = '';

  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small])
    .pipe(distinctUntilChanged());

  @Input() private sortBy?: (sort: Sort, data: T[]) => T[];

  constructor(private breakpointObserver: BreakpointObserver, private cdr: ChangeDetectorRef) {}

  @Input() filter?: (item: any) => boolean = () => true;

  ngAfterContentInit(): void {
    this.customColumns = this.tableCellDirectives?.filter((it) => !!it?.cell).map((it) => it.cell!) ?? [];
    this.cellTemplates = ArrayUtil.toMap(this.tableCellDirectives?.toArray() ?? [], (val) => val.cell);

    this.columns = [...this.customColumns];
    if (this.isSelectTable) {
      this.columns.unshift('select');
    }

    this.breakpoint$.subscribe(() => this.breakpointChanged());
  }

  ngOnChanges(changes: SimpleChanges): void {
    const dataset = changes?.dataSource?.currentValue;
    if (dataset) {
      this._dataSource.data = this.dataSource.filter(this.filter);
      this.cdr.detectChanges();
    }

    const selected = changes?.selected?.currentValue;
    if (selected?.length) {
      const selectedIds = this.selected?.map((it) => it.id);
      const preselected = this._dataSource.data.filter((it) => selectedIds?.includes(it.id));
      if (preselected?.length) {
        this.selection.select(...preselected);
        this.selectChange.emit(preselected);
      }
    }
  }

  get = (column: any) => this.cellTemplates.get(column)!;

  sortChanged(sort: Sort): void {
    if (!sort.active || sort.direction === '') {
      this._dataSource.data = this.dataSource.filter(this.filter);
      return;
    }

    if (this.sortBy) {
      this._dataSource.data = this.sortBy(sort, this._dataSource.data.slice());
    }
  }

  ngAfterViewInit(): void {
    this._dataSource.sort = this.sort;
    this._dataSource.paginator = this.paginator;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this._dataSource.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      this.selectChange.emit([]);
      return;
    }

    this.selection.select(...this._dataSource.data);
    this.selectChange.emit(this._dataSource.data);
  }

  toggle(row: T) {
    this.selection.toggle(row);
    this.selectChange.emit(this.selection.selected);
  }

  private breakpointChanged() {
    if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.currentBreakpoint = Breakpoints.Large;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.currentBreakpoint = Breakpoints.Medium;
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.currentBreakpoint = Breakpoints.Small;
    }
  }
}
