import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-what-is-this',
  templateUrl: './what-is-this.component.html',
  styleUrls: ['./what-is-this.component.scss'],
})
export class WhatIsThisComponent {
  @Input() desc = '';
}
