import { Component, Input } from '@angular/core';

@Component({
  selector: 'recrewt-textbox-placeholder',
  templateUrl: './text-box-placeholder.component.html',
  styleUrls: ['./text-box-placeholder.component.scss'],
})
export class TextBoxPlaceholderComponent {
  @Input() message = '';
}
