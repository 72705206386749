import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserDataState, UserDataStore } from '@data/user-data/user-data.store';
import { UserData } from '@data/user-data/user-data.model';

@Injectable({ providedIn: 'root' })
export class UserDataQuery extends QueryEntity<UserDataState, UserData> {
  constructor(protected store: UserDataStore) {
    super(store);
  }
}
