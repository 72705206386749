import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InputDialogData } from '@shared/components/dialog/input-dialog/input-dialog.component';

@Component({
  selector: 'recrewt-date-dialog',
  templateUrl: './date-dialog.component.html',
  styleUrls: ['./date-dialog.component.scss'],
})
export class DateDialogComponent implements OnInit {
  form!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<DateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const { value, valid } = this.form;
    if (valid) {
      this.dialogRef.close(value.date);
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      date: [null, [Validators.required, this.data.validator]],
    });
  }
}
