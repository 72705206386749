import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuComponent, MenuStrategy } from '@shared/menus/menu/menu.component';
import { Space } from '@data/space/space.model';

@Component({
  selector: 'recrewt-space-menu',
  templateUrl: './space-menu.component.html',
  styleUrls: ['./space-menu.component.scss'],
})
export class SpaceMenuComponent extends MenuComponent<Space> {
  @Input() showTagEdit = false;

  @Output() spaceEditClicked = new EventEmitter<any>();

  @Output() spaceDeleteClicked = new EventEmitter<any>();

  @Output() spaceTagsClicked = new EventEmitter<any>();

  private readonly defaultStrategy: SpaceMenuStrategy = {
    showDeleteSpace: () => true,
    showTagSpace: () => this.showTagEdit,
  };

  @Input() strategy: SpaceMenuStrategy = this.defaultStrategy;

  deleteSpace(): void {
    this.spaceDeleteClicked.emit();
  }

  tagSpace(): void {
    this.spaceTagsClicked.emit();
  }
}

export interface SpaceMenuStrategy extends MenuStrategy {
  showDeleteSpace(): boolean;
  showTagSpace(): boolean;
}
