<div [formGroup]="parentFormGroup" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
  <mat-form-field appearance="outline" class="slim" fxFlex="72px">
    <input
      #inputAmount
      [formControlName]="formCtrlName"
      [min]="min"
      [step]="step"
      [value]="min.toString()"
      digitOnly
      matInput
      type="number"
    />
    <mat-error>{{ 'JOB_CREATOR.GENERAL.ERRORS.too_low' | translate }}</mat-error>
  </mat-form-field>
  <mat-slider
    (input)="inputAmount.value = $event.value.toString()"
    [(value)]="+inputAmount.value"
    [formControlName]="formCtrlName"
    [max]="max"
    [min]="min"
    [required]="require"
    [step]="step"
    [thumbLabel]="showThumb"
    color="primary"
    fxFlex="grow"
  ></mat-slider>
</div>
