import { COLOR_CONFIGS } from '@core/constants/app/status-colors';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';
import { MatchStatus } from '@core/enums/data/match-status';

export const MATCH_STATUS_TAGS = {
  like: {
    ...COLOR_CONFIGS.WARN,
    icon: 'thumb_up',
    text: 'event.2_like',
    desc: 'MATCHING.TYPES.like_desc',
  },
  highlike: {
    ...COLOR_CONFIGS.OK,
    icon: 'favorite',
    text: 'event.3_super_like',
    desc: 'MATCHING.TYPES.super_like_desc',
  },
  application: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'badge',
    text: 'event.5_application',
    desc: 'MATCHING.TYPES.appl_desc',
  },
};

export function getMatchStatusTag(status: MatchStatus): StatusTagConfig | undefined {
  switch (status) {
    case MatchStatus.HIGH_LIKE:
      return MATCH_STATUS_TAGS.highlike;
    case MatchStatus.LIKE:
      return MATCH_STATUS_TAGS.like;
    case MatchStatus.APPLICATION:
      return MATCH_STATUS_TAGS.application;
    default:
      return undefined;
  }
}
