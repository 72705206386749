<h1 mat-dialog-title>{{ 'JOB_CREATOR.ADD_FOR_START_YEAR.title' | translate }}</h1>
<div mat-dialog-content>
  <p class="info-box">{{ 'JOB_CREATOR.ADD_FOR_START_YEAR.message' | translate }}</p>
  <div class="p-v-2" fxLayout="column" fxLayoutGap="12px">
    <div *ngFor="let year of startYearOptions">
      <mat-checkbox (change)="onYearToggled(year, $event.checked)">{{ year }}</mat-checkbox>
    </div>
  </div>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  <button (click)="submit()" color="accent" mat-flat-button type="submit">
    {{ 'COMMON.create' | translate }}
  </button>
</div>
