<div *ngIf="!loading; else loadingTpl" class="full-screen">
  <header class="p-2 header" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="space-between center">
      <button (click)="goBack()" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
      <div class="divider"></div>
      <p class="mat-subheading-1 no-pad" fxHide.lt-sm>{{ headline | translate }}</p>
    </div>
    <ng-content select="[header]"></ng-content>
  </header>
  <div class="content p-h-5 p-t-3">
    <ng-content select="[content]"></ng-content>
  </div>
</div>

<ng-template #loadingTpl>
  <recrewt-full-screen-loading text="Lädt..."></recrewt-full-screen-loading>
</ng-template>
