// Import the core angular services.
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fn',
  pure: true,
})
export class FnPipe implements PipeTransform {
  public transform(templateValue: any, fnReference: Function, ...fnArguments: any[]): any {
    fnArguments.unshift(templateValue);

    return fnReference.apply(null, fnArguments);
  }
}
