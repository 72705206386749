import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { NotificationState, NotificationStore } from '@data/notification/notification.store';
import { Notification } from '@data/notification/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationQuery extends QueryEntity<NotificationState, Notification> {
  countUnread$ = this.selectCount((it) => !it.read);

  constructor(protected store: NotificationStore) {
    super(store);
  }
}
