import { COLOR_CONFIGS } from '@core/constants/app/status-colors';
import { StatusTagConfig } from '@shared/components/status-tag/status-tag.component';
import { ApplicationMetaStatus, ApplicationStage } from '@core/enums/data/application-status';

export type ApplicationStatusType =
  | 'new'
  | 'invited'
  | 'assessment'
  | 'offer'
  | 'rejected'
  | 'archived'
  | 'deleted'
  | 'rejected_by_user'
  | 'accepted_by_user';

export const APPLICATION_STATUS_TAGS = {
  new: {
    ...COLOR_CONFIGS.INFO,
    icon: 'auto_awesome',
    text: 'APPLICATIONS.STATUS.new',
  },
  invited: {
    ...COLOR_CONFIGS.WARN,
    icon: 'insert_invitation',
    text: 'APPLICATIONS.STATUS.invited',
  },
  assessment: {
    ...COLOR_CONFIGS.WARN,
    icon: 'insert_invitation',
    text: 'APPLICATIONS.STATUS.assessment',
  },
  offer: {
    ...COLOR_CONFIGS.OK,
    icon: 'insert_invitation',
    text: 'APPLICATIONS.STATUS.offer',
  },
  rejected: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'close',
    text: 'APPLICATIONS.STATUS.rejected',
  },
  archived: {
    ...COLOR_CONFIGS.GRAY,
    icon: 'archive',
    text: 'APPLICATIONS.STATUS.archived',
  },
  deleted: {
    ...COLOR_CONFIGS.ERROR,
    icon: 'delete',
    text: 'APPLICATIONS.STATUS.deleted',
    desc: 'APPLICATIONS.OVERVIEW.hint',
  },
  rejected_by_user: {
    ...COLOR_CONFIGS.OK_VARIANT,
    icon: 'not_interested',
    text: 'APPLICATIONS.STATUS.rejected_by_user',
  },
  accepted_by_user: {
    ...COLOR_CONFIGS.OK,
    icon: 'check',
    text: 'APPLICATIONS.STATUS.accepted_by_user',
  },
};

const EXPRESS_STATUS_TAG = {
  ...COLOR_CONFIGS.INFO,
  icon: 'bolt',
  text: 'APPLICATIONS.TABLE.COLUMN.express',
  desc: 'JOB_CREATOR.APPLICATION.HEADLINES.allow_express_application',
};

const APPRENTICESHIP_STATUS_TAG = {
  ...COLOR_CONFIGS.INFO,
  text: 'APPLICATIONS.TABLE.COLUMN.apprenticeship',
  desc: 'APPLICATIONS.apprenticeship_hint',
};

export function getApplicationStatus(
  stage: ApplicationStage,
  status: ApplicationMetaStatus,
): ApplicationStatusType | undefined {
  if (status === ApplicationMetaStatus.ARCHIVED) {
    return 'archived';
  } else if (status === ApplicationMetaStatus.DELETED) {
    return 'deleted';
  }
  switch (stage) {
    case ApplicationStage.SENT:
    case ApplicationStage.REVIEW:
      return 'new';
    case ApplicationStage.INTERVIEW:
      return 'invited';
    case ApplicationStage.ASSESSMENT:
      return 'assessment';
    case ApplicationStage.OFFER:
      return 'offer';
    case ApplicationStage.REJECTED:
      return 'rejected';
    case ApplicationStage.REJECTED_BY_USER:
      return 'rejected_by_user';
    case ApplicationStage.ACCEPTED_BY_USER:
      return 'accepted_by_user';
  }
}

export function getApplicationStatusTag(stage: ApplicationStage, status: ApplicationMetaStatus) {
  const applicationStatus = getApplicationStatus(stage, status);
  if (!applicationStatus) return undefined;
  return APPLICATION_STATUS_TAGS[applicationStatus] ?? undefined;
}

export function getExpressStatusTag(isExpress: boolean): StatusTagConfig | undefined {
  if (isExpress) {
    return EXPRESS_STATUS_TAG;
  }
}

export function getApprenticeshipStatusTag(isApprenticeship: boolean): StatusTagConfig | undefined {
  if (isApprenticeship) {
    return APPRENTICESHIP_STATUS_TAG;
  }
}
