import { Component, Input } from '@angular/core';
import { SKELETON_COLOR } from '@core/constants/system/skeleton';

@Component({
  selector: 'recrewt-name-letter-avatar-skeleton',
  templateUrl: './name-letter-avatar-skeleton.component.html',
  styleUrls: ['./name-letter-avatar-skeleton.component.scss'],
})
export class NameLetterAvatarSkeletonComponent {
  @Input() showName = true;

  color = SKELETON_COLOR;
}
