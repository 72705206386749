import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface MessageState {
  key: string;
  text: string;
  ref: string;
  hidden: boolean;
}

export function createInitialState(): MessageState {
  return {
    key: '',
    hidden: true,
    ref: '',
    text: '',
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'message', resettable: true })
export class MessageStore extends Store<MessageState> {
  constructor() {
    super(createInitialState());
  }
}
