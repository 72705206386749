import { Component, Input, OnInit } from '@angular/core';
import { Job } from '@data/job/job.model';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { QuestionConfig } from '@shared/components/question-panel/card-deck-form/card-deck-form.component';
import { JobDataMapperService } from '@lib/job-elements/services/job-data-mapper.service';

@Component({
  selector: 'recrewt-frame-job-data-page',
  templateUrl: './frame-job-data-page.component.html',
  styleUrls: ['./frame-job-data-page.component.scss'],
})
export class FrameJobDataPageComponent implements OnInit {
  @Input() template: Partial<Job> = {};

  questionSet: QuestionConfig[] = [];

  frmFrame!: UntypedFormGroup;

  finished = false;

  initialValues: number[] = [];

  constructor(
    private jdm: JobDataMapperService,
    private ctrlContainer: FormGroupDirective,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.questionSet = this.jdm.getFrameDataQuestions();
  }

  ngOnInit(): void {
    const frame = this.template?.matchData?.frame ?? -1;
    const frameValues = new UntypedFormArray([]);
    const parentForm = this.ctrlContainer.form;

    this.frmFrame = this.formBuilder.group({ frameValues });
    parentForm.addControl('frame', this.frmFrame);

    if (frame < 0) {
      this.frmFrame.setErrors({ incorrect: true });
    }
    this.initialValues = this.jdm.frameDataBinToArr(this.questionSet.length, frame);
  }
}
