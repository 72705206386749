import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { enumKeys } from '@shared/util/enum.util';
import moment, { Moment } from 'moment';
import { ApplicationStage } from '@core/enums/data/application-status';

@Component({
  templateUrl: './application-stage-dialog.component.html',
  styleUrls: ['./application-stage-dialog.component.scss'],
})
export class ApplicationStageDialogComponent implements OnInit {
  form!: UntypedFormGroup;

  stageKey = enumKeys(ApplicationStage)[this.stage];

  useDate = this.stage === ApplicationStage.INTERVIEW || this.stage === ApplicationStage.ASSESSMENT;

  readonly STATUS_MESSAGE = [
    null,
    null,
    null,
    null,
    'Einladung zum Bewerbungsgespräch',
    'Einladung zum Assessment-Center',
    'Zusage',
    'Absage',
    null,
    null,
  ];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ApplicationStageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public stage: ApplicationStage,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  submit(): void {
    const { value, valid } = this.form;
    if (valid) {
      let statusMessage = this.STATUS_MESSAGE[this.stage] ?? '';
      const message = value.message ?? '';
      const date = this.combineDateAndTime(value);
      const dateStr = moment(date).format('DD.MM.YYYY HH:mm');
      const userMessage =
        date && this.useDate
          ? `${statusMessage}:\n\n${message}\n\nVorgeschlagener Termin: ${dateStr}`
          : `${statusMessage}:\n\n${message}`;
      this.dialogRef.close({ message: userMessage, date });
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      message: [null, [Validators.required]],
      date: [this.useDate ? null : moment(), [Validators.required]],
      time: [this.useDate ? null : '00:00', [Validators.required]],
    });
  }

  private combineDateAndTime = (value: { date: Moment; time: string }) => {
    const date: Moment = value.date;
    const hoursAndMinutes: number[] = value.time.split(':')?.map((it: string) => +it);
    date.set('hours', hoursAndMinutes[0]);
    date.set('minutes', hoursAndMinutes[1]);
    return date.subtract(2, 'hours').toDate();
  };
}
