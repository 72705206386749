<h1 mat-dialog-title>{{ 'COMPANY_PROFILE.GENERAL.DIALOG.headline' | translate }}</h1>
<div [formGroup]="form" mat-dialog-content>
  <mat-form-field appearance="outline" class="width-match-parent">
    <input formControlName="name" matInput required />
    <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
    <mat-label>{{ 'COMPANY_PROFILE.GENERAL.DIALOG.label_name' | translate }}</mat-label>
  </mat-form-field>
  <recrewt-location-selector
    [formGroup]="form"
    class="width-match-parent"
    controlName="location"
    require="true"
  ></recrewt-location-selector>
</div>
<div class="p-b-2" fxLayoutAlign="end start" mat-dialog-actions>
  <button (click)="onNoClick()" mat-button>{{ 'COMMON.cancel' | translate }}</button>
  <button (click)="submit()" [disabled]="!form.valid" color="accent" mat-flat-button>
    {{ 'COMMON.add' | translate }}
  </button>
</div>
