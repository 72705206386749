import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StopSubService {
  private stopSignal$ = new Subject();

  stopped(): Subject<any> {
    return this.stopSignal$;
  }

  stop(): void {
    this.stopSignal$.next(true);
  }
}
