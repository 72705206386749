import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserDataStore } from '@data/user-data/user-data.store';
import { UserDataQuery } from '@data/user-data/user-data.query';
import { environment } from '../../../environments/environment';
import { UserData } from '@data/user-data/user-data.model';
import { retryBackoff } from 'backoff-rxjs';
import { SpaceQuery } from '@data/space/space.query';
import { takeUntil, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { StopSubService } from '@core/services/stop-sub/stop-sub.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  private url = `${environment.apiUrl}/user`;

  constructor(
    private http: HttpClient,
    private collaboratorStore: UserDataStore,
    private collaboratorQuery: UserDataQuery,
    private spaceQuery: SpaceQuery,
    private stopService: StopSubService,
  ) {}

  public getForActive(): void {
    // if (!this.collaboratorQuery.getHasCache()) {
    this.spaceQuery.getActiveWhenReady().subscribe((space) => {
      if (!space) {
        return;
      }
      this.getForCompany(space.companyRef);
    });
    // }
  }

  public get(uid: string): Observable<UserData> {
    return this.http.get<UserData>(`${this.url}/${uid}`).pipe(
      takeUntil(this.stopService.stopped()),
      tap((userData) => this.collaboratorStore.upsert(userData.id, userData)),
      retryBackoff({
        shouldRetry: (e) => e.statusCode === 504,
        maxRetries: 3,
        initialInterval: 1000,
      }),
    );
  }

  private getForCompany(companyId: string): void {
    this.http
      .get<UserData[]>(`${this.url}/company/${companyId}`)
      .pipe(
        retryBackoff({
          shouldRetry: (e) => e.statusCode === 504,
          maxRetries: 3,
          initialInterval: 1000,
        }),
      )
      .subscribe((userData) => {
        this.collaboratorStore.set(userData);
      });
  }
}
