<mat-card class="width-match-parent p-h-4 p-t-4 p-b-3" fxLayout="column" style="border-radius: 16px">
  <div fxFlex="grow" fxLayout="row" fxLayoutAlign="space-between end">
    <ng-container *ngFor="let bar of bars">
      <div fxLayout="column" fxLayoutAlign="end center">
        <div [ngStyle]="{ 'height.px': maxHeight }" fxLayout="column" fxLayoutAlign="end center" id="container">
          <div [ngStyle]="{ 'height.px': maxHeight }" class="bar-back" id="bar-back"></div>
          <div [ngStyle]="{ 'height.px': (bar / (maxBar ?? 1)) * maxHeight }" class="bar" id="bar"></div>
        </div>
        <p>{{ bar }}€</p>
      </div>
    </ng-container>
  </div>
  <div class="p-h-4" fxLayout="row" fxLayoutAlign="space-between end">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="mobile-primary-back legend-node"></div>
      <p class="no-pad m-l-1 on-surface-medium">Vergütung</p>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="mobile-accent-back legend-node"></div>
      <p class="no-pad m-l-1 on-surface-medium">Durchschnitt</p>
    </div>
  </div>
</mat-card>
