import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {
  SERVICE_AUTH,
  SERVICE_CHANGES,
  SERVICE_LOCAL_STORAGE,
  SERVICE_UPLOAD,
} from '@core/constants/system/service-providers';
import { IonosService } from '@core/services/http/upload/ionos.service';
import { AuthService } from '@core/services/http/auth/auth.service';
import { ChangesService } from '@core/services/changes/changes.service';
import { SpaceStorageService } from '@core/services/storage/space-storage.service';
import { ErrorInterceptor } from '@core/interceptor/error/error.interceptor';

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule, HttpClientModule, MatSnackBarModule],
  providers: [
    { provide: SERVICE_AUTH, useClass: AuthService },
    { provide: SERVICE_CHANGES, useClass: ChangesService },
    { provide: SERVICE_LOCAL_STORAGE, useClass: SpaceStorageService },
    { provide: SERVICE_UPLOAD, useClass: IonosService },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule {}
