import { Component, Input } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'recrewt-fullscreen-dialog-layout',
  templateUrl: './fullscreen-dialog-layout.component.html',
  styleUrls: ['./fullscreen-dialog-layout.component.scss'],
})
export class FullscreenDialogLayoutComponent {
  @Input() headline = '';

  @Input() loading = false;

  constructor(private location: Location) {}

  goBack = (): void => this.location.back();
}
