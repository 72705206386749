import { Component } from '@angular/core';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AbstractEditableComponent } from '@shared/components/editor/inplace-editable/abstract-editable.component';

@Component({
  selector: 'recrewt-dblclick-editable',
  templateUrl: './dbl-click-editable.component.html',
})
export class EditableComponent extends AbstractEditableComponent {
  protected viewModeHandler(): void {
    fromEvent(this.element, 'dblclick')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.mode = 'edit';
        this.editMode.next(true);
      });
  }
}
