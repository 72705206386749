<span fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
  <p
    #tooltip="matTooltip"
    [matTooltip]="name"
    [class.big]="size === 'big'"
    matTooltipClass="tooltip"
    matTooltipPosition="before"
    [style.background-color]="backgroundColor"
    [style.color]="color"
    class="avatar"
  >
    {{ acronym }}
  </p>
  <p *ngIf="showName" class="name p-1">{{ name }}</p>
</span>
