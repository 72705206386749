import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SERVICE_AUTH } from '@core/constants/system/service-providers';
import { IAuthService } from '@core/services/http/auth/interface.auth.service';
import { AuthService } from '@auth0/auth0-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(@Inject(SERVICE_AUTH) private auth: IAuthService, private router: Router, private auth0: AuthService) {}

  canActivate(): Observable<boolean> {
    return this.auth0.isAuthenticated$.pipe(
      map((isAuth) => {
        if (isAuth) {
          return true;
        } else {
          this.auth.logout();
          return false;
        }
      }),
    );
  }
}
