/**
 * Helper function that checks whether the attachment is a zip archive file.
 *
 * @return true, if attachment is a zip archive, false otherwise
 */
export function isZip(type: string): boolean {
  return (
    type.includes('/gzip') ||
    type.includes('/zip') ||
    type.includes('/x-7z-compressed') ||
    type.includes('/vnd.rar') ||
    type.includes('/x-bzip')
  );
}

/**
 * Helper function that checks whether the attachment is a ms excel (or similar) file.
 *
 * @return true, if attachment is a ms excel file, false otherwise
 */
export function isExcel(type: string): boolean {
  return (
    type.includes('excel') ||
    type.includes('/vnd.openxmlformats-officedocument.spreadsheetml') ||
    type.includes('/vnd.oasis.opendocument.spreadsheet') ||
    type.includes('comma-separated-values')
  );
}

/**
 * Helper function that checks whether the attachment is a ms powerpoint (or similar) file.
 *
 * @return true, if attachment is a ms powerpoint file, false otherwise
 */
export function isPowerPoint(type: string): boolean {
  return (
    type.includes('powerpoint') ||
    type.includes('/vnd.openxmlformats-officedocument.presentationml') ||
    type.includes('/vnd.oasis.opendocument.presentation')
  );
}

/**
 * Helper function that checks whether the attachment is a ms word (or similar) file.
 *
 * @return true, if attachment is a ms word file, false otherwise
 */
export function isWordDocument(type: string): boolean {
  return (
    type.includes('word') ||
    type.includes('/vnd.openxmlformats-officedocument.wordprocessingml') ||
    type.includes('/vnd.oasis.opendocument.text')
  );
}

/**
 * Helper function that checks whether the attachment is a purely textually representable file.
 *
 * @return true, if attachment is purely textually representable, false otherwise
 */
export function isText(type: string): boolean {
  return type.includes('text/');
}

/**
 * Helper function that checks whether the attachment is a google-apps file.
 *
 * @return true, if attachment is a google-apps file, false otherwise
 */
export function isGoogleFileType(type: string): boolean {
  return [
    'vnd.google-apps.audio',
    'vnd.google-apps.document',
    'vnd.google-apps.drawing',
    'vnd.google-apps.file',
    'vnd.google-apps.folder',
    'vnd.google-apps.form',
    'vnd.google-apps.fusiontable',
    'vnd.google-apps.kix',
    'vnd.google-apps.photo',
    'vnd.google-apps.presentation',
    'vnd.google-apps.script',
    'vnd.google-apps.sites',
    'vnd.google-apps.spreadsheet',
    'vnd.google-apps.unknown',
    'vnd.google-apps.video',
  ].some((t) => t === type);
}

/**
 * Helper function that returns image crud for non visual attachment types that cannot be represented via Glide.
 *
 * This function builds a [Pair] containing the fitting background color resource and an icon
 * representing the file type.
 *
 * @return the image crud for the attachment
 */

export function getFileIcon(type: string): string {
  if (!type) {
    return 'assets/images/icon_file.svg';
  }
  if (type.includes('/pdf')) {
    return 'assets/images/icon_pdf.svg';
  } else if (type.includes('/plain')) {
    return 'assets/images/icon_txt.svg';
  } else if (type.includes('/xml')) {
    return 'assets/images/icon_xml.svg';
  } else if (type.includes('/json')) {
    return 'assets/images/icon_json.svg';
  } else if (type.includes('/comma-separated-values')) {
    return 'assets/images/icon_csv.svg';
  } else if (type.includes('audio/')) {
    return 'assets/images/icon_audio.svg';
  } else if (type.includes('image/')) {
    return 'assets/images/icon_img.svg';
  } else if (isWordDocument(type)) {
    return 'assets/images/icon_doc.svg';
  } else if (isPowerPoint(type)) {
    return 'assets/images/icon_ppt.svg';
  } else if (isExcel(type)) {
    return 'assets/images/icon_xls.svg';
  } else if (isZip(type)) {
    return 'assets/images/icon_zip.svg';
  } else {
    return 'assets/images/icon_file.svg';
  }
}

export function getFileSizeInMb(files: File[]): number {
  const size = files.map((file) => file.size).reduce((sum, bytes) => (sum += bytes));
  return size / 1024 / 1024;
}

export function removeDirectories(files: FileList): Promise<File[]> {
  return new Promise((resolve) => {
    const fileArray = Array.from(files);
    const dirnames: string[] = [];
    const readerList = [];

    function addToReaderList(val: number): void {
      readerList.push(val);
      if (readerList.length === fileArray.length) {
        resolve(fileArray.filter((file: File) => !dirnames.includes(file.name)));
      }
    }

    for (let i = 0; i < fileArray.length; i++) {
      const reader = new FileReader();
      reader.onerror = () => {
        dirnames.push(fileArray[i].name);
      };
      reader.onloadend = () => addToReaderList(i);
      reader.readAsArrayBuffer(fileArray[i]);
    }
  });
}

/**
 * Helper function that checks whether the attachment is displayable in google doc viewer.
 *
 * @return true, if attachment is is displayable in google doc viewer, false otherwise
 */
export function googleDocSupported(type: string): boolean {
  return (
    type.includes('postscript') ||
    type.includes('x-font-ttf') ||
    type.includes('vnd.ms-xpsdocument') ||
    type.includes('oxps') ||
    isGoogleFileType(type) ||
    isWordDocument(type) ||
    isExcel(type) ||
    isPowerPoint(type) ||
    isZip(type)
  );
}
