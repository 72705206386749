<form #form="ngForm" *ngIf="applicationForm" [formGroup]="applicationForm" fxLayout="column" fxLayoutGap="24px">
  <section class="mat-card" fxLayout="column" fxLayoutGap="16px">
    <h2 class="m-b-3">{{ 'JOB_CREATOR.APPLICATION.HEADLINES.application_headline' | translate }}</h2>
    <h3>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.application' | translate }}</h3>
    <div fxLayout="row" fxLayoutGap="16px">
      <span class="on-surface-medium">{{ 'JOB_CREATOR.APPLICATION.HEADLINES.career_only' | translate }}</span>
      <mat-slide-toggle
        (change)="clearCareerSite($event.checked)"
        [checked]="useAppForTrainingApplication"
        color="primary"
      ></mat-slide-toggle>
      <span class="on-surface-medium">{{ 'JOB_CREATOR.APPLICATION.HEADLINES.app_only' | translate }}</span>
    </div>
    <div *ngIf="useAppForTrainingApplication" class="m-t-1">
      <h4>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.app_only' | translate }}</h4>
      <div fxLayout="column" fxLayoutGap="16px">
        <mat-slide-toggle
          class="on-surface-medium overflow-visible"
          color="primary"
          formControlName="allowExpressApplication"
          labelPosition="after"
        >
          {{ 'JOB_CREATOR.APPLICATION.HEADLINES.allow_express_application' | translate }}
          <recrewt-what-is-this
            desc="JOB_CREATOR.APPLICATION.HINT.allow_express_application_hint"
          ></recrewt-what-is-this>
        </mat-slide-toggle>
        <mat-slide-toggle
          class="on-surface-medium overflow-visible"
          color="primary"
          formControlName="allowClassicApplication"
          labelPosition="after"
        >
          {{ 'JOB_CREATOR.APPLICATION.HEADLINES.allow_classic_application' | translate }}
          <recrewt-what-is-this
            desc="JOB_CREATOR.APPLICATION.HINT.allow_classic_application_hint"
          ></recrewt-what-is-this>
        </mat-slide-toggle>
        <div fxLayout="row" fxLayoutGap="16px">
          <mat-form-field
            [formGroup]="applicationForm?.controls.contact"
            appearance="outline"
            class="m-b-2"
            fxFlex="grow"
          >
            <input formControlName="applicationEmail" matInput type="email" />
            <mat-label>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.contact_application_email' | translate }}</mat-label>
            <mat-hint>{{ 'JOB_CREATOR.APPLICATION.HINT.contact_application_email_hint' | translate }}</mat-hint>
            <mat-error>{{ 'COMMON.invalid_email' | translate }}</mat-error>
          </mat-form-field>
          <recrewt-count-down-button
            (click)="openSendDialog(applicationForm?.value['contact']['applicationEmail'], clearTraining)"
            [clear]="clearTraining.asObservable()"
            class="m-t-2"
            color="primary"
            countdown="60"
            key="applicationTestCountdown"
            label="JOB_CREATOR.APPLICATION.TEST.send"
          ></recrewt-count-down-button>
        </div>
      </div>
    </div>
    <div *ngIf="!useAppForTrainingApplication" class="m-t-1">
      <h4>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.career_title' | translate }}</h4>
      <mat-form-field
        [formGroup]="applicationForm?.controls.applicationFlowOverride"
        appearance="outline"
        class="width-match-parent m-b-2"
      >
        <input formControlName="careerSite" matInput />
        <mat-error>{{ 'COMMON.invalid_url' | translate }}</mat-error>
        <mat-label>{{ 'JOB_CREATOR.APPLICATION.HINT.application_flow_override_career' | translate }}</mat-label>
        <mat-hint>{{ 'JOB_CREATOR.APPLICATION.HINT.application_flow_override_hint' | translate }}</mat-hint>
      </mat-form-field>
    </div>
  </section>
  <section class="mat-card" fxLayout="column" fxLayoutGap="16px">
    <h2 class="m-b-3">{{ 'JOB_CREATOR.APPLICATION.HEADLINES.intern_headline' | translate }}</h2>
    <mat-slide-toggle
      class="on-surface-medium overflow-visible"
      color="primary"
      formControlName="internship_possible"
      labelPosition="after"
    >
      {{ 'JOB_CREATOR.APPLICATION.HEADLINES.internship_possible' | translate }}
      <recrewt-what-is-this
        class="p-t-1"
        desc="JOB_CREATOR.APPLICATION.HINT.internship_possible_hint"
      ></recrewt-what-is-this>
    </mat-slide-toggle>
    <ng-container *ngIf="applicationForm?.value['internship_possible']">
      <h3>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.intern_application' | translate }}</h3>
      <div fxLayout="row" fxLayoutGap="16px">
        <span class="on-surface-medium">{{ 'JOB_CREATOR.APPLICATION.HEADLINES.career_only' | translate }}</span>
        <mat-slide-toggle
          (change)="clearInternshipSite($event.checked)"
          [checked]="useAppForInternshipApplication"
          color="primary"
        ></mat-slide-toggle>
        <span class="on-surface-medium">{{ 'JOB_CREATOR.APPLICATION.HEADLINES.app_only' | translate }}</span>
      </div>
      <div *ngIf="useAppForInternshipApplication" class="m-t-1">
        <h4>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.app_only' | translate }}</h4>
        <div fxLayout="column" fxLayoutGap="16px">
          <mat-slide-toggle
            class="on-surface-medium overflow-visible"
            color="primary"
            formControlName="allowExpressInternshipApplication"
            labelPosition="after"
          >
            {{ 'JOB_CREATOR.APPLICATION.HEADLINES.allow_express_intern_application' | translate }}
            <recrewt-what-is-this
              desc="JOB_CREATOR.APPLICATION.HINT.allow_express_application_hint"
            ></recrewt-what-is-this>
          </mat-slide-toggle>
          <mat-slide-toggle
            class="on-surface-medium overflow-visible"
            color="primary"
            formControlName="allowClassicInternshipApplication"
            labelPosition="after"
          >
            {{ 'JOB_CREATOR.APPLICATION.HEADLINES.allow_classic_intern_application' | translate }}
            <recrewt-what-is-this
              desc="JOB_CREATOR.APPLICATION.HINT.allow_classic_application_hint"
            ></recrewt-what-is-this>
          </mat-slide-toggle>
          <div fxLayout="row" fxLayoutGap="20px">
            <mat-form-field
              [formGroup]="applicationForm?.controls.contact"
              appearance="outline"
              class="m-b-2"
              fxFlex="grow"
            >
              <input formControlName="internshipApplicationEmail" matInput type="email" />
              <mat-label>{{
                'JOB_CREATOR.APPLICATION.HEADLINES.contact_intern_application_email' | translate
              }}</mat-label>
              <mat-hint>{{ 'JOB_CREATOR.APPLICATION.HINT.contact_application_email_hint' | translate }}</mat-hint>
              <mat-error>{{ 'COMMON.invalid_email' | translate }}</mat-error>
            </mat-form-field>
            <recrewt-count-down-button
              (click)="openSendDialog(applicationForm?.value['contact']['internshipApplicationEmail'], clearIntern)"
              [clear]="clearIntern.asObservable()"
              class="m-t-2"
              color="primary"
              countdown="60"
              key="internshipApplicationTestCountdown"
              label="JOB_CREATOR.APPLICATION.TEST.send"
            ></recrewt-count-down-button>
          </div>
        </div>
      </div>
      <div *ngIf="!useAppForInternshipApplication" class="m-t-1">
        <h4>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.career_title' | translate }}</h4>
        <mat-form-field
          [formGroup]="applicationForm?.controls.applicationFlowOverride"
          appearance="outline"
          class="width-match-parent"
        >
          <input formControlName="internshipSite" matInput />
          <mat-error>{{ 'COMMON.invalid_url' | translate }}</mat-error>
          <mat-label>{{ 'JOB_CREATOR.APPLICATION.HINT.application_flow_override_intern' | translate }}</mat-label>
          <mat-hint>{{ 'JOB_CREATOR.APPLICATION.HINT.application_flow_override_intern_hint' | translate }}</mat-hint>
        </mat-form-field>
      </div>
      <h3>{{ 'JOB_CREATOR.APPLICATION.HEADLINES.intern_time' | translate }}</h3>
      <div formArrayName="internshipSlots">
        <button (click)="addInternshipSlot()" class="m-b-1" mat-button type="button">
          <mat-icon>add</mat-icon> {{ 'COMMON.add' | translate }}
        </button>
        <div
          *ngFor="let slot of applicationForm.controls.internshipSlots.controls; index as i"
          [formGroupName]="i"
          fxLayout="row"
          fxLayoutGap="16px"
        >
          <mat-form-field appearance="outline" fxFlex="grow">
            <mat-label>{{ 'JOB_CREATOR.GENERAL.PLACEHOLDERS.start_date' | translate }}</mat-label>
            <input [matDatepicker]="pickerStart" formControlName="start" matInput />
            <mat-datepicker-toggle [for]="pickerStart" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #pickerStart startView="multi-year"></mat-datepicker>
            <mat-error>{{ 'JOB_CREATOR.GENERAL.ERRORS.valid_date' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" fxFlex="grow">
            <mat-label>{{ 'JOB_CREATOR.GENERAL.PLACEHOLDERS.end_date' | translate }}</mat-label>
            <input [matDatepicker]="pickerEnd" formControlName="end" matInput />
            <mat-datepicker-toggle [for]="pickerEnd" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd startView="multi-year"></mat-datepicker>
            <mat-error>{{ 'JOB_CREATOR.GENERAL.ERRORS.valid_date' | translate }}</mat-error>
          </mat-form-field>
          <button class="m-t-1" mat-icon-button type="button">
            <mat-icon (click)="removeInternshipSlot(i)" class="on-surface-medium">delete</mat-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </section>
  <ng-container *ngIf="applicationForm?.value as value">
    <section
      *ngIf="
        (useAppForInternshipApplication || useAppForTrainingApplication) &&
        (value.allowClassicApplication || value.allowClassicInternshipApplication)
      "
      [formGroup]="applicationForm"
      class="mat-card"
      fxLayout="column"
      fxLayoutGap="16px"
    >
      <h2 class="m-b-3">
        {{ 'JOB_CREATOR.APPLICATION.HEADLINES.application_interview' | translate }}
      </h2>
      <p class="on-surface-medium">{{ 'JOB_CREATOR.APPLICATION.HINT.application_interview' | translate }}</p>
      <mat-slide-toggle
        (change)="toggleInterviewEnableState($event.checked)"
        class="on-surface-medium overflow-visible"
        color="primary"
        formControlName="enableInterview"
        labelPosition="after"
        >{{ 'JOB_CREATOR.APPLICATION.HEADLINES.allow_application_interview' | translate }}
      </mat-slide-toggle>
      <div *ngIf="value.enableInterview" fxLayout="column">
        <ng-container *ngFor="let q of APPLICATION_INTERVIEW; index as i" formArrayName="application_interview">
          <mat-form-field appearance="outline" fxFlex="grow">
            <input [formControlName]="i" matInput required />
            <mat-label>{{
              'JOB_CREATOR.APPLICATION.PLACEHOLDER.application_interview' | translate : { i: (i + 1).toString() }
            }}</mat-label>
            <mat-error>{{ 'COMMON.required' | translate }}</mat-error>
          </mat-form-field>
        </ng-container>
      </div>
    </section>
  </ng-container>
</form>
