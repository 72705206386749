<ng-container *ngrxLet="spaces$; let spaces">
  <div
    *ngIf="spaces?.length; else standaloneTpl"
    [style.width.px]="select.isOpen ? 300 : undefined"
    class="container"
    title="Space wechseln"
  >
    <recrewt-select
      #select
      (selectChange)="openSpace($event)"
      [optionHeight]="60"
      [optionTpl]="option"
      [options]="spaces"
      [originTpl]="origin"
      labelKey="name"
      placeholder="Space wechseln..."
    ></recrewt-select>
  </div>

  <ng-template #option let-space>
    <div class="option">
      <p class="ellipsize m-v-s">{{ space?.name }}</p>
      <recrewt-status-tag *ngIf="space?.branched" [config]="PARENT_TAG"></recrewt-status-tag>
      <recrewt-status-tag *ngIf="space?.parentRef" [config]="BRANCH_TAG"></recrewt-status-tag>
    </div>
  </ng-template>

  <ng-template #origin>
    <div class="p-h-1" fxLayout="row" fxLayoutGap="8px">
      <div *ngIf="activeSpace$ | async as space" class="origin">
        <p class="ellipsize m-v-s on-primary">{{ space?.name }}</p>
        <recrewt-status-tag *ngIf="space?.branched" [config]="PARENT_TAG"></recrewt-status-tag>
        <recrewt-status-tag *ngIf="space?.parentRef" [config]="BRANCH_TAG"></recrewt-status-tag>
      </div>
      <div class="height-match-parent list-down" fxLayoutAlign="center center">
        <mat-icon class="on-primary">expand_more</mat-icon>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #standaloneTpl>
  <div
    (click)="toOverview()"
    *ngIf="activeSpace$ | async as space"
    class="p-h-1 origin hover-effect"
    title="Space wechseln"
  >
    <p class="ellipsize m-v-s on-primary mat-body-1">{{ space?.name }}</p>
    <recrewt-status-tag [config]="CURRENT_TAG"></recrewt-status-tag>
  </div>
</ng-template>
