import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Company } from '@data/company/company.model';

export function createInitialState(): Company {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company', resettable: true, cache: { ttl: 600000 } })
export class CompanyStore extends Store<Company> {
  constructor() {
    super(createInitialState());
  }
}
