import { NgModule } from '@angular/core';
import { FeedbackDialogComponent } from './page/feedback-dialog/feedback-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { FeedbackButtonComponent } from './component/feedback-button/feedback-button.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [FeedbackDialogComponent, FeedbackButtonComponent],
  imports: [
    SharedModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    FlexModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
  ],
  exports: [FeedbackButtonComponent],
})
export class SupportElementsModule {}
