import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpaceSwitchButtonComponent } from './component/space-switch-button/space-switch-button.component';
import { SharedModule } from '@shared/shared.module';
import { MatIconModule } from '@angular/material/icon';
import { FlexModule } from '@angular/flex-layout';
import { LetModule } from '@ngrx/component';

@NgModule({
  declarations: [SpaceSwitchButtonComponent],
  exports: [SpaceSwitchButtonComponent],
  imports: [CommonModule, SharedModule, MatIconModule, FlexModule, LetModule],
})
export class SpaceElementsModule {}
